import React from 'react'
import { Route } from 'react-router-dom'
import lazyLoad from 'utils/lazyLoad'

const Invoice = lazyLoad(() => import('containers/invoices/Invoice'))
const Approving = lazyLoad(() => import('containers/parking/Approving'))
const CardHandle = lazyLoad(() => import('containers/parking/CardHandle'))
const ParkingApproval = lazyLoad(() => import('containers/parking/ParkingApproval'))
const ParkingSettingsBox = lazyLoad(() => import('containers/parking/ParkingSettingsBox'))
const PaymentRecords = lazyLoad(() => import('containers/parking/PaymentRecords'))
const PaymentSettings = lazyLoad(() => import('containers/parking/PaymentSettings'))
const VipCard = lazyLoad(() => import('containers/parking/VipCard'))
const IndexRedirectBox = lazyLoad(() => import('../containers/shuck/IndexRedirectBox'))
const ParkingViolationList = lazyLoad(() => import('containers/parking/ParkingViolationList'))
const ParkingBlackList = lazyLoad(() => import('containers/parking/ParkingBlackList'))

// CardHandle
export default function () {
    return (
        <Route
            path="parking"
            element={IndexRedirectBox}>
            <Route
                path="card_handle"
                element={CardHandle}>
                <Route
                    path="approving"
                    element={Approving} />
                <Route
                    path="record"
                    element={Approving} />
            </Route>
            <Route
                path="payment_records"
                element={PaymentRecords} />
            <Route
                path="payment_records/:invoice_id"
                element={Invoice} />
            <Route
                path="parking_approval"
                element={ParkingApproval} />
            <Route
                path="parking_violation"
                element={ParkingViolationList} />
            <Route
                path="parking_black"
                element={ParkingBlackList} />
            <Route
                path="settings"
                element={ParkingSettingsBox}>
                <Route
                    index
                    element={VipCard} />
                <Route
                    path="vip_card"
                    element={VipCard} />
                <Route
                    path="payment_settings"
                    element={PaymentSettings} />
            </Route>
        </Route>
    )
}
