import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoad from 'utils/lazyLoad'

const IndexRedirectBox = lazyLoad(() => import('../containers/shuck/IndexRedirectBox'))
const TasksBox = lazyLoad(() => import('../containers/tasks/TasksBox'))
const TasksList = lazyLoad(() => import('../containers/tasks/TasksList'))

export default function() {
    return (
        <Route path="tasks" element={IndexRedirectBox}>
            {/* 处理自动跳转 */}
            <Route path=":task_type" element={TasksBox}>
                <Route path="type" element={TasksList} />
            </Route>
        </Route>
    )
}
