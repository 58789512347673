import * as _ from 'lodash'

function locationGroupSetting(state = {}, action = {}) {
  if (action.type === 'GETLOCATIONGROUPSETTING_SUCCESS') {
    return _.get(action, 'response.json', null)
  }

  if (action.type === 'UPDATELOCATIONGROUPSETTING_SUCCESS') {
    return { ...state, ..._.get(action, 'response.json', {}) }
  }

  return state
}

export default locationGroupSetting
