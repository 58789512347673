import { get } from 'lodash'
import * as ActionTypes from '../actions'

export default function user(state = {}, action = {}) {
  let { type, response } = action

  if (
    type === ActionTypes.REGISTER.SUCCESS ||
    type === ActionTypes.LOGIN.SUCCESS ||
    type === 'GETCURRENT_USER_SUCCESS' ||
    type === 'UPDATECURRENT_USER_SUCCESS'
  ) {
    if (action.response.result) {
      return {
        ...state,
        ...get(action, `response.entities.users.${action.response.result}`)
      }
    }
    return {
      ...state,
      ...action.response
    }
  } else if (type === ActionTypes.USER_LOGGED_OUT) {
    return {}
  }
  return state
}
