import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoad from 'utils/lazyLoad'

const VisitBlackList = lazyLoad(() => import('containers/visit/VisitBlackList'))
const VisitorBox = lazyLoad(() => import('containers/visit/VisitorBox'))
const IndexRedirectBox = lazyLoad(() => import('../containers/shuck/IndexRedirectBox'))

export default function() {
    return (
        <Route path="visit" element={IndexRedirectBox}>
            <Route path="visits" element={VisitorBox} />
            <Route path="blacklist" element={VisitBlackList} />
        </Route>
    )
}
