import CITIES from './cities'
import intl from 'react-intl-universal'
import {
  DEFAULT_SPACE_MENU as spaceMenus,
  IDEAPOD_SPACE_MENU as ideapodMenus,
  KINEER_SPACE_MENU as  kineerMenus,
} from './spaceMenus'
import { DEFAULT_LOCATION_MENU as locationMenus, KINEER_LOCATION_MENU as kineerLocationMenus } from './locationMenus'
import { DEFAULT_LOCATION_GROUP_MENU as locationGroupMenus } from './locationGroupMenus'
import { DEFAULT_ORGANIZANTION_MENU as organizationMenus } from './organizationMenus'

import { STATE_COLOR } from './StateColor'
export { SUPPORTED_LOCALES } from './locales'
export { DEFAULT_APP_ICON } from './DEFAULT_APP_ICON'
export { MENUICON } from './MENUICON'

export const QINGNIU_STATIC_FILE =
  `${import.meta.env.VITE_QINIU_BASE_URL  }static/`

export const IDEAPOD_SPACE_MENU= ideapodMenus
export const KINEER_SPACE_MENU= kineerMenus
export const DEFAULT_SPACE_MENU = spaceMenus
export const DEFAULT_LOCATION_MENU = locationMenus
export const DEFAULT_LOCATION_GROUP_MENU = locationGroupMenus
export const DEFAULT_ORGANIZANTION_MENU = organizationMenus
export const KINEER_LOCATION_MENU = kineerLocationMenus

/* COMMON */
export const cities = CITIES
export const BOOL_STATES = [
  { id: 'true', color: STATE_COLOR.success, status: 'success' },
  { id: 'false', color: STATE_COLOR.warning, status: 'error' },
]

export const POST_STATE = [
  { id: 'active', color: STATE_COLOR.success, status: 'success' },
  { id: 'disabled', color: STATE_COLOR.warning, status: 'error' },
]

export const POST_STATUS = [
  { id: 'active', color: STATE_COLOR.success, status: 'success' },
  { id: 'deleted', color: STATE_COLOR.warning, status: 'error' },
]

export const SWITCH_STATE = [
  { id: 'enabled', color: STATE_COLOR.success, status: 'success' },
  { id: 'disabled', color: STATE_COLOR.warning, status: 'error' },
]

export const ANNOUNCEMENT_TYPE = [
  { id: 'banner', color: STATE_COLOR.success, status: 'success' },
  { id: 'notification', color: STATE_COLOR.warning, status: 'error' },
  { id: 'popup', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'passcode', color: STATE_COLOR.processing, status: 'warning' },
]

/* Invoices */
export const LINEITEM_TYPES = [
  { id: 'late_fee', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'house_fee', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'renting_fee', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'deposit_fee', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'water_fee', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'electric_fee', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'network_fee', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'express_fee', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'parking_fee', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'damaged_fee', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'reservation', color: STATE_COLOR.success, status: 'success' },
  { id: 'activity', color: STATE_COLOR.success, status: 'success' },
  { id: 'refund_deposit', color: STATE_COLOR.success, status: 'success' },
  { id: 'other', color: STATE_COLOR.default, status: 'default' },
]

export const LINE_ITEM_TYPE = [
  {
    id: 'renting_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
    features: ['asset_management'],
  },
  {
    id: 'deposit_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
    features: ['asset_management'],
  },
  {
    id: 'normal',
    color: STATE_COLOR.processing,
    status: 'warning',
    features: ['asset_management'],
  },
  {
    id: 'late_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
    features: ['asset_management'],
  },
  {
    id: 'house_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
    features: ['asset_management'],
  },

  {
    id: 'rent_and_deposit',
    color: STATE_COLOR.processing,
    status: 'warning',
    // features: ['asset_management']
  },
  {
    id: 'commission_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
    features: ['asset_management'],
  },
  {
    id: 'additional_service_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
    features: ['asset_management'],
  },
  {
    id: 'mgmt_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
    // features: ['asset_management']
  },
  {
    id: 'refund_deposit',
    color: STATE_COLOR.success,
    status: 'success',
    features: ['asset_management'],
  }, //
  // { id: 'free_rent_fee', color: STATE_COLOR.processing, status: 'warning' ,features: ['asset_management']},
  {
    id: 'refund_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
    features: ['asset_management'],
  },
  {
    id: 'water_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
    features: ['asset_management'],
  },
  {
    id: 'electric_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
    features: ['asset_management'],
  },
  {
    id: 'network_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'express_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'damaged_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'reservation',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'print_reservation',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'point_plan',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  // { id: 'parking_fee', color: STATE_COLOR.processing, status: 'warning' ,features: ['asset_management']},
  {
    id: 'product_reservation',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'desk_reservation',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'cabinet_reservation',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'membership',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'membership_resource',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'activity',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'parking_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'ac_overtime_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'clean_fee',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  // { id: 'cycle_fee', color: STATE_COLOR.processing, status: 'warning' ,features: ['asset_management']},
  {
    id: 'moc_recharge',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'other',
    color: STATE_COLOR.default,
    status: 'default',
    features: ['asset_management'],
  },
]

export const REVENUE_STATE = [
  { id: 'active', color: STATE_COLOR.success, status: 'success' },
  { id: 'unpaid', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'cancelled', color: STATE_COLOR.default, status: 'default' },
]

export const INVOICE_STATES = [
  { id: 'active', color: STATE_COLOR.success, status: 'success' },
  { id: 'expiring', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'cancelled', color: STATE_COLOR.default, status: 'default' },
  { id: 'ended', color: STATE_COLOR.warning, status: 'error' },
  { id: 'approving', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'overdue', color: STATE_COLOR.red, status: 'error' },
  { id: 'rejected', color: STATE_COLOR.processing, status: 'error' },
  { id: 'draft', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'terminating', color: STATE_COLOR.processing, status: 'warning' },
]
export const SERVICE_STATES = [
  { id: 'completed', color: STATE_COLOR.success, status: 'success' },
  { id: 'pending', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'no_need', color: STATE_COLOR.default, status: 'default' },
  { id: 'rejected', color: STATE_COLOR.warning, status: 'error' },
]

export const POSTS_STATE = [
  { id: 'approved', color: STATE_COLOR.success, status: 'error' },
  { id: 'approving', color: STATE_COLOR.pink_purple, status: 'warning' },
  { id: 'rejected', color: STATE_COLOR.warning, status: 'error' },
]

export const INVOICE_PAYMENT_TYPES = [
  { id: 'cash', color: STATE_COLOR.success, status: 'success' },
  { id: 'invoice', color: STATE_COLOR.success, status: 'success' },
  { id: 'point', color: STATE_COLOR.success, status: 'success' },
  { id: 'balance', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'manually', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'pos', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'union_pay', color: STATE_COLOR.success, status: 'success' },
  { id: 'wechat_offline', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'alipay_offline', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'credit', color: STATE_COLOR.success, status: 'success' },
  { id: 'other', color: STATE_COLOR.default, status: 'default' },
]

export const REPORT_STATES = [
  {
    id: 'not_signed',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'signed',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'cancel',
    color: STATE_COLOR.default,
    status: 'default',
  },
]

export const COMMISSION_STATES = [
  {
    id: 'init_commission_approving',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'init_commission_rejected',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'request_commission_approving',
    color: STATE_COLOR.default,
    status: 'default',
  },
  {
    id: 'request_commission_rejected',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'sended_commission',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'sending_commission',
    color: STATE_COLOR.default,
    status: 'default',
  },
  {
    id: 'wait_commission',
    color: STATE_COLOR.default,
    status: 'default',
  },
]
export const INVOICE_PAYMENT_MODES = [
  { id: 'cash', color: STATE_COLOR.success, status: 'success' },
  { id: 'pos', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'point', color: STATE_COLOR.success, status: 'success' },
  { id: 'union_pay', color: STATE_COLOR.success, status: 'success' },
  {
    id: 'adapay',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'enterprise_offline',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'bank',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'deposit_cover',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'deposit_pay',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'wx_lite',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'wechat',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'alipay',
    color: STATE_COLOR.success,
    status: 'success',
  },
  { id: 'wechat_offline', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'alipay_offline', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'balance', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'credit', color: STATE_COLOR.success, status: 'success' },
  { id: 'coupon', color: STATE_COLOR.success, status: 'success' },
  { id: 'manually', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'invoice', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'other', color: STATE_COLOR.default, status: 'default' },
]

export const INVOICE_APPLICATION_FEE_TYPES = [
  { id: 'rent_fees', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'service_fees', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'properties_fees', color: STATE_COLOR.processing, status: 'warning' },
  {
    id: 'meeting_rooms_fees',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  { id: 'networking_fees', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'cycle_fees.water', color: STATE_COLOR.processing, status: 'warning' },
  {
    id: 'cycle_fees.electric',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  { id: 'other_fees', color: STATE_COLOR.default, status: 'default' },
]

export const ORDER_STATES = [
  'unpaid',
  'paid',
  'cancelled',
  'refunding',
  'refunded',
]

export const CONTRACT_STATIS_TYPE = [
  {
    id: 'subscription_num',
  },
  {
    id: 'subscription_amount',
  },
]

export const CYCLE_FEE_TYPES = ['electric', 'water']
/* CRM */
export const CRM_OPPORTUNITY_STATES = [
  {
    id: 'preliminary',
  },
  {
    id: 'plan',
  },
  {
    id: 'success',
  },
  {
    id: 'shelved',
  },
  {
    id: 'failed',
  },
]

export const EXPECT_AREA_USAGE = [
  {
    id: 'office',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'shared_area',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'warehouse',
    color: STATE_COLOR.warning,
    status: 'error',
  },
  {
    id: 'business',
    color: STATE_COLOR.default,
    status: 'success',
  },
  {
    id: 'apartment',
    color: STATE_COLOR.pink_purple,
    status: 'error',
  },
  {
    id: 'club',
    color: STATE_COLOR.blue,
    status: 'error',
  },
]

export const CRM_LEAD_STATES = [
  {
    id: 'not_contact',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'correct_contact',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'wrong_contact',
    color: STATE_COLOR.warning,
    status: 'error',
  },
  {
    id: 'customer',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'closed',
    color: STATE_COLOR.warning,
    status: 'error',
  },
]
export const CRM_CUSTOMER_STATES = [
  // {
  //   id: 'visit',
  //   color: STATE_COLOR.success,
  //   status: 'success'
  // },
  {
    id: 'potential',
    color: STATE_COLOR.default,
    status: 'warning',
  },
  {
    id: 'intention',
    color: STATE_COLOR.processing,
    status: 'success',
  },
  {
    id: 'deal',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'archived',
    color: STATE_COLOR.default,
    status: 'warning',
  },
  {
    id: 'loss',
    color: STATE_COLOR.warning,
    status: 'error',
  },
]
export const CRM_OPPORTUNITY_CONTACT_POSITIONS = [
  { id: 'decision_maker', color: STATE_COLOR.warning, status: 'error' },
  { id: 'auditer', color: STATE_COLOR.warning, status: 'error' },
  { id: 'purchaser', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'evaluator', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'user', color: STATE_COLOR.success, status: 'success' },
  { id: 'other', color: STATE_COLOR.default, status: 'default' },
]

export const SMART_DEVICE_TYPE = [
  { id: 'meeting_pad', color: STATE_COLOR.warning, status: 'error' }, //         # 会议预定平板
  { id: 'control_pad', color: STATE_COLOR.warning, status: 'error' }, //       # 会议中控平板
  { id: 'visitor_pad', color: STATE_COLOR.warning, status: 'error' }, //     # 访客平板
  { id: 'cabinet_pad', color: STATE_COLOR.warning, status: 'error' }, //   # 储物柜平板
  { id: 'desk_pad', color: STATE_COLOR.warning, status: 'error' }, // # 工位平板
  { id: 'lock', color: STATE_COLOR.warning, status: 'error' }, //          # 门禁
  { id: 'edison', color: STATE_COLOR.warning, status: 'error' }, //              # 人体传感器
  { id: 'lighting', color: STATE_COLOR.warning, status: 'error' }, //             # 智能灯
  { id: 'fog_glass', color: STATE_COLOR.warning, status: 'error' }, //            # 雾化玻璃
  { id: 'power_strip', color: STATE_COLOR.warning, status: 'error' }, //           # 智能插座
  { id: 'person_flow', color: STATE_COLOR.warning, status: 'error' }, //         # 人数传感器
  { id: 'air', color: STATE_COLOR.warning, status: 'error' }, //          # 空气质量传感器
  { id: 'air_conditioner', color: STATE_COLOR.warning, status: 'error' }, //        # 空调
  { id: 'curtain', color: STATE_COLOR.warning, status: 'error' }, //         # 窗帘
  { id: 'gateway', color: STATE_COLOR.warning, status: 'error' }, //         # 网关
  { id: 'door_magnetic', color: STATE_COLOR.warning, status: 'error' }, //        # 门磁
  { id: 'ink_screen', color: STATE_COLOR.warning, status: 'error' }, //         # 水墨屏
  { id: 'other', color: STATE_COLOR.warning, status: 'error' }, //             # 其他
]

export const CRM_CHANNEL_TYPES = ['org', 'user']
export const CRM_AGENT_TYPES = [
  {
    id: 'org',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'user',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'old',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'management',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'third',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'ads',
    color: STATE_COLOR.success,
    status: 'success',
  },
]

export const CRM_AGENT_STATE = [
  {
    id: 'true',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'false',
    color: STATE_COLOR.warning,
    status: 'error',
  },
]

/** Lock */
export const OPEN_DOOR_RESULTS = [
  { id: 'open_door', color: STATE_COLOR.success, status: 'success' },
  { id: 'fail', color: STATE_COLOR.warning, status: 'error' },
]
export const LOCK_PERMISSION_SOURCES = ['sub', 'res', 'visitor']

export let DUTIES_TYPE_OBJ

export let DUTIES_TYPE_FORM

export let LOCK_MANAGE_ADD_TYPE

export let LOCK_TYPE_SELECT

export let MEMBER_AGE

export let DOCUMENT_TYPE

export let DOCUMENT_TYPE_OBJ

export let SEARCH_TYPE

export let SEARCH_TYPE_CONTENT

export let MEETING_SHIP_RESOURCE_TYPE

export let MEETING_SHIP_RESOURCE_TYPE_OBJ

export let CONTRACTAPPROVALSETTING

export let USER_SELECT_INPUT

export let DEPARTMENT_SELECT_INPUT

// export let MEMBERSHIPS_DURATION_UNITS

export let MEMBERSHIPS_DURATION_UNITS_ARRAY

export let MEMBERSHIP_RESOURCE_UNIT

export let MEMBERSHIP_RESOURCE_TYPE_OBJ

export let MEMBERSHIPS_CYCLE_TYPE_ARRAY

export let MEMBERSHIPS_CYCLE_TYPE

export let TERMINATE_REASON
export let INVOICES_ORDER_STATE
export let INVOICES_FASHIONABLE_STATE

export let LOCATION_SEND_MESSAGE_RANGE

export let ACTIVITY_SEND_MESSAGE_RANGE
export let ORG_AREA_STATE

// 分店设置 客户端是否可见
export let LOCATION_PUBLIC

// 分店设置 是否允许普通用户自行绑定卡
export let CAN_BINDING_CARD

// 分店设置 是否允许用户自行设置触摸密码
export let CAN_SET_TOUCH_PASSWORD

// 分店设置 是否显示蓝牙门禁
export let DISPLAY_BLUETOOTH_LOCK

// 分店设置 是否靠近门才能开门
export let NEED_CLOSE_TO_LOCK

export let CONTRACT_END_DATE_BTN

export let TASKS
export let TASK_SOURCES
export let ALL_AREAS

export let ORG_ROLES_FORM

export let ORG_ROLES_FORM_VALUE

export let ORG_ROLES_OBJ

export let MEETING_THEME_CATEGORY

export let MEETING_THEME_CATEGORY_ARRAY

export let MEETING_THEME_UPLOAD_ARRAY

// 会议室管理
export let AREATYPES_STATES
export let TENANT_DEVICE

export let AREATYPES_TIPSY

export let LOCK_MANAGE_TIPSY

export let RESERVATION_PRIVILEGES

export let RESERVATION_PRIVILEGE_ORG

export let RESERVATION_PROGRESS_STATUS
export let MARKETING_ACTIVITIES_PROGRESS_STATUS
export let COUPONS_MARKETING_TYPE
export let ACTIVITY_TASK_TYPE
export let ACTIVITY_TASK_CYCLE
export let ACTIVITY_TASK_STATE

export let ORIENTATION_STATES_OBJ

export let OFFICE_AREA_STATE

export let AREAS_TYPE

export let DESK_LIST_STATES

export let DESK_STATES

export let NETWORK_TYPE

export let LOCK_TYPE

export let OPPORTUNITY_STATES

export let OPPORTUNITY_DESIRES

export let MEETING_SHIP_AREA_TYPE

export let MEETING_TYPE

export let MEETING_SHIP_AREA_TYPE_OBJ

export let MEETING_SHIP_RESOURCES_TYPE

export let MEETING_SHIP_RESOURCES_TYPE_OBJ

export let CRM_AREA_TYPE

export let CRM_AREA_TYPE_OBJ

export let REQUIREMENT_TYPE

export let REQUIREMENT_TYPE_OBJ
// 客户意向租赁面积可选值
export let CRM_INTENT_RENT_AREA_SIZE

export let CRM_INTENT_RENT_AREA_SIZE_OBJ

export let CRM_COMPANY_TYPE

export let CRM_COMPANY_TYPE_OBJ

export let CRM_DESIRE

export let CRM_DESIRE_OBJ

export let CRM_VISIT_TYPE

export let CRM_OPP_VISIT_TYPE

export let CRM_VISIT_TYPE_OBJ

export let CRM_OPPORTUNITY_DESIRES

export let CRM_FAILED_REASON

export let CRM_FAILED_REASON_OBJ

export let CRM_LEADS_STATE_ARRAY

export let CRM_LEADS_STATE

export let CRM_USER_TYPE_STATUS

export let CRM_USER_TYPE_STAT

export let PAYMENT_TYPE

/**
 * 是否有锁有墙
 * @type {Array}
 */
export let LOCK_ARRAY

export let REPAIR_TASK_UPDATE_STATES

export let COMPLAINTS_TASK_UPDATE_STATES

export let COMPLAINTS_TASK_TYPE

export let AC_OVERTIME_TASK_TYPE

export let SUGGEST_TASK_UPDATE_STATES

export let TASK_UPDATE_STATES

export let TASK_PRIORITIES

export let TASK_PRIORITIES_FORM

export let DELIVERY_METHOD
export let DELIVERY_TYPE

export let SPACE_MEMBER_LOCATION_ROLES

export let TASK_SUBTYPES
export let INVOICES_FASHIONABLE_STATE_LIST
export let INVOICES_STATE
export let INVOICES_STATE_LIST
export let ATTENDANCE_SOURCE
export let ATTENDANCE_STATS
export let MEETING_CHECK_STATUS

export let INVOICES_UPDATE_STATE

export let INVOICES_TYPE

export let INVOICES_UPDATE_TYPE

export let INVOICES_UPDATE_TYPE_BILL

export let INVOICES_SENDING_STATE

export let INVOICES_IS_REVIEWED

export let ORDER_STATES_ARRAY

export let ORDER_STATE_ARRAY

export let INVOICE_SALES_PAYMENTS

export let INVOICE_ORDER_CERTIFICATE_PAYMENT_TYPES

export let PAYMENT_TYPES

export let INVOICE_CERTIFICATE_PAYMENT_TYPES

export let CRM_NODE_TYPES

export let NODE_STATES

/**
 * 客户来源
 * @type {Array}
 */
export let CRM_SOURCES

// 创建分区
// export let AREA_TYPE

// export let AREA_IS_COMMERCIAL

export let AREA_IS_COMMERCIAL_OBJ

export let AREA_IS_DISABLED

export let AREA_INFO_STATE

/**
 * 报修来源
 * @type {Array}
 */
export let TASK_SOURCE

export let TASK_SOURCE_OBJ

/**
 * 报修类型
 * @type {Array}
 */
export let TASK_SUBTYPE
// 保洁类型
export let CLEAN_SUBTYPE

export let CLEAN_SUBTYPES

export let FEEDBACK_SUBTYPE

export let FEEDBACK_SUBTYPES
// Faq 状态

export let FAQ_FILES_STATE

// 模块可见性设置
export let APP_MODULES_VISIBLE_TYPE

// APP模块可见性
export let APP_MODULES_VISIBLE_ARRAY

// 门禁权限progress
export let LOCKS_PERMISSIONS_PROGRESS
export let LOCKS_PERMISSIONS_PROGRESS_COLOR

// 统计方式（location，user）默认按照负责人统计
export let STATISTIC_MODE

export let SHOP_TYPE

export let SHOP_STATUS
export let SHOP_STOCK_TYPE

export let SPACE_ROLES
// 报表链接状态
export let REPORT_LINK_STATE

export let REPORT_LINK_STATE_COLOR
// 会员制资源类型
export let MEMBERSHIP_RESOURCE_TYPE

export let CRM_INVENTORY_EXPIRING_DATE_STRING
/**
 * 统计报表- 周期
 * @type {Array}
 */
export let STATISTIC_CYCLE
/**
 * 统计报表- 周期 天 周 月
 * @type {Array}
 */
export let STATISTIC_CYCLE_DWM

export let SUBSCRIPTION_STATE

// 公司员工技能
// export let ORG_MEMBER_SKILLS
// 积分明细的客户类型 公司 / 个人
export let POINT_PLAN_CUSTOMER_TYPE
export let CRM_CHANNEL_TYPE
export let POINT_PLAN_CUSTOMER_SPECIAL_TYPE

// 发票状态

export let INVOICE_APPLICATION_STATES

export let INVOICE_REVIEWED_STATUS

export let POINT_RECHARGE_TABS

export let LINE_ITEM_TYPES

export let DEFAULT_VISITOR_TYPE

export let VISIT_SOURCE
export let VISIT_STATES

export let POST_CATEGORY_OBJ

export let USER_TYPE

export let USER_TYPE_OBJ

export let ACTIVITY_ORDER_STATUS

export let ACTIVITY_ORDER_STATUS_OBJ

export let ACTIVITY_QUESTION_STATUS

export let SHOP_DELIVERY_METHOD

export let INCOME_CONFIRM_STATE

export let INCOME_CONFIRM_STATE_OBJ

export let INVOICES_TYPES_OBJ

export let MONTH_NUM

export let SELECT_APPROVAL_TYPE

export let PAD_APPTYPE

export let PAYMENT_SETTING

export let PAYMENT_TYPE_OBJ

export let COUPONS_FROM_TYPE

export let APPROVAL_RECORDS_STATUS
export let MEETING_SERVICE_STATUS

export let APPROVA_STATUS

export let MEETING_RECORDS_STATUS

export let MEETING_RECORDS_TYPE

export let COUPONS_FROM_RESOURCE

export let COUPONS_FROM_VALIDITY

export let COUPONS_VALIDITY_OBJ

export let COUPONS_LIST_RESOURCE

export let COUPONS_VALID_PERIOD

export let COUPONS_FROM_SCOPE

export let COUPONS_FROM_RES_SCOPE

export let COUPONS_SELECT_TYPE

export let COUPONS_TYPE_OBJ

export let COUPONS_TYPE_UNIT

export let COUPONS_SELECT_SCOPE

export let COUPONS_SCOPE_OBJ

export let COUPONS_GRANT_REASON

export let COUPONS_GRANT
export let COUPONS_GRANT_OBJ

export let COUPONS_GRANT_RANGE

export let RESOURCE_SCOPE_OBJ

export let RESOURCE_SCOPE_STATES
export let GRANTED_REASON_OBJ
export let GRANTED_REASON_STATES
export let COUPONS_STATE_OBJ
export let COUPONS_STATE_TYPE
export let ORG_COUPON_GRANT_RECORD_OBJ
export let ORG_COUPON_GRANT_RECORD

export let autoApprovalData
export let arrayData
export let visitAutoApproval
export let ENTRY_LEVEL
export let RETURN_VISIT

// TODO: taoh - 用constants重写，并移到相应的constants/[模块].js

export function initContantsContent() {
  INVOICES_TYPES_OBJ = {
    common: intl.get('fapiao.type.common'),
    special: intl.get('fapiao.type.special'),
    personal: intl.get('fapiao.type.personal'),
  }
  INCOME_CONFIRM_STATE_OBJ = {
    true: intl.get('const.income.confirm.state.true'),
    false: intl.get('const.income.confirm.state.false'),
  }
  INCOME_CONFIRM_STATE = [
    {
      id: true,
      name: intl.get('const.income.confirm.state.true'),
    },
    {
      id: false,
      name: intl.get('const.income.confirm.state.false'),
    },
  ]
  SHOP_DELIVERY_METHOD = {
    no_delivery: intl.get('const.shop.delivery.method.no_delivery'),
    pickup: intl.get('const.shop.delivery.method.pickup'),
    delivery: intl.get('const.shop.delivery.method.delivery'),
  }
  ACTIVITY_ORDER_STATUS_OBJ = {
    active: intl.get('const.activity.order.status.active'),
    reserved: intl.get('const.activity.order.status.reserved'),
    cancelled: intl.get('const.activity.order.status.cancelled'),
    rejected: intl.get('const.activity.order.status.rejected'),
  }
  ACTIVITY_ORDER_STATUS = [
    {
      id: 'active',
      name: intl.get('const.activity.order.status.active'),
    },
    {
      id: 'reserved',
      name: intl.get('const.activity.order.status.reserved'),
    },
    {
      id: 'cancelled',
      name: intl.get('const.activity.order.status.cancelled'),
    },
  ]
  ACTIVITY_QUESTION_STATUS = {
    approving: {
      title: intl.get('activities.activities_comments.status.unreviewed'),
      color: 'orange',
    },
    approved: {
      title: intl.get('activities.activities_comments.status.pass'),
      color: 'green',
    },
    rejected: {
      title: intl.get('const.activity.order.status.rejected'),
      color: 'red',
    },
  }
  USER_TYPE_OBJ = {
    entered: intl.get('const.user.type.entered'),
    membership: intl.get('const.user.type.membership'),
    visitor: intl.get('const.user.type.visitor'),
  }
  USER_TYPE = [
    {
      name: intl.get('const.user.type.entered'),
      value: 'entered',
    },
    {
      name: intl.get('const.user.type.membership'),
      value: 'membership',
    },
    {
      name: intl.get('const.user.type.visitor'),
      value: 'visitor',
    },
  ]
  POST_CATEGORY_OBJ = {
    normal: intl.get('const.post.category.normal'),
    demand: intl.get('const.post.category.demand'),
  }

  VISIT_SOURCE = [
    {
      id: 'wechat_app',
      color: STATE_COLOR.pink_purple,
    },
    {
      id: 'face_plus',
      color: STATE_COLOR.blue,
    },
  ]

  VISIT_STATES = [
    {
      id: 'approving',
      color: STATE_COLOR.orange,
    },
    {
      id: 'disapproved',
      color: STATE_COLOR.red, // 已拒绝
    },
    {
      id: 'sended_invite',
      color: STATE_COLOR.pink_purple, // 已发送邀请
    },
    {
      id: 'waiting_for_signin',
      color: STATE_COLOR.blue, // 待访问
    },
    {
      id: 'rejected',
      color: STATE_COLOR.red, // 已拒绝邀请
    },
    {
      id: 'signined',
      color: STATE_COLOR.green, // 已签到
    },
    {
      id: 'signouted',
      color: STATE_COLOR.purple, // 已签退
    },
    {
      id: 'cancelled',
      color: STATE_COLOR.gray, // 已取消
    },
    {
      id: 'organization_approving',
      color: STATE_COLOR.yellow,
    },
    {
      id: 'organization_disapproved',
      color: STATE_COLOR.pink, // 已拒绝
    },
  ]

  // approving: 0,           # 待项目审批
  // disapproved: 1,         # 项目审批驳回
  // sended_invite: 2,       #客户已邀请
  // waiting_for_signin: 3,  #待访问（访客待签到）
  // rejected: 4,            # 访客拒绝邀请
  // signined: 5,            # 访客已签到
  // signouted: 6,           # 访客已签退
  // cancelled: 7,            # 已取消
  // organization_approving: 8, # 待公司审批
  // organization_disapproved: 9 # 公司审批驳回

  DEFAULT_VISITOR_TYPE = {
    _visitor: intl.get('const.visit.audit.type.access_member'),
    _tour: intl.get('const.visit.audit.type.visit_project'),
  }

  LINE_ITEM_TYPES = [
    {
      id: 'late_fee',
      name: intl.get('const.line.item.type.late_fee'),
    },
    {
      id: 'house_fee',
      name: intl.get('const.line.item.type.house_fee'),
    },
    {
      id: 'renting_fee',
      name: intl.get('const.line.item.type.renting_fee'),
    },
    {
      id: 'deposit_fee',
      name: intl.get('const.line.item.type.deposit_fee'),
    },
    {
      id: 'water_fee',
      name: intl.get('const.line.item.type.water_fee'),
    },
    {
      id: 'electric_fee',
      name: intl.get('const.line.item.type.electric_fee'),
    },
    {
      id: 'network_fee',
      name: intl.get('const.line.item.type.network_fee'),
    },
    {
      id: 'express_fee',
      name: intl.get('const.line.item.type.express_fee'),
    },
    {
      id: 'damaged_fee',
      name: intl.get('const.line.item.type.damaged_fee'),
    },
    {
      id: 'commission_fee',
      name: intl.get('const.line.item.type.commission_fee'),
    },
    {
      id: 'parking_fee',
      name: intl.get('const.line.item.type.parking_fee'),
    },
    {
      id: 'other',
      name: intl.get('const.line.item.type.other'),
    },
  ]

  POINT_RECHARGE_TABS = [
    {
      id: 'giving',
      name: intl.get('const.point.recharge.tabs.giving'),
    },
    {
      id: 'recharge',
      name: intl.get('const.point.recharge.tabs.recharge'),
    },
    {
      id: 'revoke',
      name: intl.get('const.point.recharge.tabs.revoke'),
    },
  ]

  INVOICE_REVIEWED_STATUS = [
    {
      id: true,
      color: STATE_COLOR.success,
      status: 'success',
    },
    {
      id: false,
      color: STATE_COLOR.warning,
      status: 'error',
    },
  ]

  INVOICE_APPLICATION_STATES = [
    {
      id: 'pending',
      name: intl.get('const.invoice.application.state.pending'),
      color: STATE_COLOR.processing,
      status: 'warning',
    },
    {
      id: 'process',
      name: intl.get('const.invoice.application.state.process'),
      color: STATE_COLOR.processing,
      status: 'warning',
    },
    {
      id: 'issued',
      name: intl.get('const.invoice.application.state.issued'),
      color: STATE_COLOR.success,
      status: 'success',
    },
    {
      id: 'rejected',
      name: intl.get('const.invoice.application.state.rejected'),
      color: STATE_COLOR.red,
      status: 'error',
    },
    {
      id: 'cancelled',
      name: intl.get('const.invoice.application.state.cancelled'),
      color: STATE_COLOR.default,
      status: 'default',
    },
    {
      id: 'aborted',
      name: intl.get('const.invoice.application.state.aborted'),
      color: STATE_COLOR.default,
      status: 'default',
    },
  ]

  POINT_PLAN_CUSTOMER_SPECIAL_TYPE = [
    {
      id: 'org',
      color: STATE_COLOR.success,
      status: 'success',
    },
  ]

  POINT_PLAN_CUSTOMER_TYPE = [
    {
      id: 'org',
      color: STATE_COLOR.success,
      status: 'success',
    },
    {
      id: 'user',
      color: STATE_COLOR.processing,
      status: 'warning',
    },
  ]

  CRM_CHANNEL_TYPE = [
    {
      id: 'visiting_date',
      color: STATE_COLOR.success,
      status: 'success',
    },
    {
      id: 'success_date',
      color: STATE_COLOR.processing,
      status: 'warning',
    },
  ]

  // ORG_MEMBER_SKILLS = [
  //   { id: '数据分析', name: intl.get('const.org.member.skills.k1') },
  //   { id: '项目管理', name: intl.get('const.org.member.skills.k2') },
  //   { id: '产品运营', name: intl.get('const.org.member.skills.k3') },
  //   { id: '产品设计', name: intl.get('const.org.member.skills.k4') },
  //   { id: 'UI/UX', name: intl.get('const.org.member.skills.k5') },
  //   { id: '移动开发', name: intl.get('const.org.member.skills.k6') },
  //   { id: '网站开发', name: intl.get('const.org.member.skills.k7') },
  //   { id: '后台开发', name: intl.get('const.org.member.skills.k8') },
  //   { id: 'IT支持', name: intl.get('const.org.member.skills.k9') },
  //   { id: '市场营销', name: intl.get('const.org.member.skills.k10') },
  //   { id: '创意策划', name: intl.get('const.org.member.skills.k11') },
  //   { id: '行政管理', name: intl.get('const.org.member.skills.k12') },
  //   { id: '财务会计', name: intl.get('const.org.member.skills.k13') },
  //   { id: '人力资源', name: intl.get('const.org.member.skills.k14') },
  //   { id: '广告', name: intl.get('const.org.member.skills.k15') },
  //   { id: '金融', name: intl.get('const.org.member.skills.k16') },
  //   { id: '法律', name: intl.get('const.org.member.skills.k17') },
  //   { id: '采购贸易', name: intl.get('const.org.member.skills.k18') },
  //   { id: '商务谈判', name: intl.get('const.org.member.skills.k19') },
  //   { id: '播音主持', name: intl.get('const.org.member.skills.k20') },
  //   { id: '新媒体运营', name: intl.get('const.org.member.skills.k21') },
  //   { id: '摄影摄像', name: intl.get('const.org.member.skills.k22') },
  //   { id: '心理咨询', name: intl.get('const.org.member.skills.k23') }
  // ]

  SUBSCRIPTION_STATE = [
    {
      id: 'enabled',
      name: intl.get('templete.state.enabled'),
    },
    {
      id: 'disabled',
      name: intl.get('templete.state.disabled'),
    },
    {
      id: 'draft',
      name: intl.get('templete.state.draft'),
    },
  ]

  STATISTIC_CYCLE_DWM = [
    {
      id: 'daily',
      name: intl.get('const.statistic.cycle.daily'),
    },
    {
      id: 'weekly',
      name: intl.get('const.statistic.cycle.weekly'),
    },
    {
      id: 'monthly',
      name: intl.get('const.statistic.cycle.monthly'),
    },
  ]
  STATISTIC_CYCLE = [
    {
      id: 'daily',
      name: intl.get('const.statistic.cycle.daily'),
    },
    {
      id: 'weekly',
      name: intl.get('const.statistic.cycle.weekly'),
    },
    {
      id: 'monthly',
      name: intl.get('const.statistic.cycle.monthly'),
    },
    {
      id: 'yearly',
      name: intl.get('const.statistic.cycle.yearly'),
    },
  ]
  CRM_INVENTORY_EXPIRING_DATE_STRING = {
    oneMonth: intl.get('const.crm.inventory.expiring.date.string.oneMonth'),
    twoMonth: intl.get('const.crm.inventory.expiring.date.string.twoMonth'),
    threeMonth: intl.get('const.crm.inventory.expiring.date.string.threeMonth'),
    moreMonth: intl.get('const.crm.inventory.expiring.date.string.moreMonth'),
  }
  MEMBERSHIP_RESOURCE_TYPE = [
    {
      id: 'desk',
      name: intl.get('const.membership.resource.type.desk'),
    },
    {
      id: 'booking',
      name: intl.get('const.membership.resource.type.booking'),
    },
    {
      id: 'printing',
      name: intl.get('const.membership.resource.type.printing'),
    },
    {
      id: 'point',
      name: intl.get('const.membership.resource.type.point'),
    },
  ]
  REPORT_LINK_STATE = {
    active: intl.get('const.report.link.state.active'),
    failed: intl.get('const.report.link.state.failed'),
    pending: intl.get('const.report.link.state.pending'),
    uploading: intl.get('const.report.link.state.uploading'),
  }
  REPORT_LINK_STATE_COLOR = {
    active: '#87d068',
    failed: '#f50',
    pending: '#2db7f5',
    uploading: '#108ee9',
  }
  SPACE_ROLES = {
    admin: intl.get('const.space.role.admin'),
    hr: intl.get('const.space.role.hr'),
    crm: intl.get('const.space.role.crm'),
    crm_admin: intl.get('const.space.role.crm_admin'),
    repair: intl.get('const.space.role.repair'),
    finance: intl.get('const.space.role.finance'),
    faq: intl.get('const.space.role.faq'),
    app: intl.get('const.space.role.app'),
    statistic: intl.get('const.space.role.statistic'),
    shop_manager: intl.get('const.space.role.shop_manager'),
    shop: intl.get('const.space.role.shop'),
    membership: intl.get('const.space.role.membership'),
    content: intl.get('const.space.role.content'),
  }

  // SHOP_TYPES = {
  //   online: intl.get('const.shop.type.online'),
  //   offline: intl.get('const.shop.type.offline')
  // }

  SHOP_STATUS = [
    {
      id: 'on_sale',
      name: 'const.shop.status.on_sale',
    },
    {
      id: 'off_sale',
      name: 'const.shop.status.off_sale',
    },
  ]

  SHOP_STOCK_TYPE = [
    {
      id: 'all_stock',
      color: STATE_COLOR.success,
      status: 'success',
    },
    {
      id: 'day_stock',
      color: STATE_COLOR.warning,
      status: 'error',
    },
  ]

  SHOP_TYPE = [
    {
      id: 'online',
      name: intl.get('const.shop.type.online'),
    },
    {
      id: 'offline',
      name: intl.get('const.shop.type.offline'),
    },
  ]
  STATISTIC_MODE = [
    {
      id: 'location',
      name: intl.get('const.statistic.mode.location'),
    },
    {
      id: 'user',
      name: intl.get('const.statistic.mode.user'),
    },
  ]

  // LOCKS_PERMISSIONS_PROGRESS = {
  //   upcoming: intl.get('const.locks.permissions.progress.upcoming'),
  //   current: intl.get('const.locks.permissions.progress.current'),
  //   ended: intl.get('const.locks.permissions.progress.ended')
  // }

  // LOCKS_PERMISSIONS_PROGRESS_COLOR = {
  //   upcoming: 'blue',
  //   current: 'green',
  //   ended: 'orange'
  // }

  FAQ_FILES_STATE = [
    {
      id: 'active',
      name: intl.get('const.faq.files.state.active'),
    },
    {
      id: 'hidden',
      name: intl.get('const.faq.files.state.hidden'),
    },
    {
      id: 'draft',
      name: intl.get('const.faq.files.state.draft'),
    },
  ]

  TASK_SOURCES = ['space_member', 'organization_user']
  ALL_AREAS = ['public_area', 'tenant_area']

  // AREA_IS_DISABLED = [
  //   {
  //     id: false,
  //     name: intl.get('locker.const.locker_status.normal'),
  //   }, {
  //     id: true,
  //     name: intl.get('locker.const.locker_status.disabled'),
  //   },
  // ]
  // AREA_IS_COMMERCIAL = [
  //   {
  //     id: true,
  //     name: intl.get('is_commercial.true'),
  //   }, {
  //     id: false,
  //     name: intl.get('is_commercial.false'),
  //   },
  // ]
  // AREA_IS_COMMERCIAL_OBJ = {
  //   true: intl.get('is_commercial.true'),
  //   false: intl.get('is_commercial.false'),
  // }
  AREA_INFO_STATE = [
    {
      id: 'free',
      name: intl.get('desks.area_info.state.free'),
    },
    {
      id: 'assigned',
      name: intl.get('desks.area_info.state.assigned'),
    },
    {
      id: 'disabled',
      name: intl.get('desks.area_info.state.disabled'),
    },
  ]

  NODE_STATES = {
    wechat: intl.get('const.crm.node.type.wechat'),
    phone: intl.get('const.crm.node.type.phone'),
    visit: intl.get('const.crm.node.type.visit'),
    send_email: intl.get('const.crm.node.type.send_email'),
    send_message: intl.get('const.crm.node.type.send_message'),
    send_qq: intl.get('const.crm.node.type.qq'),
    other: intl.get('const.crm.node.type.other'),
  }
  CRM_NODE_TYPES = [
    {
      id: 'visit',
      name: intl.get('const.crm.node.type.visit'),
    },

    {
      id: 'phone',
      name: intl.get('const.crm.node.type.phone'),
    },
    {
      id: 'send_email',
      name: intl.get('const.crm.node.type.send_email'),
    },
    {
      id: 'wechat',
      name: intl.get('const.crm.node.type.wechat'),
    },
    {
      id: 'qq',
      name: intl.get('const.crm.node.type.qq'),
    },
    {
      id: 'other',
      name: intl.get('const.crm.node.type.other'),
    },
  ]
  INVOICE_CERTIFICATE_PAYMENT_TYPES = [
    {
      id: 'cash',
      name: intl.get('const.invoice.order.certificate.payment.type.cash'),
    },
    {
      id: 'point',
      name: intl.get('const.invoice.order.certificate.payment.type.point'),
    },
    {
      id: 'manually',
      name: intl.get('const.invoice.order.certificate.payment.type.manually'),
    },
    {
      id: 'pos',
      name: intl.get('const.invoice.order.certificate.payment.type.pos'),
    },
    {
      id: 'union_pay',
      name: intl.get('const.invoice.order.certificate.payment.type.union_pay'),
    },
    {
      id: 'wechat_offline',
      name: intl.get(
        'const.invoice.order.certificate.payment.type.wechat_offline',
      ),
    },
    {
      id: 'alipay_offline',
      name: intl.get(
        'const.invoice.order.certificate.payment.type.alipay_offline',
      ),
    },
    {
      id: 'balance',
      name: intl.get('const.invoice.order.certificate.payment.type.balance'),
    },
    {
      id: 'credit',
      name: intl.get('const.invoice.order.certificate.payment.type.credit'),
    },
    {
      id: 'other',
      name: intl.get('const.invoice.order.certificate.payment.type.other'),
    },
  ]
  // credit,money,point,balance,invoice,coupon,offline

  PAYMENT_TYPES = [
    {
      id: 'credit',
      name: intl.get('const.invoice.order.certificate.payment.type.credit'), // invoices.type
    },
    {
      id: 'money',
      name: intl.get('const.payment.type.money'),
    },
    {
      id: 'point',
      name: intl.get('const.invoice.order.certificate.payment.type.point'),
    },
    {
      id: 'balance',
      name: intl.get('const.invoice.order.certificate.payment.type.balance'),
    },
    {
      id: 'coupon',
      name: intl.get('const.invoice.sales.payment.coupon'),
    },
    {
      id: 'offline_paid',
      name: intl.get('const.invoice.sales.payment.offline'),
    },
    {
      id: 'invoice',
      name: intl.get('const.invoice.sales.payment.invoice'),
    },
  ]

  INVOICE_ORDER_CERTIFICATE_PAYMENT_TYPES = [
    {
      id: 'cash',
      name: intl.get('const.invoice.order.certificate.payment.type.cash'),
    },
    {
      id: 'point',
      name: intl.get('const.invoice.order.certificate.payment.type.point'),
    },
    {
      id: 'balance',
      name: intl.get('const.invoice.order.certificate.payment.type.balance'),
    },
    {
      id: 'manually',
      name: intl.get('const.invoice.order.certificate.payment.type.manually'),
    },
    {
      id: 'pos',
      name: intl.get('const.invoice.order.certificate.payment.type.pos'),
    },
    {
      id: 'union_pay',
      name: intl.get('const.invoice.order.certificate.payment.type.union_pay'),
    },
    {
      id: 'wechat_offline',
      name: intl.get(
        'const.invoice.order.certificate.payment.type.wechat_offline',
      ),
    },
    {
      id: 'alipay_offline',
      name: intl.get(
        'const.invoice.order.certificate.payment.type.alipay_offline',
      ),
    },
    {
      id: 'credit',
      name: intl.get('const.invoice.order.certificate.payment.type.credit'),
    },
    {
      id: 'other',
      name: intl.get('const.invoice.order.certificate.payment.type.other'),
    },
  ]

  INVOICE_SALES_PAYMENTS = {
    cash: intl.get('const.invoice.sales.payment.cash'),
    pos: intl.get('const.invoice.sales.payment.pos'),
    point: intl.get('const.invoice.sales.payment.point'),
    union_pay: intl.get('const.invoice.sales.payment.union_pay'),
    wechat_offline: intl.get('const.invoice.sales.payment.wechat_offline'),
    alipay_offline: intl.get('const.invoice.sales.payment.alipay_offline'),
    balance: intl.get('const.invoice.sales.payment.balance'),
    credit: intl.get('const.invoice.sales.payment.credit'),
    manually: intl.get('const.invoice.sales.payment.manually'),
    coupon: intl.get('const.invoice.sales.payment.coupon'),
    invoice: intl.get('const.invoice.sales.payment.invoice'),
    other: intl.get('const.invoice.sales.payment.other'),
  }
  ORDER_STATE_ARRAY = [
    {
      id: '',
      name: intl.get('const.order.state.all'),
    },
    {
      id: 'unpaid',
      name: intl.get('const.order.state.unpaid'),
    },
    {
      id: 'paid',
      name: intl.get('const.order.state.paid'),
    },
    {
      id: 'cancelled',
      name: intl.get('const.order.state.cancelled'),
    },
    {
      id: 'refunding',
      name: intl.get('const.order.state.refunding'),
    },
    {
      id: 'refunded',
      name: intl.get('const.order.state.refunded'),
    },
  ]
  ORDER_STATES_ARRAY = [
    {
      id: 'unpaid',
      name: intl.get('const.order.state.unpaid'),
    },
    {
      id: 'paid',
      name: intl.get('const.order.state.paid'),
    },
    {
      id: 'cancelled',
      name: intl.get('const.order.state.cancelled'),
    },
  ]

  INVOICES_UPDATE_STATE = [
    {
      id: 'unpaid',
      name: intl.get('const.invoices.update.state.unpaid'),
    },
    {
      id: 'partially_paid',
      name: intl.get('const.invoices.update.state.partially_paid'),
    },
    {
      id: 'paid',
      name: intl.get('const.invoices.update.state.paid'),
    },
    {
      id: 'part_refund',
      color: STATE_COLOR.pink_purple,
      status: 'success',
    },
    {
      id: 'overdue',
      name: intl.get('const.invoices.update.state.overdue'),
    },
    {
      id: 'refunding',
      name: intl.get('const.invoices.update.state.refunding'),
    },
    {
      id: 'refunded',
      name: intl.get('const.invoices.state.refunded'),
    },
    // {
    //   id   : 'offline_paid',
    //   name : intl.get('const.invoice.sales.payment.offline'),
    // }
  ]
  INVOICES_IS_REVIEWED = [
    {
      id: 'true',
      name: intl.get('const.invoices.is.reviewed.true'),
    },
    {
      id: 'false',
      name: intl.get('const.invoices.is.reviewed.false'),
    },
  ]
  INVOICES_SENDING_STATE = {
    unsent: intl.get('const.invoices.send.state.unsent'),
    sent: intl.get('const.invoices.send.state.sent'),
  }
  INVOICES_UPDATE_TYPE = [
    {
      id: 'deposit',
      name: intl.get('invoices.type.deposit'),
    },
    {
      id: 'rent',
      name: intl.get('invoices.type.rent'),
    },
    {
      id: 'house_fee',
      name: intl.get('invoices.type.house_fee'),
    },
    {
      id: 'refund',
      name: intl.get('invoices.type.refund'),
    },
    {
      id: 'custom',
      name: intl.get('invoices.type.custom'),
    },
    {
      id: 'monthly_reservation',
      name: intl.get('invoices.type.monthly_reservation'),
    },
    // {
    //   id   : 'reservation',
    //   name : intl.get('invoices.type.reservation'),
    // },
    // {
    //   id   : 'desk_reservation',
    //   name : intl.get('common.desk'),
    // },
    // {
    //   id   : 'membership',
    //   name : intl.get('invoices.type.membership'),
    // },
    // {
    //   id   : 'membership_resource',
    //   name : intl.get('invoices.type.membership_resource'),
    // }
  ]

  INVOICES_UPDATE_TYPE_BILL = [
    {
      id: 'deposit',
      name: intl.get('invoices.type.deposit'),
    },
    {
      id: 'rent',
      name: intl.get('invoices.type.rent'),
    },
    {
      id: 'refund',
      name: intl.get('invoices.type.refund'),
    },
    {
      id: 'custom',
      name: intl.get('invoices.type.custom'),
    },
    {
      id: 'monthly_reservation',
      name: intl.get('invoices.type.monthly_reservation'),
    },
  ]

  INVOICES_TYPE = {
    deposit: intl.get('invoices.type.deposit'),
    rent: intl.get('invoices.type.rent'),
    rent_and_deposit: intl.get('invoices.type.rent_and_deposit'),
    refund: intl.get('invoices.type.refund'),
    custom: intl.get('invoices.type.custom'),
    reservation: intl.get('invoices.type.reservation'),
    membership: intl.get('invoices.type.membership'),
    membership_resource: intl.get('invoices.type.membership_resource'),
    point_plan: intl.get('invoices.type.point_plan'),
    print_reservation: intl.get('invoices.type.print_reservation'),
    product_reservation: intl.get('invoices.type.product_reservation'),
    activity: intl.get('invoices.type.activity'),
    desk_reservation: intl.get('invoices.type.desk_reservation'),
    monthly_reservation: intl.get('invoices.type.monthly_reservation'),
    cabinet_reservation: intl.get('invoices.type.cabinet_reservation'),
    cycle_fee: intl.get('invoices.type.cycle_fee'),
    monthly_reservation: intl.get('invoices.type.monthly_reservation'),
    // additional: intl.get('invoices.type.additional'),
    monthly_cabinet_reservation: intl.get(
      'invoices.type.monthly_cabinet_reservation',
    ),
    commission_fee: intl.get('invoices.type.commission_fee'),
    monthly_desk_reservation: intl.get(
      'invoices.type.monthly_desk_reservation',
    ),
    monthly_activity: intl.get('invoices.type.monthly_activity'),
  }
  INVOICES_STATE = {
    unpaid: intl.get('const.invoices.state.unpaid'),
    partially_paid: intl.get('const.invoices.state.partially_paid'),
    paid: intl.get('const.invoices.state.paid'),
    part_refund: intl.get('const.invoices.state.part_refund'),
    overdue: intl.get('const.invoices.state.overdue'),
    refunding: intl.get('const.invoices.state.refunding'),
    refunded: intl.get('const.invoices.state.refunded'),
    cancelled: intl.get('const.invoices.state.cancelled'),
    offline_paid: intl.get('const.invoices.state.paid'),
    confirmed: intl.get('const.invoices.state.confirmed'),
    check_paid: intl.get('invoices.deposit_list.string.check_paid'),

    // offline_paid: intl.get('const.invoice.sales.payment.offline'),
  }

  ATTENDANCE_SOURCE = [
    {
      id: 'inside',
      color: STATE_COLOR.green,
      status: 'success',
    },
    {
      id: 'outside',
      color: STATE_COLOR.orange,
      status: 'warning',
    },
  ]

  MEETING_CHECK_STATUS = [
    {
      id: 'pending_check',
      color: STATE_COLOR.orange,
      status: 'warning',
    },
    {
      id: 'checked',
      color: STATE_COLOR.green,
      status: 'success',
    },
  ]

  ATTENDANCE_STATS = [
    {
      id: 'pending',
      color: STATE_COLOR.orange,
      status: 'warning',
    },
    {
      id: 'pass',
      color: STATE_COLOR.green,
      status: 'success',
    },
    {
      id: 'reject',
      color: STATE_COLOR.red,
      status: 'error',
    },
  ]
  INVOICES_FASHIONABLE_STATE = [
    {
      id: 'paid',
      color: STATE_COLOR.green,
      status: 'success',
    },
    {
      id: 'refunding',
      color: STATE_COLOR.refunding,
      status: 'warning',
    },
    {
      id: 'refunded',
      color: STATE_COLOR.success,
      status: 'success',
    },
    {
      id: 'part_refund',
      color: STATE_COLOR.pink_purple,
      status: 'success',
    },
  ]
  INVOICES_ORDER_STATE = [
    {
      id: 'paid',
      color: STATE_COLOR.green,
      status: 'success',
    },
    {
      id: 'refunded',
      color: STATE_COLOR.success,
      status: 'success',
    },
  ]
  INVOICES_STATE_LIST = [
    {
      id: 'unpaid',
      color: STATE_COLOR.unpaid,
      status: 'warning',
    },
    {
      id: 'partially_paid',
      color: STATE_COLOR.partially_paid,
      status: 'success',
    },

    {
      id: 'paid',
      color: STATE_COLOR.paid,
      status: 'success',
    },
    {
      id: 'part_refund',
      color: STATE_COLOR.pink_purple,
      status: 'success',
    },
    {
      id: 'overdue',
      color: STATE_COLOR.overdue,
      status: 'error',
    },
    {
      id: 'refunding',
      color: STATE_COLOR.refunding,
      status: 'warning',
    },
    {
      id: 'refunded',
      color: STATE_COLOR.success,
      status: 'success',
    },

    {
      id: 'cancelled',
      color: STATE_COLOR.default,
      status: 'default',
    },
    {
      id: 'offline_paid',
      color: STATE_COLOR.success,
      status: 'success',
    },
    {
      id: 'confirmed',
      color: STATE_COLOR.success,
      status: 'success',
    },
    {
      id: 'check_paid',
      color: STATE_COLOR.success,
      status: 'success',
    },
    {
      id: 'close',
      color: STATE_COLOR.default,
      status: 'default',
    },
    { id: 'pending_approved', color: STATE_COLOR.default, status: 'default' },
  ]

  INVOICES_FASHIONABLE_STATE_LIST = [
    {
      id: 'paid',
      color: STATE_COLOR.paid,
      status: 'success',
    },
    {
      id: 'part_refund',
      color: STATE_COLOR.pink_purple,
      status: 'success',
    },
    {
      id: 'refunding',
      color: STATE_COLOR.refunding,
      status: 'warning',
    },
    {
      id: 'refunded',
      color: STATE_COLOR.success,
      status: 'success',
    },
  ]

  TASK_SUBTYPE = [
    {
      id: 'it',
      name: intl.get('const.task.subtype.it'),
    },
    {
      id: 'engineering',
      name: intl.get('const.task.subtype.engineering'),
    },
    {
      id: 'office',
      name: intl.get('const.task.subtype.office'),
    },
    {
      id: 'water',
      name: '水路管件',
    },
    {
      id: 'wall',
      name: '墙地面',
    },
    {
      id: 'install',
      name: '开锁换锁',
    },
    {
      id: 'lock',
      name: '安装拆除',
    },
    // {
    //   id: 'client',
    //   name: '客户吐槽',
    // },
    {
      id: 'other',
      name: intl.get('const.task.subtype.other'),
    },
  ]

  TASK_SUBTYPES = {
    air: '暖通空调',
    maintenance: '维护',
    it: intl.get('const.task.subtype.it'),
    office: intl.get('const.task.subtype.office'),
    engineering: intl.get('const.task.subtype.engineering'),
    requirement: intl.get('const.task.subtype.suggest'),
    suggestion: intl.get('const.task.subtype.suggest'),
    general: intl.get('const.opportunity.desires.ordinary'),
    other: intl.get('const.task.subtype.other'),
    water: '水路管件',
    wall: '墙地面',
    install: '开锁换锁',
    lock: '安装拆除',
    client: '客户吐槽',
    property_cleaning: '物业保洁',
    glass_lamps: '玻璃灯具',
    sofa_carpet: '沙发地毯',
    refurbishment: '翻新养护',
    wasteland_cleaning: '开荒保洁',
    air_purification: '空气净化',
    insect_killing: '除虫除蚁',
    complaint: '投诉',
    suggest: '建议',
    client: '吐槽',
  }

  // prefix: const.space.member.location.role
  SPACE_MEMBER_LOCATION_ROLES = [
    {
      id: 'location_manager',
      features: ['asset_management'],
    },
    {
      id: 'location_operator',
      features: ['asset_management'],
    },
    {
      id: 'observer',
      features: ['asset_management'],
    },
    {
      id: 'finance',
      features: ['asset_management'],
    },
    {
      id: 'sales',
      features: ['asset_management'],
    },
    {
      id: 'sales_listener',
      features: ['asset_management'],
    },
    {
      id: 'contract',
      features: ['asset_management'],
    },
    {
      id: 'contract_terminate',
      features: ['asset_management'],
    },
    {
      id: 'contract_listener',
      features: ['asset_management'],
    },
    {
      id: 'contract_approval_listener',
      features: ['asset_management'],
    },
    {
      id: 'contract_expire_listener',
      features: ['asset_management'],
    },
    {
      id: 'invoice_check',
      features: ['asset_management'],
    },
    {
      id: 'invoice_check_notify',
      features: ['asset_management'],
    },
    {
      id: 'invoice_confirm',
      features: ['asset_management'],
    },
    {
      id: 'invoice_listener',
      features: ['asset_management'],
    },
    {
      id: 'invoice_sended_listener',
      features: ['asset_management'],
    },
    {
      id: 'association_listener', // 发帖通知
    },
    {
      id: 'task_feedback_listener',
    },
    {
      id: 'reservation_approval_listener',
    },
    {
      id: 'reservation_cancel_listener',
    },
    {
      id: 'reservation_change_listener',
    },
    {
      id: 'visitor_listener',
    },

    {
      id: 'activity_listener',
    },

    {
      id: 'task_repair_listener',
    },
    {
      id: 'reservation_listener',
    },

    {
      id: 'repair',
    },
    {
      id: 'repair_manager',
    },
    {
      id: 'clean',
    },
    {
      id: 'clean_manager',
    },
    {
      id: 'task_suggest',
    },
    {
      id: 'task_suggest_manager',
    },
    {
      id: 'task_complaint',
    },
    {
      id: 'task_complaint_manager',
    },

    {
      id: 'meeting_room_manager',
    },
    {
      id: 'meeting_room_auditor',
    },
    {
      id: 'cancel_reservation_auditor',
    },
    {
      id: 'visitor_approve',
    },
    // {
    //   id: 'custom_service',
    //   name: intl.get('const.space.member.location.role.custom_service')
    // },
    {
      id: 'package_in_record_user',
    },
    {
      id: 'package_out_record_user',
    },
    // {
    //   id: 'custom_service_manager',
    //   name: intl.get('const.space.member.location.role.custom_service_manager')
    // },
    {
      id: 'parking_contract',
    },
    {
      id: 'parking_manager',
    },
    {
      id: 'task_ac_overtime_service_manager',
    },
  ]

  DELIVERY_METHOD = [
    {
      id: 'delivery',
      color: STATE_COLOR.green,
      status: 'success',
    },
    {
      id: 'pickup',
      color: STATE_COLOR.red,
      status: 'warning',
    },
    {
      id: 'no_delivery',
      color: STATE_COLOR.orange,
      status: 'warning',
    },
  ]

  DELIVERY_TYPE = [
    {
      id: 'package',
      color: STATE_COLOR.green,
      status: 'success',
    },
    {
      id: 'self_pick',
      color: STATE_COLOR.red,
      status: 'warning',
    },
  ]

  TASK_PRIORITIES_FORM = [
    {
      id: 'normal',
      name: intl.get('const.task.priorities.normal'),
      color: STATE_COLOR.green,
      status: 'success',
    },
    {
      id: 'high',
      name: intl.get('const.task.priorities.high'),
      color: STATE_COLOR.red,
      status: 'warning',
    },
  ]
  TASK_PRIORITIES = {
    normal: intl.get('const.task.priorities.normal'),
    high: intl.get('const.task.priorities.high'),
  }
  // submit_task(提交)
  // -> in_progress(处理中)
  // -> pending(等待验收)
  // -> reopen(重新打开)
  // -> completed(完成)

  TASK_UPDATE_STATES = [
    {
      id: 'in_progress',
      name: intl.get('const.task.state.in_progress'),
    },
    {
      id: 'pending',
      name: intl.get('const.task.state.pending'),
    },
    {
      id: 'pending_confirm',
      name: intl.get('const.task.state.pending_confirm'),
    },
    {
      id: 'reopen',
      name: intl.get('const.task.state.reopen'),
    },
    {
      id: 'completed',
      name: intl.get('const.task.state.completed'),
    },
    {
      id: 'verified',
      name: intl.get('const.task.state.verified'),
    },
    {
      id: 'overdue',
      name: intl.get('const.task.state.overdue'),
    },
    {
      id: 'submit_task',
      name: intl.get('const.task.state.submit_task'),
    },
    {
      id: 'reject',
      name: intl.get('tasks.task_rate_form.validate.noAccept'),
    },
    {
      id: 'closed',
      name: intl.get('const.task.state.closed'),
    },
    {
      id: 'received',
      name: intl.get('const.service.request.state.request_accepted'),
    },
    {
      id: 'pending_visit',
      name: intl.get('const.task.sections.return_visit.state.pending_visit'),
    },
    {
      id: 'visited',
      name: intl.get('const.task.sections.return_visit.state.visited'),
    },
  ]

  RETURN_VISIT = [
    {
      id: 'pending_visit',
      color: STATE_COLOR.red,
      status: 'warning',
    },
    {
      id: 'visited',
      color: STATE_COLOR.green,
      status: 'success',
    },
    {
      id: 'completed',
      color: STATE_COLOR.processing,
      status: 'processing',
    },
  ]

  REPAIR_TASK_UPDATE_STATES = [
    {
      id: 'pending',
      name: intl.get('const.task.state.pending'),
    },
    {
      id: 'received',
      name: intl.get('const.task.state.received'),
    },
    {
      id: 'completed',
      name: intl.get('const.task.state.completed'),
    },
    {
      id: 'pending_confirm',
      name: intl.get('const.task.state.pending_confirm'),
    },
    {
      id: 'verified',
      name: intl.get('const.task.state.verified'),
    },
    {
      id: 'reject',
      name: intl.get('tasks.task_rate_form.validate.noAccept'),
    },
    {
      id: 'pending_visit',
      name: intl.get('const.task.sections.return_visit.state.pending_visit'),
    },
    {
      id: 'visited',
      name: intl.get('const.task.sections.return_visit.state.visited'),
    },
  ]

  AC_OVERTIME_TASK_TYPE = [
    {
      id: 'waited',
      color: STATE_COLOR.orange,
      status: 'warning',
    },
    {
      id: 'completed',
      color: STATE_COLOR.green,
      status: 'success',
    },

    {
      id: 'reject',
      color: STATE_COLOR.red,
      status: 'error',
    },
    {
      id: 'closed',
      color: STATE_COLOR.gray,
      status: 'default',
    },
  ]

  COMPLAINTS_TASK_TYPE = [
    {
      id: 'property_service',
      name: intl.get('const.task.state.complaints.type.property_service'),
    },
    {
      id: 'public_service',
      name: intl.get('const.task.state.complaints.type.public_service'),
    },
    {
      id: 'cleaning_service',
      name: intl.get('const.task.state.complaints.type.cleaning_service'),
    },
    {
      id: 'other_service',
      name: intl.get('const.task.state.complaints.type.other'),
    },
  ]

  COMPLAINTS_TASK_UPDATE_STATES = [
    {
      id: 'pending',
      name: intl.get('const.task.state.complaints.pending'),
      color: STATE_COLOR.purple,
      status: 'warning',
    },
    {
      id: 'received',
      name: intl.get('const.task.state.received'),
      color: STATE_COLOR.processing,
      status: 'warning',
    },
    {
      id: 'completed',
      name: intl.get('const.task.state.complaints.completed'),
      color: STATE_COLOR.success,
      status: 'success',
    },
    {
      id: 'verified',
      name: intl.get('const.task.state.complaints.verified'),
      color: STATE_COLOR.success,
      status: 'success',
    },
    {
      id: 'reject',
      name: intl.get('tasks.task_rate_form.validate.noAccept'),
      color: STATE_COLOR.default,
      status: 'default',
    },
    {
      id: 'pending_visit',
      name: intl.get('const.task.sections.return_visit.state.pending_visit'),
      color: STATE_COLOR.red,
      status: 'warning',
    },
    {
      id: 'visited',
      name: intl.get('const.task.sections.return_visit.state.visited'),
      color: STATE_COLOR.success,
      status: 'success',
    },
  ]

  SUGGEST_TASK_UPDATE_STATES = [
    {
      id: 'unreply',
      name: intl.get('const.task.sections.feedback.no_reply'),
      color: STATE_COLOR.processing,
      status: 'warning',
    },
    {
      id: 'replied',
      name: intl.get('const.task.sections.feedback.reply'),
      color: STATE_COLOR.success,
      status: 'success',
    },
    // {
    //   id: 'pending_visit',
    //   name: intl.get('const.task.sections.return_visit.state.pending_visit'),
    //   color: STATE_COLOR.red,
    //   status: 'warning'
    // },
    // {
    //   id: 'visited',
    //   name: intl.get('const.task.sections.return_visit.state.visited'),
    //   color: STATE_COLOR.success,
    //   status: 'success'
    // }
  ]

  LOCK_ARRAY = [
    {
      title: intl.get('const.has'),
      id: true,
      name: intl.get('const.has'),
      value: true,
    },
    {
      title: intl.get('const.nil'),
      value: false,
      id: false,
      name: intl.get('const.nil'),
    },
  ]

  PAYMENT_TYPE = [
    {
      id: 'both',
      name: intl.get('const.payment.type.both'),
    },
    {
      id: 'money',
      name: intl.get('const.payment.type.money'),
    },
    {
      id: 'point',
      name: intl.get('const.payment.type.point'),
    },
  ]
  CRM_USER_TYPE_STAT = {
    all: intl.get('const.crm.user.type.status.all'),
    me: intl.get('const.crm.user.type.status.me'),
    descendant: intl.get('const.crm.user.type.status.descendant'),
  }
  CRM_USER_TYPE_STATUS = [
    {
      name: intl.get('const.crm.user.type.status.all'),
      id: 'all',
    },
    {
      name: intl.get('const.crm.user.type.status.me'),
      id: 'me',
    },
    {
      name: intl.get('const.crm.user.type.status.descendant'),
      id: 'descendant',
    },
  ]
  CRM_LEADS_STATE = {
    not_contact: intl.get('const.crm.leads.state.not_contact'),
    contacted: intl.get('const.crm.leads.state.contacted'),
    new_opportunity: intl.get('const.crm.leads.state.new_opportunity'),
    no_chance: intl.get('const.crm.leads.state.no_chance'),
  }
  CRM_LEADS_STATE_ARRAY = [
    {
      id: 'not_contact',
      name: intl.get('const.crm.leads.state.not_contact'),
    },
    {
      id: 'contacted',
      name: intl.get('const.crm.leads.state.contacted'),
    },
    {
      id: 'new_opportunity',
      name: intl.get('const.crm.leads.state.new_opportunity'),
    },
    {
      id: 'no_chance',
      name: intl.get('const.crm.leads.state.no_chance'),
    },
  ]
  CRM_FAILED_REASON_OBJ = {
    expensive: intl.get('const.crm.failed.reason.expensive'),
    no_need: intl.get('const.crm.failed.reason.no_need'),
    lighting: intl.get('const.crm.failed.reason.lighting'),
    layout: intl.get('const.crm.failed.reason.layout'),
    narrow: intl.get('const.crm.failed.reason.narrow'),
    old_fashion: intl.get('const.crm.failed.reason.old_fashion'),
    environment: intl.get('const.crm.failed.reason.environment'),
    story_height: intl.get('const.crm.failed.reason.story_height'),
    smell: intl.get('const.crm.failed.reason.smell'),
    chose_others: intl.get('const.crm.failed.reason.chose_others'),
  }
  CRM_FAILED_REASON = [
    {
      id: 'expensive',
      name: intl.get('const.crm.failed.reason.expensive'),
    },
    {
      id: 'no_need',
      name: intl.get('const.crm.failed.reason.no_need'),
    },
    {
      id: 'lighting',
      name: intl.get('const.crm.failed.reason.lighting'),
    },
    {
      id: 'layout',
      name: intl.get('const.crm.failed.reason.layout'),
    },
    {
      id: 'narrow',
      name: intl.get('const.crm.failed.reason.narrow'),
    },
    {
      id: 'old_fashion',
      name: intl.get('const.crm.failed.reason.old_fashion'),
    },
    {
      id: 'environment',
      name: intl.get('const.crm.failed.reason.environment'),
    },
    {
      id: 'story_height',
      name: intl.get('const.crm.failed.reason.story_height'),
    },
    {
      id: 'smell',
      name: intl.get('const.crm.failed.reason.smell'),
    },
    {
      id: 'chose_others',
      name: intl.get('const.crm.failed.reason.chose_others'),
    },
  ]
  CRM_OPPORTUNITY_DESIRES = [
    {
      id: 'very_strong',
      name: intl.get('const.opportunity.desires.very_strong'),
      class: 'desire-strong',
    },
    {
      id: 'ordinary',
      name: intl.get('const.opportunity.desires.ordinary'),
      class: 'desire-ordinary',
    },
    {
      id: 'not_strong',
      name: intl.get('const.opportunity.desires.not_strong'),
      class: 'desire-notstrong',
    },
    {
      id: 'not_clear',
      name: intl.get('const.opportunity.desires.not_clear'),
      class: 'desire-notclear',
    },
  ]

  CRM_VISIT_TYPE_OBJ = {
    visited: intl.get('const.crm.visit.type.visited'),
    pending: intl.get('const.crm.visit.type.pending'),
    no_visited: intl.get('const.crm.visit.type.no_visited'),
  }
  CRM_VISIT_TYPE = [
    {
      id: 'visited',
      name: intl.get('const.crm.visit.type.visited'),
    },
    {
      id: 'pending',
      name: intl.get('const.crm.visit.type.pending'),
    },
    {
      id: 'no_visited',
      name: intl.get('const.crm.visit.type.no_visited'),
    },
  ]

  CRM_OPP_VISIT_TYPE = [
    {
      value: 'visited',
      name: intl.get('const.crm.visit.type.visited'),
    },
    {
      value: 'pending',
      name: intl.get('const.crm.visit.type.pending'),
    },
    {
      value: 'no_visited',
      name: intl.get('const.crm.visit.type.no_visited'),
    },
  ]

  CRM_DESIRE_OBJ = {
    very_strong: intl.get('const.opportunity.desires.very_strong'),
    ordinary: intl.get('const.opportunity.desires.ordinary'),
    not_strong: intl.get('const.opportunity.desires.not_strong'),
    not_clear: intl.get('const.opportunity.desires.not_clear'),
  }
  CRM_DESIRE = [
    {
      id: 'very_strong',
      name: intl.get('const.opportunity.desires.very_strong'),
    },
    {
      id: 'ordinary',
      name: intl.get('const.opportunity.desires.ordinary'),
    },
    {
      id: 'not_strong',
      name: intl.get('const.opportunity.desires.not_strong'),
    },
    {
      id: 'not_clear',
      name: intl.get('const.opportunity.desires.not_clear'),
    },
  ]
  CRM_COMPANY_TYPE_OBJ = {
    office: intl.get('const.crm.company.type.office'),
    branch_office: intl.get('const.crm.company.type.branch_office'),
    startup: intl.get('const.crm.company.type.startup'),
    personal: intl.get('const.crm.company.type.personal'),
    other: intl.get('const.crm.company.type.other'),
    common: intl.get('const.crm.company.type.common'),
  }

  CRM_COMPANY_TYPE = [
    {
      id: 'office',
      name: intl.get('const.crm.company.type.office'),
    },
    {
      id: 'branch_office',
      name: intl.get('const.crm.company.type.branch_office'),
    },
    {
      id: 'startup',
      name: intl.get('const.crm.company.type.startup'),
    },
    {
      id: 'personal',
      name: intl.get('const.crm.company.type.personal'),
    },
    {
      id: 'common',
      name: intl.get('const.crm.company.type.common'),
    },
    {
      id: 'other',
      name: intl.get('const.crm.company.type.other'),
    },
  ]

  CRM_INTENT_RENT_AREA_SIZE_OBJ = {
    '1-10': '1-10',
    '11-20': '11-20',
    '21-50': '21-50',
    '51-100': '51-100',
    '101-200': '101-200',
    '200以上': intl.get('const.crm.intent.rent.area.size.title'),
  }
  CRM_INTENT_RENT_AREA_SIZE = [
    {
      id: '1-10',
      name: '1-10',
    },
    {
      id: '11-20',
      name: '11-20',
    },
    {
      id: '21-50',
      name: '21-50',
    },
    {
      id: '51-100',
      name: '51-100',
    },
    {
      id: '101-200',
      name: '101-200',
    },
    {
      id: '200以上',
      name: intl.get('const.crm.intent.rent.area.size.title'),
    },
  ]
  REQUIREMENT_TYPE_OBJ = {
    desk: intl.get('const.requirement.type.desk'),
    field: intl.get('const.requirement.type.field'),
    other: intl.get('const.requirement.type.other'),
  }
  REQUIREMENT_TYPE = [
    {
      id: 'desk',
      name: intl.get('const.requirement.type.desk'),
    },
    {
      id: 'field',
      name: intl.get('const.requirement.type.field'),
    },
    {
      id: 'other',
      name: intl.get('const.requirement.type.other'),
    },
  ]
  CRM_AREA_TYPE_OBJ = {
    public_office: intl.get('const.lock.manage.tipsy.public_office'),
    private_office: intl.get('const.lock.manage.tipsy.private_office'),
    flexible_office: intl.get('const.lock.manage.tipsy.flexible_office'),
    scattered: intl.get('const.lock.manage.tipsy.scattered'),
    floor: intl.get('const.lock.manage.tipsy.floor'),
  }
  CRM_AREA_TYPE = [
    {
      id: 'public_office',
      name: intl.get('const.lock.manage.tipsy.public_office'),
    },
    {
      id: 'private_office',
      name: intl.get('const.lock.manage.tipsy.private_office'),
    },
    {
      id: 'flexible_office',
      name: intl.get('const.lock.manage.tipsy.flexible_office'),
    },
    {
      id: 'scattered',
      name: intl.get('const.lock.manage.tipsy.scattered'),
    },
    {
      id: 'floor',
      name: intl.get('const.lock.manage.tipsy.floor'),
    },
  ]
  MEETING_SHIP_RESOURCES_TYPE_OBJ = {
    printing: intl.get('const.meeting.ship.resources.type.printing'),
  }
  MEETING_SHIP_RESOURCES_TYPE = [
    {
      id: 'printing',
      name: intl.get('const.meeting.ship.resources.type.printing'),
    },
  ]
  MEETING_SHIP_AREA_TYPE_OBJ = {
    public_office: intl.get('const.office.area.state.public_office'),
    private_office: intl.get('const.office.area.state.private_office'),
    'public_office,private_office': intl.get(
      'const.office.area.state.public_office,private_office',
    ),
    floor: intl.get('const.office.area.state.floor'),
  }
  MEETING_TYPE = [
    {
      id: 'organization',
      color: STATE_COLOR.warning,
      status: 'error',
    },
    {
      id: 'location',
      color: STATE_COLOR.success,
      status: 'success',
    },
  ]

  MEETING_SHIP_AREA_TYPE = [
    {
      id: 'public_office',
      name: intl.get('const.lock.manage.tipsy.public_office'),
    },
    {
      id: 'private_office',
      name: intl.get('const.lock.manage.tipsy.private_office'),
    },
  ]
  OPPORTUNITY_DESIRES = {
    very_strong: intl.get('const.opportunity.desires.very_strong'),
    ordinary: intl.get('const.opportunity.desires.ordinary'),
    not_strong: intl.get('const.opportunity.desires.not_strong'),
    not_clear: intl.get('const.opportunity.desires.not_clear'),
  }

  OPPORTUNITY_STATES = {
    preliminary: intl.get('const.opportunity.state.preliminary'),
    determined: intl.get('const.opportunity.state.determined'),
    plan: intl.get('const.opportunity.state.plan'),
    audit: intl.get('const.opportunity.state.audit'),
    success: intl.get('const.opportunity.state.success'),
    shelved: intl.get('const.opportunity.state.shelved'),
    failed: intl.get('const.opportunity.state.failed'),
    voided: intl.get('const.opportunity.state.voided'),
    entered: intl.get('const.opportunity.state.entered'),
    archived: intl.get('const.opportunity.state.archived'),
  }

  NETWORK_TYPE = {
    two_g: intl.get('const.network.type.two_g'),
    ethernet_cable: intl.get('const.network.type.ethernet_cable'),
    wifi: intl.get('const.network.type.wifi'),
  }
  LOCK_TYPE = {
    bluetooth: '蓝牙门禁',
    networking: '联网门禁',
    faceplusplus: 'Face++',
    xlink: '不用',
    lifang: '立方门禁',
    lingling: '令令门禁',
    kuban_bolt: 'bolt门禁',
    kuban_drawin: 'drawin人脸门禁',
    kuban_darwin: 'drawin人脸门禁',
  }
  DESK_STATES = {
    all: intl.get('const.area.state.all'),
    free: intl.get('const.area.state.obj.free'),
    assigned: intl.get('const.area.state.obj.assigned'),
    expiring: intl.get('const.area.state.expiring'),
    reserved: intl.get('const.desk.state.reserved'),
    partial_free: intl.get('const.desk.state.partial_free'),
    approving: intl.get('const.area.state.obj.approving'),
  }

  DESK_LIST_STATES = [
    {
      id: 'all',
      name: intl.get('const.area.state.all'),
    },
    {
      id: 'free',
      name: intl.get('const.area.state.free'),
    },
    {
      id: 'approving',
      name: intl.get('const.area.state.approving'),
    },
    {
      id: 'assigned',
      name: intl.get('const.area.state.assigned'),
    },
    {
      id: 'expiring',
      name: intl.get('const.area.state.expiring'),
    },
    {
      id: 'reserved',
      name: intl.get('const.area.state.reserved'),
    },
  ]

  AREAS_TYPE = [
    {
      id: 'all',
      name: intl.get('const.area.type.all'),
    },
    {
      id: 'public_office',
      name: intl.get('const.areas.type.public_office'),
    },
    {
      id: 'private_office',
      name: intl.get('const.areas.type.private_office'),
    },
  ]
  OFFICE_AREA_STATE = {
    public_office: intl.get('const.office.area.state.public_office'),
    private_office: intl.get('const.office.area.state.private_office'),
    flexible_office: intl.get('const.office.area.state.flexible_office'),
    floor: intl.get('const.office.area.state.floor'),
  }
  ORIENTATION_STATES_OBJ = {
    east: intl.get('const.east'),
    west: intl.get('const.west'),
    south: intl.get('const.south'),
    north: intl.get('const.north'),
  }

  RESERVATION_PROGRESS_STATUS = [
    { id: 'upcoming', color: STATE_COLOR.success, status: 'success' },
    { id: 'proceeding', color: STATE_COLOR.processing, status: 'warning' },
    { id: 'ended', color: STATE_COLOR.warning, status: 'error' },
  ]

  MARKETING_ACTIVITIES_PROGRESS_STATUS = [
    { id: 'waited', color: STATE_COLOR.success, status: 'success' },
    { id: 'processing', color: STATE_COLOR.processing, status: 'warning' },
    { id: 'over', color: STATE_COLOR.warning, status: 'error' },
    { id: 'stop', color: STATE_COLOR.default, status: 'default' },
  ]

  COUPONS_MARKETING_TYPE = [
    { id: 'common', color: STATE_COLOR.success, status: 'success' },
    { id: 'shop', color: STATE_COLOR.processing, status: 'warning' },
    { id: 'meeting_room', color: STATE_COLOR.processing, status: 'warning' },
  ]

  ACTIVITY_TASK_TYPE = [
    { id: 'signin', color: STATE_COLOR.success, status: 'success' },
    {
      id: 'wechat_motion_data',
      color: STATE_COLOR.processing,
      status: 'warning',
    },
    { id: 'lock_event', color: STATE_COLOR.processing, status: 'warning' },
  ]

  ACTIVITY_TASK_CYCLE = [
    { id: 'daily', color: STATE_COLOR.success, status: 'success' },
    {
      id: 'monthly',
      color: STATE_COLOR.processing,
      status: 'warning',
    },
  ]
  ACTIVITY_TASK_STATE = [
    { id: 'init', color: STATE_COLOR.processing, status: 'success' },
    {
      id: 'active',
      color: STATE_COLOR.success,
      status: 'warning',
    },
    { id: 'end', color: STATE_COLOR.warning, status: 'warning' },
    { id: 'cancel', color: STATE_COLOR.default, status: 'warning' },
  ]

  RESERVATION_PRIVILEGES = [
    {
      title: intl.get('const.reservation.privileges.manage'),
      id: true,
      name: intl.get('const.reservation.privileges.manage'),
      value: true,
    },
    {
      title: intl.get('const.reservation.privileges.member'),
      value: false,
      id: false,
      name: intl.get('const.reservation.privileges.member'),
    },
  ]

  RESERVATION_PRIVILEGE_ORG = [
    {
      title: intl.get('const.reservation.privileges.manage'),
      value: 'location_tenant',
    },
    {
      title: intl.get('const.reservation.privileges.member'),
      value: 'all_user',
    },
    {
      title: intl.get('const.reservation.privileges.company'),
      value: 'only_organization',
    },
  ]

  LOCK_MANAGE_TIPSY = {
    gate: intl.get('const.lock.manage.tipsy.gate'),
    private_office: intl.get('const.lock.manage.tipsy.private_office'),
    public_office: intl.get('const.lock.manage.tipsy.public_office'),
    flexible_office: intl.get('const.lock.manage.tipsy.flexible_office'),
    activity_room: intl.get('const.lock.manage.tipsy.activity_room'),
    meeting_room: intl.get('const.lock.manage.tipsy.meeting_room'),
    break_room: intl.get('const.lock.manage.tipsy.break_room'),
    cafe: intl.get('const.lock.manage.tipsy.cafe'),
    gym: intl.get('const.lock.manage.tipsy.gym'),
    broadcasting_studio: intl.get(
      'const.lock.manage.tipsy.broadcasting_studio',
    ),
    photography_studio: intl.get('const.lock.manage.tipsy.photography_studio'),
    gaming_room: intl.get('const.lock.manage.tipsy.gaming_room'),
    sleeping_room: intl.get('const.lock.manage.tipsy.sleeping_room'),
    floor: intl.get('const.lock.manage.tipsy.floor'),
    other: intl.get('const.lock.manage.tipsy.other'),
  }
  AREATYPES_TIPSY = {
    meeting_room: intl.get('const.area.type.meeting_room'),
    break_room: intl.get('const.area.type.break_room'),
    cafe: intl.get('const.area.type.cafe'),
    gym: intl.get('const.area.type.gym'),
    activity_room: intl.get('const.area.type.activity_room'),
    broadcasting_studio: intl.get('const.area.type.broadcasting_studio'),
    photography_studio: intl.get('const.area.type.photography_studio'),
    gaming_room: intl.get('const.area.type.gaming_room'),
    sleeping_room: intl.get('const.area.type.sleeping_room'),
    other: intl.get('const.area.type.other'),
  }

  ENTRY_LEVEL = [
    {
      name: intl.get('const.entry_level.one'),
      id: 'one',
    },
    {
      name: intl.get('const.entry_level.two'),
      id: 'two',
    },
    {
      name: intl.get('const.entry_level.three'),
      id: 'three',
    },
  ]

  TENANT_DEVICE = [
    { id: 3, name: '幻灯', device_type: 'device' },
    { id: 1, name: '白板', device_type: 'device' },
    { id: 2, name: '电视', device_type: 'device' },
    { id: 5, name: '投影', device_type: 'device' },
    { id: 6, name: '视频会议', device_type: 'device' },
    { id: 7, name: '电话会议', device_type: 'device' },
  ]

  AREATYPES_STATES = [
    {
      name: intl.get('const.area.type.meeting_room'),
      id: 'meeting_room',
    },
    {
      name: intl.get('const.area.type.break_room'),
      id: 'break_room',
    },
    {
      name: intl.get('const.area.type.cafe'),
      id: 'cafe',
    },
    {
      name: intl.get('const.area.type.gym'),
      id: 'gym',
    },
    {
      name: intl.get('const.area.type.activity_room'),
      id: 'activity_room',
    },
    {
      name: intl.get('const.area.type.broadcasting_studio'),
      id: 'broadcasting_studio',
    },
    {
      name: intl.get('const.area.type.photography_studio'),
      id: 'photography_studio',
    },
    {
      name: intl.get('const.area.type.gaming_room'),
      id: 'gaming_room',
    },
    {
      name: intl.get('const.area.type.sleeping_room'),
      id: 'sleeping_room',
    },
    {
      name: intl.get('const.area.type.other'),
      id: 'other',
    },
  ]
  MEETING_THEME_UPLOAD_ARRAY = [
    {
      name: intl.get('const.meeting.theme.upload.image'),
      id: 'image',
    },
    {
      name: intl.get('const.meeting.theme.upload.video'),
      id: 'video',
    },
  ]
  MEETING_THEME_CATEGORY_ARRAY = [
    {
      name: intl.get('const.meeting.theme.category.one'),
      id: 'one',
    },
    {
      name: intl.get('const.meeting.theme.category.two'),
      id: 'two',
    },
    {
      name: intl.get('const.meeting.theme.category.three'),
      id: 'three',
    },
    {
      name: intl.get('const.meeting.theme.category.four'),
      id: 'four',
    },
    {
      name: intl.get('const.meeting.theme.category.five'),
      id: 'five',
    },
  ]
  MEETING_THEME_CATEGORY = {
    one: intl.get('const.meeting.theme.category.one'),
    two: intl.get('const.meeting.theme.category.two'),
    three: intl.get('const.meeting.theme.category.three'),
    four: intl.get('const.meeting.theme.category.four'),
    five: intl.get('const.meeting.theme.category.five'),
  }
  ORG_ROLES_OBJ = {
    employer: intl.get('const.org.roles.form.employer'),
    finance: intl.get('const.org.roles.form.finance'),
    employee: intl.get('const.org.roles.form.employee'),
  }
  ORG_ROLES_FORM = [
    {
      title: intl.get('const.org.roles.form.employer'),
      value: 'employer',
    },
    {
      title: intl.get('const.org.roles.form.finance'),
      value: 'finance',
    },
    {
      title: intl.get('const.org.roles.form.employee'),
      value: 'employee',
    },
  ]

  ORG_ROLES_FORM_VALUE = [
    {
      id: 'employer',
      color: STATE_COLOR.warning,
      status: 'error',
    },
    {
      id: 'employee',
      color: STATE_COLOR.success,
      status: 'success',
    },
    {
      id: 'finance',
      color: STATE_COLOR.processing,
      status: 'warning',
    },
  ]

  ORG_AREA_STATE = [
    {
      id: 'free',
      color: STATE_COLOR.processing,
      status: 'warning',
    },
    {
      id: 'partial_free',
      color: STATE_COLOR.pink_purple,
      status: 'warning',
    },
    {
      id: 'reserved',
      color: STATE_COLOR.success,
      status: 'success',
    },
    {
      id: 'assigned',
      color: STATE_COLOR.success,
      status: 'success',
    },
    {
      id: 'expiring',
      color: STATE_COLOR.warning,
      status: 'error',
    },
    {
      id: 'disabled',
      color: STATE_COLOR.default,
      status: 'default',
    },
    {
      id: 'approving',
      color: STATE_COLOR.processing,
      status: 'warning',
    },
  ]

  TASKS = {
    repair: [
      { id: 'air', color: STATE_COLOR.success, status: 'success' },
      { id: 'it', color: STATE_COLOR.success, status: 'success' },
      { id: 'engineering', color: STATE_COLOR.success, status: 'success' },
      { id: 'office', color: STATE_COLOR.success, status: 'success' },
      { id: 'water', color: STATE_COLOR.success, status: 'success' },
      { id: 'wall', color: STATE_COLOR.success, status: 'success' },
      { id: 'install', color: STATE_COLOR.success, status: 'success' },
      { id: 'other', color: STATE_COLOR.default, status: 'default' },
    ],
    clean: [
      {
        id: 'property_cleaning',
        color: STATE_COLOR.success,
        status: 'success',
      },
      { id: 'glass_lamps', color: STATE_COLOR.success, status: 'success' },
      { id: 'sofa_carpet', color: STATE_COLOR.success, status: 'success' },
      { id: 'refurbishment', color: STATE_COLOR.success, status: 'success' },
      {
        id: 'wasteland_cleaning',
        color: STATE_COLOR.success,
        status: 'success',
      },
      { id: 'air_purification', color: STATE_COLOR.success, status: 'success' },
      { id: 'insect_killing', color: STATE_COLOR.success, status: 'success' },
    ],
    feedback: [
      { id: 'complaint', color: STATE_COLOR.warning, status: 'error' },
      { id: 'suggest', color: STATE_COLOR.processing, status: 'warning' },
    ],
    // custom_service: [
    //   { id: 'other', color: STATE_COLOR.default, status: 'default' }
    // ],
    complaint: [
      { id: 'property_service', color: STATE_COLOR.success, status: 'success' },
      { id: 'public_service', color: STATE_COLOR.success, status: 'success' },
      { id: 'cleaning_service', color: STATE_COLOR.success, status: 'success' },
      { id: 'other_service', color: STATE_COLOR.success, status: 'success' },
    ],
  }

  CONTRACT_END_DATE_BTN = [
    {
      name: intl.get('const.subscription.end.date.btn.1'),
      id: '1',
    },
    {
      name: intl.get('const.subscription.end.date.btn.3'),
      id: '3',
    },
    {
      name: intl.get('const.subscription.end.date.btn.6'),
      id: '6',
    },
    {
      name: intl.get('const.subscription.end.date.btn.12'),
      id: '12',
    },
    {
      name: intl.get('const.subscription.end.date.btn.24'),
      id: '24',
    },
    {
      name: intl.get('const.subscription.end.date.btn.36'),
      id: '36',
    },
    {
      name: intl.get('const.subscription.end.date.btn.60'),
      id: '60',
    },
  ]
  NEED_CLOSE_TO_LOCK = [
    {
      id: 'true',
      name: intl.get('const.yes'),
    },
    {
      id: 'false',
      name: intl.get('const.no'),
    },
  ]
  DISPLAY_BLUETOOTH_LOCK = [
    {
      id: 'true',
      name: intl.get('const.display.bluetooth.lock.true'),
    },
    {
      id: 'false',
      name: intl.get('const.display.bluetooth.lock.false'),
    },
  ]
  CAN_SET_TOUCH_PASSWORD = [
    {
      id: 'true',
      name: intl.get('const.can.set.touch.password.true'),
    },
    {
      id: 'false',
      name: intl.get('const.can.set.touch.password.false'),
    },
  ]
  CAN_BINDING_CARD = [
    {
      id: 'true',
      name: intl.get('const.can.binding.card.true'),
    },
    {
      id: 'false',
      name: intl.get('const.can.binding.card.false'),
    },
  ]
  LOCATION_PUBLIC = [
    {
      id: 'true',
      name: intl.get('const.location.public.true'),
    },
    {
      id: 'false',
      name: intl.get('const.location.public.false'),
    },
  ]

  LOCATION_SEND_MESSAGE_RANGE = [
    {
      name: intl.get('const.location.send.message.range.all_user'),
      id: 'all_user',
    },
    {
      name: intl.get('const.location.send.message.range.all_ceo'),
      id: 'all_ceo',
    },
    // , {
    //   name: intl.get("const.location.send.message.range.area"),
    //   id: 'area'
    // }
  ]

  // : 所有会员 1: 仅仅公司负责人 2: 仅仅当天注册成功的新用户
  ACTIVITY_SEND_MESSAGE_RANGE = [
    {
      name: '所有会员',
      id: 0,
    },
    {
      name: '仅租户负责人',
      id: 1,
    },
    // {
    //   name: '仅当天注册成功的新用户',
    //   id: 2
    // }
  ]

  TERMINATE_REASON = [
    {
      name: intl.get('const.terminate.reason.dismissed'),
      id: 'dismissed',
    },
    {
      name: intl.get('const.terminate.reason.transition'),
      id: 'transition',
    },
    {
      name: intl.get('const.terminate.reason.high_price'),
      id: 'high_price',
    },
    {
      name: intl.get('const.terminate.reason.discouraged'),
      id: 'discouraged',
    },
    {
      name: intl.get('const.terminate.reason.moved'),
      id: 'moved',
    },
    {
      name: intl.get('const.terminate.reason.move_area'),
      id: 'move_area',
    },
    {
      name: intl.get('const.terminate.reason.modify'),
      id: 'modify',
    },
    {
      name: intl.get('const.terminate.reason.input_mistake'),
      id: 'input_mistake',
    },
    {
      name: intl.get('const.terminate.reason.other_ended'),
      id: 'other_ended',
    },
  ]

  MEMBERSHIPS_CYCLE_TYPE = {
    one_time: intl.get('const.memberships.cycle.type.one_time'),
    recurring: intl.get('const.memberships.cycle.type.recurring'),
  }

  MEMBERSHIPS_CYCLE_TYPE_ARRAY = [
    {
      id: 'one_time',
      value: intl.get('const.memberships.cycle.type.one_time'),
      name: intl.get('const.memberships.cycle.type.one_time'),
    },
    // {
    //   id : 'recurring',
    //   name : '定期支付',
    //   value : '定期支付'
    // }
  ]

  MEMBERSHIP_RESOURCE_TYPE_OBJ = {
    booking: intl.get('const.meeting.ship.resource.type.booking'),
    desk: intl.get('const.meeting.ship.resource.type.desk'),
    printing: intl.get('const.meeting.ship.resource.type.printing'),
  }

  MEMBERSHIP_RESOURCE_UNIT = {
    booking: intl.get('const.duration.units.booking'),
    desk: intl.get('const.duration.units.desk'),
    printing: intl.get('const.duration.units.printing'),
  }

  MEMBERSHIPS_DURATION_UNITS_ARRAY = [
    {
      id: 'months',
      name: intl.get('const.memberships.duration.units.months'),
      value: intl.get('const.memberships.duration.units.months'),
    },
    {
      id: 'days',
      name: intl.get('const.memberships.duration.units.days'),
      value: intl.get('const.memberships.duration.units.days'),
    },
  ]

  // MEMBERSHIPS_DURATION_UNITS = {
  //   months: intl.get("const.duration.units.months"),
  //   days: intl.get("const.duration.units.days"),
  // }

  DEPARTMENT_SELECT_INPUT = {
    placeholder: intl.get('const.department.select.input.placeholder'),
    noResultsText: intl.get('const.select.input.noresultstext'),
  }

  USER_SELECT_INPUT = {
    placeholder: intl.get('const.user.select.input.placeholder'),
    noResultsText: intl.get('const.select.input.noresultstext'),
  }

  MEETING_SHIP_RESOURCE_TYPE_OBJ = {
    desk: intl.get('const.meeting.ship.resource.type.desk'),
    booking: intl.get('const.meeting.ship.resource.type.booking'),
    printing: intl.get('const.meeting.ship.resource.type.printing'),
  }

  MEETING_SHIP_RESOURCE_TYPE = [
    {
      id: 'desk',
      name: intl.get('const.meeting.ship.resource.type.desk'),
    },
    {
      id: 'booking',
      name: intl.get('const.meeting.ship.resource.type.booking'),
    },
    {
      id: 'printing',
      name: intl.get('const.meeting.ship.resource.type.printing'),
    },
  ]

  SEARCH_TYPE_CONTENT = {
    user: intl.get('const.search.type.user'),
    desk: intl.get('const.search.type.desk'),
    subscription: intl.get('const.search.type.subscription'),
    org: intl.get('const.search.type.org'),
    invoice: intl.get('const.search.type.invoice'),
  }

  SEARCH_TYPE = [
    {
      id: 'user',
      name: intl.get('const.search.type.user'),
    },
    {
      id: 'org',
      name: intl.get('const.search.type.org'),
    },
    {
      id: 'desk',
      name: intl.get('const.search.type.desk'),
    },
  ]

  LOCK_MANAGE_ADD_TYPE = [
    {
      value: 'org',
      title: intl.get('locks.lock_manage_form.hint.select_org'),
    },
    {
      value: 'user',
      title: intl.get('locks.lock_manage_form.hint.select_user'),
    },
  ]

  LOCK_TYPE_SELECT = [
    {
      value: 'lockGroupTarget',
      title: intl.get('locks.lock_manage_form.hint.select_lock_group'),
    },
    {
      value: 'lockTarget',
      title: intl.get('locks.lock_manage_form.hint.select_lock'),
    },
  ]

  MEMBER_AGE = [
    {
      name: intl.get('const.member.age.all'),
      value: {
        age_more_than: '',
        age_less_than: '',
      },
    },
    {
      name: '18-22',
      value: {
        age_more_than: 18,
        age_less_than: 22,
      },
    },
    {
      name: '23-26',
      value: {
        age_more_than: 23,
        age_less_than: 26,
      },
    },
    {
      name: '27-35',
      value: {
        age_more_than: 27,
        age_less_than: 35,
      },
    },
    {
      name: intl.get('const.member.age.35'),
      value: {
        age_more_than: 36,
        age_less_than: 100,
      },
    },
  ]

  DOCUMENT_TYPE = [
    {
      id: 'idcard',
      name: intl.get('const.document.type.idcard'),
    },
    {
      id: 'passport',
      name: intl.get('const.document.type.passport'),
    },
  ]

  DOCUMENT_TYPE_OBJ = {
    idcard: intl.get('const.document.type.idcard'),
    passport: intl.get('const.document.type.passport'),
  }

  MONTH_NUM = {
    1: intl.get('common.month_num.1'),
    2: intl.get('common.month_num.2'),
    3: intl.get('common.month_num.3'),
    4: intl.get('common.month_num.4'),
    5: intl.get('common.month_num.5'),
    6: intl.get('common.month_num.6'),
    7: intl.get('common.month_num.7'),
    8: intl.get('common.month_num.8'),
    9: intl.get('common.month_num.9'),
    10: intl.get('common.month_num.10'),
    11: intl.get('common.month_num.11'),
    12: intl.get('common.month_num.12'),
  }
  PAD_APPTYPE = [
    {
      id: 'visitor',
      name: intl.get('const.pad_apptype.visitor'),
    },
    {
      id: 'meetingroom',
      name: intl.get('const.pad_apptype.meetingroom'),
    },
    {
      id: 'office',
      name: intl.get('const.pad_apptype.office'),
    },
    {
      id: 'panel',
      name: intl.get('const.pad_apptype.panel'),
    },
    {
      id: 'signage',
      name: intl.get('const.pad_apptype.signage'),
    },
  ]

  COUPONS_FROM_RESOURCE = [
    {
      name: intl.get('const.coupons.type.Shop'),
      id: 'Shop',
    },
    // {
    //   name: intl.get('const.coupons.type.Desk'),
    //   id: 'Desk'
    // },
    {
      name: intl.get('const.coupons.type.area'),
      id: 'Area',
    },
    // {
    //   name: intl.get('const.coupons.type.Activity'),
    //   id: 'Activity'
    // }
  ]

  COUPONS_LIST_RESOURCE = {
    Desk: intl.get('const.coupons.type.desk'),
    Area: intl.get('const.coupons.type.area'),
    Activity: intl.get('const.coupons.type.activity'),
  }
  COUPONS_VALID_PERIOD = {
    years: '年',
    month: '个月',
    days: '天',
  }
  COUPONS_FROM_VALIDITY = [
    {
      id: '1.days',
      name: '1天',
    },
    {
      id: '7.days',
      name: '7天',
    },
    {
      id: '15.days',
      name: '15天',
    },
    {
      id: '1.month',
      name: '1个月',
    },
    {
      id: '3.month',
      name: '3个月',
    },
    {
      id: '6.month',
      name: '6个月',
    },
    {
      id: '1.years',
      name: '1年',
    },
    {
      id: '100.years',
      name: '不限',
    },
  ]

  COUPONS_VALIDITY_OBJ = {
    '1.month': '一个月',
  }

  // resource_type
  COUPONS_FROM_SCOPE = [
    {
      name: '全部租户',
      id: 'all',
    },
    {
      name: '部分租户',
      id: 'some',
    },
  ]

  COUPONS_FROM_RES_SCOPE = [
    {
      name: intl.get('coupons.coupons_form.choose_scope.true'),
      id: 'all',
    },
    {
      name: intl.get('coupons.coupons_form.choose_scope.false'),
      id: 'some',
    },
  ]

  COUPONS_FROM_TYPE = [
    {
      name: intl.get('const.coupons.type.deduction'),
      id: 'deduction',
    },
    {
      name: intl.get('const.coupons.type.discount'),
      id: 'discount',
    },
  ]
  COUPONS_SELECT_TYPE = [
    {
      name: intl.get('const.coupons.type.deduction'),
      id: 'deduction',
    },
    {
      name: intl.get('const.coupons.type.discount'),
      id: 'discount',
    },
  ]

  COUPONS_TYPE_OBJ = {
    deduction: intl.get('const.coupons.type.deduction'),
    discount: intl.get('const.coupons.type.discount'),
  }

  COUPONS_STATE_TYPE = [
    {
      name: '未开始',
      id: 'upcoming',
    },
    {
      name: '可使用',
      id: 'available',
    },
    {
      name: '已使用',
      id: 'used',
    },
    {
      name: '已过期',
      id: 'expired',
    },
    // {
    //   name: '已发放',
    //   id: 'granted'
    // }
  ]

  COUPONS_STATE_OBJ = {
    upcoming: '未开始',
    available: '可使用',
    used: '已使用',
    expired: '已过期',
    // granted: '已发放'
  }

  ORG_COUPON_GRANT_RECORD = [
    {
      name: '领取中',
      id: 'active',
    },
    {
      name: '已失效',
      id: 'disabled',
    },
    {
      name: '已结束',
      id: 'end',
    },
    {
      name: '已领完',
      id: 'no_balance',
    },
  ]
  ORG_COUPON_GRANT_RECORD_OBJ = {
    active: '领取中',
    disabled: '已失效',
    end: '已结束',
    no_balance: '已领完',
  }

  RESOURCE_SCOPE_OBJ = {
    All: '全场通用',
    Area: '会议室预定',
    Desk: '工位预定',
    Activity: '活动报名',
    SalesProduct: '商城商品',
  }

  RESOURCE_SCOPE_STATES = [
    {
      id: 'Area',
      name: '会议室预定',
    },
    {
      id: 'Desk',
      name: '工位预定',
    },
    {
      id: 'Activity',
      name: '活动报名',
    },
  ]

  GRANTED_REASON_OBJ = {
    all: '全部',
    gift: '赠送',
    promo: '推广',
    refund: '赔偿',
    activity: '活动',
    membership: '会员购买',
    subscription: '合同购买',
    org_bug: '租户购买',
  }

  GRANTED_REASON_STATES = [
    {
      id: 'gift',
      name: '赠送',
    },
    {
      id: 'promo',
      name: '推广',
    },
    {
      id: 'refund',
      name: '赔偿',
    },
    {
      id: 'activity',
      name: '活动',
    },
  ]

  COUPONS_TYPE_UNIT = {
    deduction: '元',
    discount: '折',
  }

  COUPONS_SELECT_SCOPE = [
    {
      title: intl.get('const.coupons.type.deduction'),
      id: 'scope_deductions',
    },
    {
      title: intl.get('const.coupons.type.discount'),
      id: 'scope_discount',
    },
  ]

  SELECT_APPROVAL_TYPE = [
    {
      title: intl.get('const.coupons.type.not_required'),
      value: 'not_required',
    },
    {
      title: intl.get('const.coupons.type.designee'),
      value: 'designee',
    },
    {
      title: intl.get('const.coupons.type.interviewees'),
      value: 'interviewees',
    },
  ]

  COUPONS_SCOPE_OBJ = {
    scope_deductions: intl.get('const.coupons.type.deduction'),
    scope_discount: intl.get('const.coupons.type.discount'),
  }

  PAD_APPTYPE = [
    {
      id: 'visitor',
      name: intl.get('const.pad_apptype.visitor'),
    },
    {
      id: 'meetingroom',
      name: intl.get('const.pad_apptype.meetingroom'),
    },
    {
      id: 'office',
      name: intl.get('const.pad_apptype.office'),
    },
    {
      id: 'panel',
      name: intl.get('const.pad_apptype.panel'),
    },
    {
      id: 'signage',
      name: intl.get('const.pad_apptype.signage'),
    },
  ]

  APPROVA_STATUS = [
    {
      id: 'unreviewed',
      name: '未审核',
    },
    {
      id: 'approving',
      name: '审批中',
    },
    {
      id: 'approved',
      name: '审批通过',
    },
    {
      id: 'rejected',
      name: '审批驳回',
    },
    {
      id: 'terminated',
      name: '审批终止',
    },
  ]

  MEETING_SERVICE_STATUS = [
    { id: 'pending', color: STATE_COLOR.processing, status: 'warning' },
    { id: 'complete', color: STATE_COLOR.success, status: 'success' },
    { id: 'reject', color: STATE_COLOR.warning, status: 'error' },
    { id: 'cancel', color: STATE_COLOR.default, status: 'default' },
  ]

  // 审批状态（unreviewed: 未审核, approving: 审批中, approved: 审批通过, rejected: 审批驳回, terminated: 审批终止 ）
  APPROVAL_RECORDS_STATUS = [
    {
      id: '',
      name: '全部',
    },
    {
      id: 'approving',
      name: '待审核',
    },
    {
      id: 'approved',
      name: '审批通过',
    },
    {
      id: 'rejected',
      name: '审批拒绝',
    },
  ]
  arrayData = [
    {
      id: 'all',
      name: intl.get('arrayData.all'),
    },
    {
      id: 'blacklist',
      name: intl.get('arrayData.blacklist'),
    },
    {
      id: 'none',
      name: intl.get('arrayData.none'),
    },
  ]

  visitAutoApproval = [
    {
      id: 'all',
      name: intl.get('arrayData.all'),
    },
    {
      id: 'invite',
      name: intl.get('arrayData.invite'),
    },
    {
      id: 'request',
      name: intl.get('arrayData.request'),
    },
    {
      id: 'blacklist',
      name: intl.get('arrayData.blacklist'),
    },
  ]

  autoApprovalData = [
    {
      id: 'approve',
      name: intl.get('autoApprovalData.approve'),
    },
    {
      id: 'reject',
      name: intl.get('autoApprovalData.reject'),
    },
  ]
}

COUPONS_GRANT_REASON = [
  {
    name: '赔偿',
    id: 'refund',
  },
  {
    name: '赠送',
    id: 'gift',
  },
  {
    name: '推广',
    id: 'promo',
  },
  {
    name: '活动',
    id: 'activity',
  },
]

COUPONS_GRANT = [
  {
    name: '会员',
    id: 'user',
  },
  {
    name: '租户',
    id: 'org',
  },
]

COUPONS_GRANT_OBJ = {
  user: '会员',
  org: '租户',
}

COUPONS_GRANT_RANGE = [
  {
    name: '全部会员',
    id: 'all',
  },
  {
    name: '指定会员',
    id: 'designated_members',
  },
]

export const NOTE_HANDLE = {
  join_SalesSubscription: {
    class: 'icon-Performance-subscription',
    color: '#ffcd7a',
  },
  join_OrganizationUser: {
    class: 'icon-entry',
    color: '#a7c2b1',
  },
  join_Organization: {
    class: 'icon-entry',
    color: '#a7c2b1',
  },
  left_SalesSubscription: {
    class: 'icon-leave',
    color: '#ef91a9',
  },
  left_OrganizationUser: {
    class: 'icon-quit',
    color: '#ec6b40',
  },
  left_Organization: {
    class: 'icon-quit',
    color: '#ec6b40',
  },
  remove_SalesSubscription: {
    class: 'icon-leave',
    color: '#ef91a9',
  },
  remove_OrganizationUser: {
    class: 'icon-quit',
    color: '#ec6b40',
  },
  remove_Organization: {
    class: 'icon-quit',
    color: '#ec6b40',
  },
  join_null: {
    class: 'icon-enter',
    color: '#ef4e4f',
  },
  left_null: {
    class: 'icon-leave',
    color: '#ef91a9',
  },
  remove_null: {
    class: 'icon-leave',
    color: '#ef91a9',
  },
  cancelled_null: {
    class: 'icon-audit-reject',
    color: '#9288e1',
  },
  cancelled_SalesSubscription: {
    class: 'icon-audit-reject',
    color: '#9288e1',
  },
  approve_SalesSubscription: {
    class: 'icon-audit-pass',
    color: '#73bb4b',
  },
  null_null: {
    class: 'icon-interview',
    color: '#d1658f',
  },
  rejected_SalesSubscription: {
    class: 'icon-quit',
    color: '#ec6b40',
  },
}

export const RECURRING_CYCLES = [
  { name: '0', id: 0 },
  { name: '1', id: 1 },
  { name: '2', id: 2 },
  { name: '3', id: 3 },
  { name: '4', id: 4 },
  { name: '5', id: 5 },
  { name: '6', id: 6 },
  { name: '7', id: 7 },
  { name: '8', id: 8 },
  { name: '9', id: 9 },
  { name: '10', id: 10 },
  { name: '11', id: 11 },
  { name: '12', id: 12 },
]

// export const RECURRING_CYCLES_WITH_HALF = [
//   { name: '0', id: 0 },
//   { name: '0.5', id: 0.5 },
//   { name: '1', id: 1 },
//   { name: '1.5', id: 1.5 },
//   { name: '2', id: 2 },
//   { name: '2.5', id: 2.5 },
//   { name: '3', id: 3 },
//   { name: '3.5', id: 3.5 },
//   { name: '4', id: 4 },
//   { name: '4.5', id: 4.5 },
//   { name: '5', id: 5 },
//   { name: '5.5', id: 5.5 },
//   { name: '6', id: 6 },
//   { name: '6.5', id: 6.5 },
//   { name: '7', id: 7 },
//   { name: '7.5', id: 7.5 },
//   { name: '8', id: 8 },
//   { name: '8.5', id: 8.5 },
//   { name: '9', id: 9 },
//   { name: '9.5', id: 9.5 },
//   { name: '10', id: 10 },
//   { name: '10.5', id: 10.5 },
//   { name: '11', id: 11 },
//   { name: '11.5', id: 11.5 },
//   { name: '12', id: 12 }
// ]

// export const MEETING_DEVICE = {
//   1: 'icon-Whiteboard',
//   2: 'icon-tv',
//   3: 'icon-slide',
//   4: 'icon-apple-tv',
//   5: 'icon-projection',
//   6: 'icon-tv',
//   7: 'icon-icon_phone',
//   8: 'icon-pc',
//   9: 'icon-mac',
//   10: 'icon-camera'
// }

// export const AREATYPES_CLASS = {
//   meeting_room: 'icon-meeting',
//   break_room: 'icon-break_room',
//   cafe: 'icon-cafe',
//   gym: 'icon-gym',
//   activity_room: 'icon-activity_room',
//   broadcasting_studio: 'icon-broadcasting_studio',
//   photography_studio: 'icon-photography_studio',
//   gaming_room: 'icon-gaming_room',
//   sleeping_room: 'icon-sleeping_room',
//   other: 'icon-other'
// }

// export const RESERVATION_PROGRESS_CLASS = {
//   upcoming: 'progress-upcoming',
//   proceeding: 'progress-proceeding',
//   ended: 'progress-ended'
// }

// export const NON_MEETING_ROOMS = ['public_office', 'private_office']
// export const OFFICE_AREA_TYPES = ['public_office', 'private_office']

// export const AREA_STATES_CLASS = {
//   free: 'free',
//   assigned: 'occupy',
//   expiring: 'expiring',
//   partial_free: 'partial_free',
//   reserved: 'reserved',
//   approving: 'approving'
// }

export const DESK_STATES_CLASS = {
  all: 'all-state',
  free: 'empty',
  assigned: 'occupy',
  expiring: 'expiring',
  partial_free: 'partial_free',
  reserved: 'reserved',
  approving: 'approving',
}

export const OPPORTUNITY_STATES_CLASS = {
  preliminary: 'status-initial',
  determined: 'status-certain',
  plan: 'status-scheme',
  audit: 'status-audit',
  success: 'status-win',
  failed: 'status-failed',
  shelved: 'status-shelved',
  voided: 'status-disable',
  entered: 'status-entered',
  archived: 'status-archived',
}

// export const OPPORTUNITY_DESIRES_CLASS = {
//   very_strong: 'desire-strong',
//   ordinary: 'desire-ordinary',
//   not_strong: 'desire-notstrong',
//   not_clear: 'desire-notclear'
// }

// export const CRM_EMPLOYEE_NUMBER = (start, end) => {
//   let people_num = []
//   for (let i = start; i <= end; i++) {
//     people_num.push({
//       id: i.toString(),
//       name: i.toString()
//     })
//   }
//   return people_num
// }

// export const CRM_TIME_NUMBER = (start, end, space = 1) => {
//   let people_num = []
//   for (let i = start; i <= end; i = i + space) {
//     let value = i < 10 ? '0' + i : i.toString()
//     people_num.push({
//       id: value,
//       name: value
//     })
//   }
//   return people_num
// }

// task_suggest: 96, #意见处理人
// task_complaint: 97, #投诉处理人
// clean: 38, #保洁处理人
// repair: 39, #保修处理人
// custom_service增值服务
// task_ac_overtime_service_manager 空调加时
export const TASK_SPACE_ROLES = {
  repair: 'repair',
  repair_manager: 'repair_manager',
  clean: 'clean',
  clean_manager: 'clean_manager',
  complaint: 'task_complaint', // #投诉处理人
  task_complaint: 'task_complaint', // #投诉处理人
  complaint_manager: 'task_complaint_manager',
  suggest: 'task_suggest', // #意见处理人
  suggest_manager: 'task_suggest_manager',
  // custom_service: 'location_manager,location_operator,admin,custom_service',
  // custom_service_manager:
  //   'location_manager,location_operator,admin,custom_service_manager',
  task_ac_overtime_service_manager:
    'location_manager,location_operator,admin,task_ac_overtime_service_manager,location_group_operator,location_group_manager',
}

// export const TASK_ASSIGNED_TO = ['me', 'all', 'following', 'descendents']

// export const TASK_UDPATE_ICONS = {
//   state: 'icon-edit',
//   priority: 'icon-edit',
//   subject: 'icon-edit',
//   due_date: 'icon-calendar',
//   reset_due_date: 'icon-calendar',
//   assigned_to: 'icon-people',
//   add_follower: 'icon-people',
//   remove_follower: 'icon-people',
//   upload_photo: 'icon-photo',
//   update: 'icon-pen'
// }

// export const THEME_COLORS = [
//   '#DA6068',
//   '#70CCB0',
//   '#FB6E52',
//   '#e47622',
//   '#F6D06A',
//   '#AC92ED',
//   '#A0D468',
//   '#4FC0E8',
//   '#EC88C0',
//   '#66C5D5'
// ]

// export const MEETING_COLORS = [
//   '#ffcb08',
//   '#80a4f1',
//   '#87d57b',
//   '#95cddb',
//   '#bc8781',
//   '#da503a',
//   '#734821',
//   '#03b6bd',
//   '#feb912',
//   '#e76054'
// ]

export const ENGLISH_ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

// export const SERVICE_REQUEST_STATE_CLASS = {
//   requested: 'service-requested',
//   request_accepted: 'service-request_accepted',
//   completed: 'service-completed',
//   canceled: 'service-canceled',
//   rejected: 'service-rejected'
// }

// 门禁权限状态class
// export const LOCKS_PERMISSIONS_PROGRESS_CLASS = {
//   current: 'receipt-new',
//   upcoming: 'receipt-expiring',
//   cancelled: 'receipt-completed',
//   ended: 'receipt-overdue'
// }

// 权限记录操作类型
export const LOCKS_PERMISSIONS_OPERATING_RECORD_CLASS = [
  {
    id: '',
    name: '全部',
  },
  {
    id: 'C',
    name: '增加',
  },
  {
    id: 'D',
    name: '删除',
  },
]

/**
 * 获取会议室预定时间段
 * 默认9点到晚上的22点
 * @method MEETING_HM
 * @param  {[array]} [返回时间数组 {id : string, name : string}]
 */
// export const MEETING_TIME_HM = ((start, end) => {
//   var time = []
//   var count = end - start
//   var currentStart = start
//   for (let i = 1; i <= count; i++) {
//     let st = fullZero(currentStart)
//     time.push({
//       id: currentStart + ',' + '1',
//       name: st + ':' + '00'
//     })

//     time.push({
//       id: currentStart + ',' + '2',
//       name: st + ':' + '30'
//     })

//     ++currentStart
//   }

//   time.push({
//     id: end + ',' + '1',
//     name: end + ':' + '00'
//   })

//   function fullZero(number) {
//     if (number <= 9) {
//       return '0' + number
//     }

//     return number
//   }

//   return time
// })(9, 19)

/**
 * 获取全天时间段
 * @method MEETING_HM
 * @param {start|Number}
 * @param {end|Number}
 * Tip: 参数小数位为5时代表半小时
 * @return  {[array]} [返回时间数组 {id : string, name : string}]
 */
// export function DAY_TIME_HM(start = 0, end = 24) {
//   let time = []
//   const startIsInteger = Number.isInteger(start)
//   const endIsInteger = Number.isInteger(end)
//   let currentStart = startIsInteger ? start : Math.ceil(start)
//   let currentEnd = endIsInteger ? end : Math.floor(end)
//   const count = currentEnd - currentStart

//   if (!startIsInteger) {
//     time.push({
//       id: currentStart - 1 + ',' + '2',
//       name: fullZero(currentStart - 1) + ':' + '30'
//     })
//   }

//   for (let i = 1; i <= count; i++) {
//     let st = fullZero(currentStart)
//     time.push({
//       id: currentStart + ',' + '1',
//       name: st + ':' + '00'
//     })

//     time.push({
//       id: currentStart + ',' + '2',
//       name: st + ':' + '30'
//     })

//     ++currentStart
//   }

//   time.push({
//     id: currentEnd + ',' + '1',
//     name: currentEnd === 24 ? 23 + ':' + '59' : currentEnd + ':' + '00'
//   })

//   if (!endIsInteger) {
//     time.push({
//       id: currentEnd + ',' + '2',
//       name: fullZero(currentEnd) + ':' + '30'
//     })
//   }

//   function fullZero(number) {
//     if (number <= 9) {
//       return '0' + number
//     }

//     return number
//   }

//   return time
// }

// 全天时段（00 - 24）
// export const DAY_TIME_HOUR = (count => {
//   var time = []
//   for (let i = 0; i <= count; i++) {
//     let st = fullZero(i)
//     time.push({
//       id: i,
//       name: st + ':' + '00'
//     })
//   }

//   function fullZero(number) {
//     if (number <= 9) {
//       return '0' + number
//     }

//     return number
//   }

//   return time
// })(24)

export const localeIndex = 1

// export const VISIT_STATUS_COLOR = {
//   accepted: '#0AC862',
//   rejected: '#FF0000',
//   ended: '#666'
// }
// export const ORG_ENTER_TYPES_OBJ = {
//   coworking: '联办租户',
//   longterm: '写字楼租户'
// }
MEETING_RECORDS_TYPE = [
  {
    id: 'inner',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'outside',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
]

MEETING_RECORDS_STATUS = [
  {
    id: 'all',
    name: '全部',
  },
  {
    id: 'reserved',
    name: '待付款',
  },
  {
    id: 'active',
    name: '未开始',
  },
  {
    id: 'proceeding',
    name: '进行中',
  },
  {
    id: 'ended',
    name: '已结束',
  },
  {
    id: 'cancelled',
    name: '已取消',
  },
  {
    id: 'pending_approved',
    name: '等待审批',
  },
  {
    id: 'approved',
    name: '审批通过',
  },
  {
    id: 'disapproved',
    name: '审批拒绝',
  },
  {
    id: 'cancel_approving',
    name: '取消审批中',
  },
  {
    id: 'cancel_disapproved',
    name: ' 取消审批拒绝',
  },
]
export const ALLOW_RESERVE = [
  {
    id: 'yes',
    title: 'yes',
    value: true,
  },
  {
    id: 'no',
    title: 'no',
    value: false,
  },
]

export const SCREEN_CONFIG_TYPE = [
  {
    id: 'text',
    value: 'text',
  },
  {
    id: 'char',
    value: 'char',
  },
]

export const RESERVE_TYPE = [
  {
    id: 'common',
    value: 'common',
  },
  {
    id: 'whole',
    value: 'whole',
  },
  {
    id: 'double',
    value: 'double',
  },
]

export const ORDER_MODE_TYPE = [
  {
    id: 'both',
    value: 'both',
  },
  {
    id: 'reserve',
    value: 'reserve',
  },
  {
    id: 'phone_contact',
    value: 'phone_contact',
  },
]

export const OFFLINE_ORDER_MODE_TYPE = [
  {
    id: 'phone_contact',
    value: 'phone_contact',
  },
  {
    id: 'offline_application',
    value: 'offline_application',
  },
]

// all: 所有
// reserved: 待付款
// active: 已预订
// proceeding: 进行中
// ended: 已结束
// cancelled: 已取消
// reserved_active
// ended_cancelled
// pending_approved: 等待审批
// approved: 审批通过
// 审批不通过
export const RESERVATION_STATE_OBJ = {
  all: '所有',
  end: '已结束',
  ended: '已结束',
  proceeding: '进行中',
  cancelled: '已取消',
  upcoming_active: '未开始',
  active: '未开始',
  reserved: '待付款',
  pending_approved: '等待审核',
  approved: '审核通过',
  disapproved: '审核拒绝',
  in_progress: '进行中',
  approving: '待审批',
  terminate: '已终止',
  cancel_approving: '取消审批中',
  cancel_disapproved: '取消审批拒绝',
}

export const RESERVATION_STATE = [
  { id: 'all', color: STATE_COLOR.default, status: 'default' },
  { id: 'end', color: STATE_COLOR.warning, status: 'error' },
  { id: 'cancelled', color: STATE_COLOR.default, status: 'default' },
  { id: 'proceeding', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'reserved', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'upcoming_active', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'pending_approved', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'approved', color: STATE_COLOR.success, status: 'success' },
  { id: 'disapproved', color: STATE_COLOR.warning, status: 'error' },
  { id: 'approving', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'terminate', color: STATE_COLOR.warning, status: 'error' },
  { id: 'cancel_approving', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'cancel_disapproved', color: STATE_COLOR.warning, status: 'error' },
  { id: 'in_progress', color: STATE_COLOR.processing, status: 'warning' },
]

export const AC_OVERTIME_RULES_TIME = [
  { id: 'no_imit', color: STATE_COLOR.default, status: 'default' },
  { id: 'less_than', color: STATE_COLOR.success, status: 'success' },
  { id: 'interval', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'greater_than', color: STATE_COLOR.warning, status: 'error' },
]

// export const MEET_DEL_STATE = {
//   cancelled: '会议已取消',
//   approved: '会议审批通过',
//   disapproved: '会议审批拒绝',
//   waiting_for_acceptance: '会议待审核',
//   ended: '已结束'
// }

export const LOC_SEND_MESSAGE = [
  {
    title: '会员APP',
    value: 'all_user',
  },
  {
    title: '办公室智能平板',
    value: 'area',
  },
]

export const RENTS_STATE = [
  {
    id: 'area',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'desk',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
]

// export let MENUACTIVEICON = {
//   dashboard: dashboards,
//   data_analysis: dashboards,
//   crm: crms,
//   members,
//   memberships: members,
//   marketing: marketings,
//   invoices,
//   tasks,
//   data: datas,
//   settings,
//   meeting: meetings,
//   lockers: lockers,
//   areas,
//   epidemic_report: epidemic_reports,
//   visit: visits,
//   locks: locks,
//   organizations,
//   home: homes
// }

export const AREA_TYPE = [
  { id: 'public_office', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'private_office', color: STATE_COLOR.warning, status: 'error' },
  { id: 'flexible_office', color: STATE_COLOR.success, status: 'success' },
]

export const TRUE_OT_FALSE = [
  { id: 'true', color: STATE_COLOR.success, status: 'success' },
  { id: 'false', color: STATE_COLOR.warning, status: 'error' },
]

export const QUESTION_TYPE = [
  // { id: 'text', color: STATE_COLOR.success, status: 'success' },
  // { id: 'boolean', color: STATE_COLOR.processing, status: 'success' },
  // { id: 'date', color: STATE_COLOR.warning, status: 'success' },
  // { id: 'select', color: STATE_COLOR.pink_purple, status: 'success' },
  // { id: 'textarea', color: STATE_COLOR.blue, status: 'success' },
  // { id: 'tel', color: STATE_COLOR.yellow, status: 'success' },
  // { id: 'number', color: STATE_COLOR.pink, status: 'error' }
  { id: 'radio', color: STATE_COLOR.pink, status: 'error' },
]
export const FALSE_OT_TRUE = [
  { id: 'false', color: STATE_COLOR.warning, status: 'error' },
  { id: 'true', color: STATE_COLOR.success, status: 'success' },
]

export const TRUE_OT_FALSE_COLOR = {
  true: '#87d068',
  false: '#CCC',
}

export const AREA_USAGE = [
  { id: 'office', color: STATE_COLOR.success, status: 'success' },
  { id: 'shared_area', color: STATE_COLOR.success, status: 'success' },
  { id: 'warehouse', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'business', color: STATE_COLOR.success, status: 'success' },
  { id: 'apartment', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'club', color: STATE_COLOR.success, status: 'success' },
]

export const AREA_PRICING_METHOD = [
  { id: 'by_area_daily', color: STATE_COLOR.success, status: 'success' },
  // { id: 'by_desk', color: STATE_COLOR.warning, status: 'error' },
  { id: 'total_amount', color: STATE_COLOR.processing, status: 'warning' },
]

export const ORIENTATION_STATES = [
  { id: 'east', color: STATE_COLOR.default, status: 'default' },
  { id: 'west', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'south', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'north', color: STATE_COLOR.success, status: 'success' },
  { id: 'southeast', color: STATE_COLOR.default, status: 'default' },
  { id: 'southwest', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'northeast', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'northwest', color: STATE_COLOR.success, status: 'success' },
]

export const AREA_HOUSE_FURNISHING = [
  { id: 'roughcas', color: STATE_COLOR.default, status: 'default' },
  { id: 'refined', color: STATE_COLOR.success, status: 'success' },
  { id: 'medium', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'simple', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'luxurious', color: STATE_COLOR.success, status: 'success' },
]

export const BATCH_UPDATE_AREAS_ATTR = [
  { id: 'floor_info_id', color: STATE_COLOR.processing, status: 'warning' },
  // { id: 'area_type', color: STATE_COLOR.success, status: 'success' },
  { id: 'is_commercial', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'area_usage', color: STATE_COLOR.warning, status: 'error' },
  { id: 'listing_price', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'lowest_price', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'house_fee', color: STATE_COLOR.warning, status: 'error' },
  // { id: 'is_external_visible', color: STATE_COLOR.warning, status: 'error' },
  // {
  //   id: 'crm_concessionary_price',
  //   color: STATE_COLOR.processing,
  //   status: 'warning'
  // },
  // { id: 'crm_price', color: STATE_COLOR.processing, status: 'warning' },
  // { id: 'floor_height', color: STATE_COLOR.success, status: 'success' },
  // { id: 'house_furnishing', color: STATE_COLOR.processing, status: 'warning' },
  // { id: 'orientation', color: STATE_COLOR.processing, status: 'warning' }
]

// export let AREA_SELL_STATES_COLOR = {
//   free_sell: '#87d068',
//   disabled_sell: '#CCC',
//   selling: '#FBC02D'
// }

export const AREA_SELL_STATES_ARR = [
  { id: 'free_sell', color: STATE_COLOR.success, status: 'success' },
  { id: 'disabled_sell', color: STATE_COLOR.warning, status: 'error' },
  { id: 'selling', color: STATE_COLOR.processing, status: 'warning' },
]

export const AREA_SELL_STATES_DATA = [
  { id: 'all', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'free_sell', color: STATE_COLOR.success, status: 'success' },
  { id: 'disabled_sell', color: STATE_COLOR.warning, status: 'error' },
]

export const COMMON_DATE = [
  { id: 'day', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'week', color: STATE_COLOR.success, status: 'success' },
  { id: 'month', color: STATE_COLOR.warning, status: 'error' },
]

export const INPUT_UNIT_MONEY = [
  { id: 'moneySqmDay', color: STATE_COLOR.success, status: 'success' },
  { id: 'moneySqmMonth', color: STATE_COLOR.warning, status: 'error' },
  { id: 'moneyMonth', color: STATE_COLOR.warning, status: 'error' },
  { id: 'moneyDay', color: STATE_COLOR.warning, status: 'error' },
  { id: 'moneyYear', color: STATE_COLOR.processing, status: 'warning' },
]

export const CLINCH_DEAL_MOVEMENTS_TYPE = [
  { id: 'contract_number', color: STATE_COLOR.success, status: 'success' },
  { id: 'size', color: STATE_COLOR.warning, status: 'error' },
]

export const CRM_PRICE_TYPE = [
  { id: 'day', color: STATE_COLOR.success, status: 'success' },
  { id: 'month', color: STATE_COLOR.warning, status: 'error' },
]

export const CRM_AREA_STATE = [
  { id: 'size', color: STATE_COLOR.success, status: 'success' },
  { id: 'price', color: STATE_COLOR.warning, status: 'error' },
]

export const CRM_ANALYSIS_TYPE = [
  { id: 'number', color: STATE_COLOR.success, status: 'success' },
  { id: 'amount', color: STATE_COLOR.warning, status: 'error' },
]

export const CRM_ANALYSIS_INDUSTRY_TYPE = [
  { id: 'level_1', color: STATE_COLOR.success, status: 'success' },
  { id: 'level_2', color: STATE_COLOR.warning, status: 'error' },
]
export const CRM_ANALYSIS_INDUSTRY_TIME_TYPE = [
  { id: 'all', color: STATE_COLOR.success, status: 'success' },
  { id: 'active', color: STATE_COLOR.warning, status: 'error' },
  { id: 'suspended', color: STATE_COLOR.warning, status: 'error' },
]

export const AREA_NUMBER_TYPE = [
  { id: 'area_number', color: STATE_COLOR.success, status: 'success' },
  { id: 'area_size', color: STATE_COLOR.warning, status: 'error' },
]
export const TENANTS_ANALYSIS_TYPE = [
  { id: 'entered', color: STATE_COLOR.success, status: 'success' },
  { id: 'all_entered', color: STATE_COLOR.warning, status: 'error' },
]

export const AREA_STATES = [
  {
    id: 'free',
    name: 'free',
    color: STATE_COLOR.default,
    status: 'default',
  },
  {
    id: 'assigned',
    name: 'assigned',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'expiring',
    name: 'expiring',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
]

export const GRADERS = [
  { id: 'male', color: STATE_COLOR.default, status: 'default' },
  { id: 'female', color: STATE_COLOR.warning, status: 'error' },
]

export const DOCUMENT_TYPE_ARR = [
  {
    id: 'idcard',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'passport',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
]

export const AREA_SIZE = [
  { id: '-20', name: '20以下' },
  { id: '20-50', name: '20-50' },
  { id: '50-100', name: '50-100' },
  { id: '100-150', name: '100-150' },
  { id: '150-200', name: '150-200' },
  { id: '200-300', name: '200-300' },
  { id: '300-500', name: '300-500' },
  { id: '500-1000', name: '500-1000' },
  { id: '1000-2000', name: '1000-2000' },
  { id: '2000- ', name: '2000以上' },
]

export const CRM_SIZE = [
  {
    id: 'size0',
    color: STATE_COLOR.default,
    status: 'default',
  },
  {
    id: 'size1',
    color: STATE_COLOR.default,
    status: 'default',
  },
  {
    id: 'size2',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'size3',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'size4',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'size5',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'size6',
    color: STATE_COLOR.warning,
    status: 'error',
  },
]

export const CAR_APPROVAL = [
  {
    id: 'pending',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'pass',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'reject',
    color: STATE_COLOR.default,
    status: 'default',
  },
]

export const CRM_SOURAE = [
  {
    id: 'advertisement',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'workshop',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'sem',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'recommend',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'website',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'wechat',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'visit',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'activity',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'robot',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'other',
    color: STATE_COLOR.default,
    status: 'default',
  },
]

export const CRM_OPP_DESIRE = [
  {
    id: 'very_strong',
    color: STATE_COLOR.warning,
    status: 'error',
  },
  {
    id: 'ordinary',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'not_strong',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'not_clear',
    color: STATE_COLOR.default,
    status: 'default',
  },
]

export const CRM_OPP_LAVEL = [
  {
    id: 'normal',
    color: STATE_COLOR.default,
    status: 'default',
  },
  {
    id: 'important',
    color: STATE_COLOR.warning,
    status: 'error',
  },
]

export const CRM_OPP_FAILED_REASON = [
  { id: 'expensive', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'no_need', color: STATE_COLOR.warning, status: 'error' },
  { id: 'lighting', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'layout', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'narrow', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'old_fashion', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'environment', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'story_height', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'smell', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'chose_others', color: STATE_COLOR.processing, status: 'warning' },
  {
    id: 'geographic_location',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  { id: 'too_far', color: STATE_COLOR.warning, status: 'error' },
  { id: 'traffic', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'price_factor', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'too_expensive', color: STATE_COLOR.warning, status: 'error' },
  { id: 'registration', color: STATE_COLOR.processing, status: 'warning' },
  {
    id: 'unsatisfied_demand',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  { id: 'rent_method', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'underaction', color: STATE_COLOR.warning, status: 'error' },
  { id: 'excessive_cycle', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'unmatched_space', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'awkward', color: STATE_COLOR.warning, status: 'error' },
  { id: 'stuffy_room', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'improper_desk', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'decorate', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'dislike_style', color: STATE_COLOR.warning, status: 'error' },
  { id: 'space_taint', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'dislike_tall', color: STATE_COLOR.warning, status: 'error' },
  { id: 'clock_other_space', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'only_adviser', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'need_hatch', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'no_answer', color: STATE_COLOR.warning, status: 'error' },
  { id: 'visit_or_study', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'other_factor', color: STATE_COLOR.default, status: 'default' },
]

export const CRM_CUSTOME_LEAVE = [
  { id: 'ordinary', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'important', color: STATE_COLOR.warning, status: 'error' },
  { id: 'low', color: STATE_COLOR.default, status: 'default' },
]

export const COUPONS_TYPE = [
  { id: 'deduction', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'discount', color: STATE_COLOR.success, status: 'success' },
]

export const DEVICE_APPTYPE = [
  { id: 'meetingroom', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'panel', color: STATE_COLOR.success, status: 'success' },
]
export const VISITOR_DEVICE_APPTYPE = [
  { id: 'visitor', color: STATE_COLOR.processing, status: 'warning' },
]
export const VISITOR_DEVICE_SUBTYPE = [
  { id: 'front_desk', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'visitor_pad', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'visitor_machine', color: STATE_COLOR.processing, status: 'warning' },
]
export const DEVICE_SUBTYPE = [
  { id: 'issac', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'pad_light', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'pad_light_all', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'office_pad', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'office_pad_noface', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'meeting_panel', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'office_panel', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'pad_nolight_noface', color: STATE_COLOR.processing, status: 'warning' },
]
export const DEVICE_LICK_TYPE = [
  { id: 'serial', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'usb', color: STATE_COLOR.success, status: 'success' },
  { id: 'remote', color: STATE_COLOR.success, status: 'success' },
  { id: 'io', color: STATE_COLOR.success, status: 'success' },
]

export const SELECT_TYPE = [
  { id: 'all', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'user', color: STATE_COLOR.success, status: 'success' },
  { id: 'org', color: STATE_COLOR.success, status: 'success' },
]

export const COUPONS_RESOURCE_TYPE_NEW = [
  { id: 'All', color: STATE_COLOR.success, status: 'success' },
  { id: 'Area', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'SalesProduct', color: STATE_COLOR.warning, status: 'error' },
  // { id: 'Desk', color: STATE_COLOR.orange, status: 'error' },
  // { id: 'Printer', color: STATE_COLOR.pink_purple, status: 'error' },
  { id: 'MeetingService', color: STATE_COLOR.yellow, status: 'error' },
]

export const CONSIFNMENT_STATE = [
  { id: 'no_send', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'send_out', color: STATE_COLOR.success, status: 'success' },
]

export const COUPONS_VALIDITY = [
  { id: '1.days', color: STATE_COLOR.success, status: 'success' },
  { id: '7.days', color: STATE_COLOR.success, status: 'success' },
  { id: '15.days', color: STATE_COLOR.success, status: 'success' },
  { id: '1.month', color: STATE_COLOR.processing, status: 'warning' },
  { id: '3.month', color: STATE_COLOR.processing, status: 'warning' },
  { id: '6.month', color: STATE_COLOR.processing, status: 'warning' },
  { id: '1.years', color: STATE_COLOR.warning, status: 'error' },
  { id: '100.years', color: STATE_COLOR.warning, status: 'error' },
]

export const COUPONS_GRANT_REASONS = [
  { id: 'gift', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'refund', color: STATE_COLOR.warning, status: 'error' },
]

export const COUPONS_DISTRIBUTING_TYPE = [
  { id: 'grant', color: STATE_COLOR.processing, status: 'warning' },
]

export const COUPON_GRANT_TYPE = ['user', 'org']

export const COUPONS_SCOPE = ['all', 'some']
export const COUPONS_SCOPE_ALL = ['all', 'org', 'role', 'some']

export const COUPONS_GRANTED_REASON = [
  { id: 'gift', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'promo', color: STATE_COLOR.default, status: 'default' },
  { id: 'refund', color: STATE_COLOR.warning, status: 'error' },
  { id: 'activity', color: STATE_COLOR.success, status: 'success' },
]

export const DISTRIBUTION_ISSUE_NUM = [
  { id: 'promote', color: STATE_COLOR.default, status: 'default' },
  { id: 'activity', color: STATE_COLOR.success, status: 'success' },
]

export const COUPONS_DISTRIBUTING_GRANT_RECORDS_TYPE = [
  { id: 'grant', color: STATE_COLOR.processing, status: 'warning' },
  // { id: 'receive', color: STATE_COLOR.success, status: 'success' }
]

export const DISTRIBUTION_ISSUE_FORM = [
  { id: 'auto', color: STATE_COLOR.default, status: 'default' },
  // { id: 'manual', color: STATE_COLOR.success, status: 'success' }
]

export const DISTRIBUTION_ISSUE_DISBURSEMENT = [
  { id: 'auto', color: STATE_COLOR.default, status: 'default' },
]

export const DISTRIBUTION_PERIOD_VALIDITY = [
  { id: 'fixed_date', color: STATE_COLOR.default, status: 'default' },
  { id: 'custom', color: STATE_COLOR.success, status: 'success' },
]

export const DISTRIBUTION_TRIGGER_CONDITION = [
  { id: 'never', color: STATE_COLOR.default, status: 'default' },
  { id: 'recent', color: STATE_COLOR.success, status: 'success' },
  { id: 'one_time', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'paid', color: STATE_COLOR.warning, status: 'error' },
]

export const DISTRIBUTION_REASON = [
  { id: 'unlimited', color: STATE_COLOR.default, status: 'default' },
  { id: 'custom', color: STATE_COLOR.success, status: 'success' },
]

export const COUPONS_STATE = [
  { id: 'upcoming', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'available', color: STATE_COLOR.success, status: 'success' },
  { id: 'used', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'expired', color: STATE_COLOR.warning, status: 'error' },
  // { id: 'granted', color: STATE_COLOR.success, status: 'success' }
]

export const COUPONS_USED_STATE = [
  { id: 'enabled', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'disabled', color: STATE_COLOR.success, status: 'success' },
  { id: 'used', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'recycled', color: STATE_COLOR.warning, status: 'error' },
  { id: 'expired', color: STATE_COLOR.success, status: 'success' },
  // { id: 'stop_use', color: STATE_COLOR.warning, status: 'error' }
]

export const LOCATION_AGREEMENT_TYPE = [
  { id: 'terms', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'privacy', color: STATE_COLOR.success, status: 'success' },
  { id: 'membership', color: STATE_COLOR.success, status: 'success' },
]

export const LOCK_STATE = [
  { id: 'available', color: STATE_COLOR.success, status: 'success' },
  { id: 'not_start', color: STATE_COLOR.processing, status: 'warning' },
  { id: 'invalid', color: STATE_COLOR.default, status: 'default' },
]

export const LOCK_STATE_ALL = [
  {
    value: '',
    label: '全部',
  },
  {
    value: 'available',
    label: '生效中',
  },
  {
    value: 'not_start',
    label: '未开始',
  },
  {
    value: 'invalid',
    label: '已失效',
  },
]

export const REFUND_STATE = [
  { id: 'S', color: STATE_COLOR.success, status: 'success' },
  { id: 'F', color: STATE_COLOR.warning, status: 'error' },
  { id: 'P', color: STATE_COLOR.processing, status: 'warning' },
]

export const POINT_LEDGER_TYPE = ['org', 'user']

export const RESERVATION_STATES_COLOR = {
  end: '#dd5a55',
  in_progress: '#73bb4b',
  cancelled: '#2ea1f8',
  upcoming_active: '#2ea1f8',
  pending_approved: '#2ea1f8',
  approved: '#2ea1f8',
  disapproved: '#dd5a55',
}

export const SALES_RESOURCES_TYPES = {
  point: '虚拟货币',
  coupon_template: '优惠券',
}

export const FREE_DATE_TYPE = {
  renting_fee_free: '租金减免',
  house_fee_free: '物业费减免',
  free: '全部减免',
}
export const FREE_TYPE = {
  discount: '折扣',
  money: '金额减免',
}
export const TASK_TYPE = [
  {
    id: 'repair',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'clean',
    color: STATE_COLOR.warning,
    status: 'error',
  },
  {
    id: 'complaint',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'suggest',
    color: STATE_COLOR.default,
    status: 'default',
  },
]
export const SHOP_TYPES = [
  {
    id: 'online',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'lips_coffee_machine',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'rknew',
    color: STATE_COLOR.default,
    status: 'default',
  },
  {
    id: 'group_buy',
    color: STATE_COLOR.warning,
    status: 'error',
  },
]
export const INVOICE_MANAGE_STATE = [
  {
    id: 'pending',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'process',
    color: STATE_COLOR.processing,
    status: 'warning',
  },

  {
    id: 'issued',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'rejected',
    color: STATE_COLOR.warning,
    status: 'error',
  },
  {
    id: 'aborted',
    color: STATE_COLOR.default,
    status: 'default',
  },
]
export const INVOICE_RECEIPTS_STATE = [
  {
    id: 'not_allowed',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'allowed',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'aborted',
    color: STATE_COLOR.warning,
    status: 'error',
  },
  {
    id: 'to_void',
    color: STATE_COLOR.default,
    status: 'default',
  },
]
export const INVOICE_TAX_NUM_TYPE = [
  {
    id: 'tax',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'social',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
]
export const INVOICE_MANAGE_TYPE = [
  {
    id: 'common',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'special',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  // {
  //   id: 'personal',
  //   color: STATE_COLOR.default,
  //   status: 'default'
  // }
  // {
  //   id: 'electronic_common',
  //   color: STATE_COLOR.success,
  //   status: 'success'
  // },
  // {
  //   id: 'special',
  //   color: STATE_COLOR.processing,
  //   status: 'warning'
  // },
  // {
  //   id: 'common',
  //   color: STATE_COLOR.default,
  //   status: 'default'
  // }
]

export const INVOICE_MANAGE_OFFLINE_TYPE = [
  {
    id: 'special',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'common',
    color: STATE_COLOR.default,
    status: 'default',
  },
]

export const INVOICE_MANAGE_INVOICE_TYPE = [
  {
    id: 'special',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'common',
    color: STATE_COLOR.default,
    status: 'default',
  },
  {
    id: 'electronic_common',
    color: STATE_COLOR.success,
    status: 'success',
  },
  // {
  //   id: 'personal',
  //   color: STATE_COLOR.success,
  //   status: 'success'
  // }
]

export const INVOICE_MANAGE_ONLINE_TYPE = [
  {
    id: 'electronic_common',
    color: STATE_COLOR.success,
    status: 'success',
  },
]

export const INVOICE_MANAGE_SEND_TYPE = [
  {
    id: 'email',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'phone',
    color: STATE_COLOR.success,
    status: 'success',
  },
]

export const STOPCAR_STATE = [
  {
    id: 'approving',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'rejected',
    color: STATE_COLOR.warning,
    status: 'error',
  },
  {
    id: 'unpaid',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'signined',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'active',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'expiring',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'ended',
    color: STATE_COLOR.default,
    status: 'default',
  },
  {
    id: 'terminated',
    color: STATE_COLOR.default,
    status: 'default',
  },
  {
    id: 'cancelled',
    color: STATE_COLOR.default,
    status: 'default',
  },
]

export const SURVEY_TYPE = {
  reservation: {
    type: '场地',
    color: 'orange',
  },
  activity: {
    type: '活动',
    color: 'blue',
  },
  visitor: {
    type: '访客',
    color: 'green',
  },
  low_carbon: {
    type: '低碳',
    color: 'pink',
  },
}

export const CAR_PAYMENT_TYPE = [
  {
    id: 'card',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'temporary_parking',
    color: STATE_COLOR.default,
    status: 'default',
  },
]

export const CAR_PAYMENT_METHOD = [
  {
    id: 'app',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'offline',
    color: STATE_COLOR.default,
    status: 'default',
  },
]
export const CAR_PAYMENT_STATUS = [
  {
    id: 'unpaid',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'paid',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'cancelled',
    color: STATE_COLOR.default,
    status: 'default',
  },
  {
    id: 'expired',
    color: STATE_COLOR.warning,
    status: 'error',
  },
]

export const CAR_PAY_COST = [
  {
    id: 'months',
    color: STATE_COLOR.warning,
    status: 'error',
  },
]

export const CAR_CARD_STATE = [
  {
    id: 'draft',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'active',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'ended',
    color: STATE_COLOR.default,
    status: 'default',
  },
]

export const CAR_CUSTOMER_TYPE = [
  {
    id: 'all',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'common_user',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'entered_user',
    color: STATE_COLOR.default,
    status: 'default',
  },
  {
    id: 'appoint_org',
    color: STATE_COLOR.warning,
    status: 'error',
  },
]

export const CAR_SALES_STATUS = [
  {
    id: 'draft',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'active',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'cancelled',
    color: STATE_COLOR.default,
    status: 'default',
  },
  {
    id: 'ended',
    color: STATE_COLOR.warning,
    status: 'error',
  },
]

export const CAR_TYPE = [
  {
    id: 'User',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'Organization',
    color: STATE_COLOR.success,
    status: 'success',
  },
]

export const CAR_CARD_TYPE = [
  {
    id: 'idcard',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  // {
  //   id: 'passport',
  //   color: STATE_COLOR.success,
  //   status: 'success'
  // }
]

export const CAR_ROLE = [
  {
    id: 'none',
    color: STATE_COLOR.warning,
    status: 'error',
  },
  {
    id: 'group',
    color: STATE_COLOR.success,
    status: 'success',
  },
]

// export const SUB_LINE_ITEM_TYPES =
// ['late_fee', 'house_fee', 'renting_fee',
// 'deposit_fee', 'water_fee', 'electric_fee', 'network_fee',
// 'express_fee', 'damaged_fee', 'other']

export const IS_USE_PREFERENTIAL = [
  {
    id: 'true',
    color: STATE_COLOR.warning,
    status: 'error',
  },
  {
    id: 'false',
    color: STATE_COLOR.success,
    status: 'success',
  },
]

export const FREE_TAX_MARK = [
  {
    id: 'out_free_tax',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'none_add_value_tax',
    color: STATE_COLOR.warning,
    status: 'error',
  },
  {
    id: 'common_tax',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
]

export const TASK_SUBMIT_TYPE = [
  {
    id: 'space_member',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'organization_user',
    color: STATE_COLOR.warning,
    status: 'error',
  },
]

export const FASHIONABLE_TYPE = [
  {
    id: 'real_time',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'delay_time',
    color: STATE_COLOR.warning,
    status: 'error',
  },
]

export const ORDER_MANAGEMENT_STATUS = [
  {
    id: 'reserved',
    color: STATE_COLOR.processing,
    status: 'warning',
  },
  {
    id: 'completed',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'refund',
    color: STATE_COLOR.warning,
    status: 'error',
  },
  {
    id: 'cancelled',
    color: STATE_COLOR.default,
    status: 'default',
  },
]
export const ORDER_PAY_STATUS = [
  {
    id: 'pay',
    color: STATE_COLOR.success,
    status: 'success',
  },
  {
    id: 'refund',
    color: STATE_COLOR.warning,
    status: 'error',
  },
]

// "2021": "2021",
//   "2022": "2022",
//   "2023": "2023",
//   "2024": "2024",
//   "2025+": "2025+",
//   "free": "空置中",
//   "selling": "签约中",
//   "free_sell": "可招商",
//   "disabled_sell": "不可招商",
//   "area_disabled": "已停用",
//   "shared_area": "共享分区",
//   "no_commercial": "非商用",
//   "assigned": "已入驻",
//   "expiring": "即将到期",

export const AREAS_COLOR_STATUS = [
  {
    name: '2021',
    value: '2021',
    color: STATE_COLOR.warning,
  },
  {
    name: '2022',
    value: '2022',
    color: STATE_COLOR.success,
  },
  {
    name: '2023',
    value: '2023',
    color: STATE_COLOR.default,
  },
  {
    name: '2024',
    value: '2024',
    color: STATE_COLOR.processing,
  },
  {
    name: '2025+',
    value: '2025',
    color: STATE_COLOR.warning,
  },
  {
    name: 'free',
    value: 'free',
    color: STATE_COLOR.warning,
  },
  {
    name: 'selling',
    value: 'selling',
    color: STATE_COLOR.warning,
  },
  {
    name: 'free_sell',
    value: 'free_sell',
    color: STATE_COLOR.warning,
  },
  {
    name: 'disabled_sell',
    className: 'area_disabled_sell',
    value: 'disabled_sell',
    color: STATE_COLOR.warning,
  },
  // {
  //   name: 'area_disabled',
  //   className: 'area_area_disabled',
  //   value: 'area_disabled',
  //   color: STATE_COLOR.warning
  // },
  // {
  //   name: 'shared_area',
  //   className: 'area_shared_area',
  //   value: 'shared_area',
  //   color: STATE_COLOR.warning
  // },
  // {
  //   name: 'no_commercial',
  //   className: 'area_no_commercial',
  //   value: 'no_commercial',
  //   color: STATE_COLOR.warning
  // },
  // {
  //   name: 'assigned',
  //   className: 'area_assigned',
  //   value: 'assigned',
  //   color: STATE_COLOR.warning
  // },
  // {
  //   name: 'expiring',
  //   className: 'area_expiring',
  //   value: 'expiring',
  //   color: STATE_COLOR.warning
  // }
]

// 招商状态
export const CRM_STATE_COLORS = [
  {
    id: 'free_sell',
    color: 'rgba(146, 146, 157, 1)',
  },
  {
    id: 'disabled_sell',
    color: 'rgba(105, 105, 116, 1)',
  },
  {
    id: 'selling',
    color: 'rgba(24, 144, 255, 1)',
  },
]

// 合同到期状态
export const LEASE_END_YEAR_COLORS = [
  'rgba(232, 234, 243, 1)', // 空置中
  'rgba(220, 123, 120, 1)', //
  'rgba(222, 123, 158, 1)',
  'rgba(254, 185, 122, 1)',
  'rgba(254, 224, 103, 1)',
  'rgba(191, 224, 108, 1)',
]
