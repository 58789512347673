// import { renderRaw } from 'redraft'
import accounting from 'accounting'
import { notification } from 'antd'
import axios from 'axios'
import * as _ from 'lodash'
import moment from 'moment'
import qs from 'qs'
import intl from 'react-intl-universal'
import jsonpAdapter from 'axios-jsonp'
import querystring from 'querystring'
import { SAAS_PLANS } from '../app/constants/saas'
import { getSaasSubscriptions } from '../app/selectors'
import { INVOICE_TYPES } from '../app/constants/invoices'
import { SUPPORTED_LOCALES } from 'app/constants'

const time_data = []

const storage = window.localStorage

export function getCurrentLocale() {
  const currentLocale = intl.getInitOptions()
  let currentLocaleData = SUPPORTED_LOCALES.find(x => x.value === currentLocale.currentLocale)
  if (!currentLocaleData) {
    currentLocaleData = SUPPORTED_LOCALES.find(x => x.value === import.meta.env.VITE_DEFAULT_LOCALE)
  }
  return currentLocaleData
}

export const getLocalStorage = key => {
  return storage && storage.getItem(key)
}

export const setLocalStorage = (key, value) => {
  return storage && storage.setItem(key, value)
}

// // TODO: this is called every request, move to redux state
// export const getCurrentLocale = () => {
//   let defaultLocale = import.meta.env.VITE_DEFAULT_LOCALE || 'zh-CN'
//   // if query string has locale, then use it
//   // const queryLocale = qs.parse(location.search)['locale']
//   // if local storage has locale, then use it
//   const storageLocale = getLocalStorage('locale')
//   // otherwise, use current browser
//   const browserLocale =
//     navigator.languages && navigator.languages.length
//       ? navigator.languages[0]
//       : navigator.userLanguage ||
//         navigator.language ||
//         navigator.browserLanguage

//   let locale = storageLocale || browserLocale
//   return locale
// }

// 根据URL返回section和subSection，即一级和二级菜单
// props: page props
export function getUrlSections(urlLocation, urlParams) {
  const locationId = urlParams?.id // location passed in from params
  const sections = urlLocation?.pathname?.split('/')
  let section; let subSection
  const isTenant = JSON.parse(localStorage.getItem('isTenant'))

  if (locationId) {
    if (isTenant) {
      section = _.get(sections, 6, '')
      subSection = _.get(sections, 7, '')
    } else {
      // location level
      // ['','admin','locations', #location_id, 'section', 'subSection' ...]
      section = _.get(sections, 4, '')
      subSection = _.get(sections, 5, '')
    }
  } else {
    // space level
    // ['','admin','section', 'subSection'...]
    section = _.get(sections, 2, '')
    subSection = _.get(sections, 3, '')
  }
  return { section, subSection }
}

// 根据URL返回seq对应的值， locations需要加2
// props: page props
// seq: 第几个元素
export function getUrlSection(props, seq) {
  const { location, params } = props
  const locationId = params && params.id // location passed in from params
  const sections = location.pathname.split('/')
  let section
  if (locationId) {
    // location level
    // ['','admin','locations', #location_id, 'section', 'subSection' ...]
    section = _.get(sections, seq + 2, '')
  } else {
    // space level
    // ['','admin','section', 'subSection'...]
    section = _.get(sections, seq, '')
  }
  return section
}

export function showError(error, defaultMessage, type = 'error') {
  notification[type]({
    message: getServiceErrorMessage(error) || error || defaultMessage,
    style: { zIndex: 9999 },
  })
}
export function showSuccess(message, description) {
  notification.success({ message, description: description || null })
}

// format 格式
export function formatPercent(rate, precision = 1) {
  if (isNaN(rate)) {
    return 0
  }
  return parseFloat(rate).toPrecision(precision)
}

// xx% 格式
export function fPercent(rate, showPercentSign = true, precision = 2) {
  return rate
    ? `${parseFloat(rate * 100).toFixed(precision)}${showPercentSign ? '％' : ''
    }`
    : `0${showPercentSign ? '％' : ''}`
}

// 有小数截取后两位 整数不处理
export function fNum(count = 0, precision = 2) {
  const fCount = Number((count * 1).toFixed(precision))
  if (isNaN(fCount)) {
    return count
  }
  return fCount

}

// x折 格式
export function formatDiscount(rate, unit = '折') {
  return `${parseFloat(rate * 10).toFixed(1)}${unit}`
}

// render text as Html
export function fHtml(content) {
  return content && content.replace(/[\\r\\n]/g, '<br/>')
}

export function fillDate(start_date, end_date, cycle, date_str = 'YYYY-MM-DD') {
  start_date = moment(start_date)
  end_date = moment(end_date)
  let date_range = []
  let diff = 0
  switch (cycle) {
    case 'monthly':
      diff =
        (end_date.year() - start_date.year()) * 12 +
        end_date.month() -
        start_date.month()
      date_range = Array.from({ length: diff + 1 }).map((e, n) => {
        return start_date
          .clone()
          .startOf('month')
          .add(n, 'months')
      })
      break
    case 'daily':
      diff = (end_date - start_date) / (24 * 60 * 60 * 1000)
      date_range = Array.from({ length: diff + 1 }).map((e, n) => {
        return start_date
          .clone()
          .startOf('day')
          .add(n, 'days')
      })
      break
    case 'weekly':
      diff = Math.ceil((end_date - start_date) / (24 * 60 * 60 * 1000 * 7.0))
      date_range = Array.from({ length: diff }).map((e, n) => {
        return start_date
          .clone()
          .startOf('week')
          .add(n, 'weeks')
      })
      break
  }
  return date_range.map(e => e.format(date_str))
}

export function fillNoDataDate(data, start_date, end_date, cycle, value = 0) {
  // get date format of data
  const date_format = 'YYYY-MM-DD'
  const result = {}
  const full_dates = fillDate(start_date, end_date, cycle, date_format)
  full_dates.forEach(k => (result[k] = value))
  Object.keys(data).map(e => {
    let date_str = e
    if (cycle == 'monthly' && e.toString().length == 6) date_str += '01'
    const key = moment(date_str).format(date_format)
    result[key] = data[e]
  })
  return result
}

export function objectIsNull(data = {}) {
  if (!data) {
    return true
  }

  const values = Object.values(data)
  if (!values.length) {
    return true
  }

  return false
}

// Group locations by city
export function groupLocationsByCity(locations) {
  const group = {}
  const cities = {}
  locations.forEach(location => {
    const city = _.get(location, 'city', {
      id: 0,
      name: intl.get('city.unknown'),
    })
    const cityId = city.id
    cities[cityId] = city
    group[cityId] = group[cityId] || []
    group[cityId] = [...group[cityId], location]
  })

  return [cities, group]
}

// Get object as associated array from Redux entities tree.
// json is the object usually returned from API and through nomralizr
export const getEntitiesJson = (json, key) =>
  _.get(json, ['json', 'entities', key])

export var util = {
  setUrlTime(url) {
    let newUrl = url
    if (url.indexOf('?') != -1) {
      newUrl += `&_=${new Date().getTime()}`
    } else {
      newUrl += `?_=${new Date().getTime()}`
    }
    return newUrl
  },
  setTimeout(name, callback, time) {
    clearTimeout(time_data[name])
    time_data[name] = setTimeout(callback, time)
  },
  setInterval(name, callback, time) {
    const setTimeOut = function () {
      callback()
      time_data[name] = setTimeout(setTimeOut, time)
    }
    setTimeOut()
  },
  clearTimeout(name) {
    clearTimeout(time_data[name])
  },
  bind(eventName, fn) {
    if (window.addEventListener) {
      window.addEventListener(eventName, fn, false)
    } else if (window.attachEvent) {
      window.attachEvent(`on${eventName}`, fn)
    }
  },
  // 清除空格
  clearSpacing(text) {
    if (!text) {
      return ''
    }
    return text.replace(/\s/gi, '')
  },
  addHandler(element, type, handler) {
    if (element.addEventListener) {
      element.addEventListener(type, handler)
    } else if (element.attachEvent) {
      element.attachEvent(`on${type}`, handler)
    } else {
      element[`on${type}`] = handler
    }
  },
  removeHandler(element, type, handler) {
    if (element.removeEventListener) {
      element.removeEventListener(type, handler, false)
    } else if (element.detachEvent) {
      element.detachEvent(`on${type}`, handler)
    } else {
      element[`on${type}`] = null
    }
  },
  appendScript(url, callback) {
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.onreadystatechange = function () {
      if (this.readyState == 'complete') callback()
    }
    script.onload = function () {
      callback()
    }
    script.src = url
    head.appendChild(script)
  },
}

/**
 * checkObject({a : { b : { c : 13123 } }},'a','b','c')
 *  检查元素
 */
export function checkObject(src) {
  const argumentArray = Array.prototype.slice.call(arguments)
  var src = { ...argumentArray.shift() }
  let result

  argumentArray.every(value => {
    const srcTemp = src[value]

    if (!srcTemp) {
      result = undefined
      return false
    }

    result = src = srcTemp

    return true
  })

  return result
}

export function encryptionPhone(phone) {
  return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3')
}

export const paginate = function (response) {
  if (response && response.headers && response.headers['x-pagination']) {
    const paginationText = response.headers['x-pagination']
    const pairs = paginationText.split(',')
    const result = {}

    pairs.forEach(pair => {
      let [key, val] = pair.split('=')
      key = key.trim()
      val = parseInt(val, 10)
      // convert pagination to antd format
      if (key === 'current_page') {
        result.current = val
      } else if (key === 'limit_value') {
        result.pageSize = val
      } else if (key === 'total_count') {
        result.total = val
      }

      // TODO: keep this for backward compatibility. Remove after changing all table
      result[key] = val
    })

    return result
  }
  return {}
}

export function isMatch(a, b) {
  return _.isEqual(a, b)
}

export function checkNull(text, def) {
  return text || def || ''
}

// return hashcode of a string
export function hashCode(s) {
  let hash = 0
  let i
  let chr
  const length = s.length
  for (i = 0; i < length; i++) {
    chr = s.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export function percent(a, b) {
  const denom = parseFloat(a)
  let nom = parseFloat(b)

  if (isNaN(denom) || denom === 0) {
    return 0
  }
  if (isNaN(nom)) {
    nom = 0
  }
  return ((nom / denom) * 100).toFixed(2)
}

export function momentFromNow(data, isTrue) {
  return moment(data).fromNow(isTrue)
}

export function randomNumber(number = 10) {
  return Math.floor(Math.random() * number + 1)
}

export function mergeArrayWithKey(oldArray, newArray, key) {
  if (!key || !newArray || !newArray.length) {
    return
  }

  if (!oldArray.length) {
    return newArray || []
  }

  const tempArray = Object.assign([], oldArray)

  for (
    let i = 0, length = tempArray.length, temp = tempArray[i];
    i < length, (temp = tempArray[i++]);

  ) {
    const oldKey = temp[key]
    const findObj = newArray.find(value => value[key] == oldKey)
    if (!findObj) {
      continue
    }

    _.merge(temp, findObj)
  }

  return tempArray
}

// replace an element in the array with the same key of given object
export function updateArrayWithKey(oldArray, newObject, key = 'id') {
  let arr = newObject
  if (!Array.isArray(newObject)) {
    arr = [newObject]
  }

  const s = oldArray.map(x => {
    const found = arr.findIndex(object => _.get(x, key) === _.get(object, key))
    if (found === -1) {
      return x
    }
    return arr[found]

  })
  return s
}
// 插入或替换数组
export function changeArrayWithKey(oldArray, newObject, key = 'id') {
  let arr = newObject
  if (!Array.isArray(newObject)) {
    arr = [newObject]
  }
  let isReplace = false
  oldArray.map(item => {
    if (item[key] == newObject[key]) {
      isReplace = true
    }
  })
  if (isReplace) {
    var s = oldArray.map(x => {
      const found = arr.findIndex(
        object => _.get(x, key) === _.get(object, key),
      )
      if (found === -1) {
        return x
      }
      return arr[found]

    })
  } else {
    var s = [newObject, ...oldArray]
  }

  return s
}

/**
 * 数组转换成Object     Object 必须 存在ID
 * @return {[object]} [object]
 */
export function arrayToObject(array) {
  const result = {}
    ; (array || []).forEach(obj => {
    if (obj && obj.id) {
      result[String(obj.id)] = obj
    }
  })

  return result
}

/**
 * 对象转换成Array
 */
export const objectToArray = object => {
  return (object && Object.values(object)) || []
}

/**
 * 对比两个时间日期
 * @param  {[date]} start [第一个日期]
 * @param  {[date]} end   [第二个日期]
 * @param  {[string]} type   [类型]
 * @return {[int]}       [时间差]
 */
export function contrastDate(start, end, type) {
  if (type === 'integer') {
    return moment(getIntegralDate(start)).diff(moment(getIntegralDate(end)))
  } if (type) {
    return moment(start).diff(moment(end), type)
  }

  return moment(start).diff(moment(end))
}

/**
 * 返回整点时间
 */
export function getIntegralDate(date) {
  return moment(date)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .toDate()
}

/**
 * 返回数据的类型
 */
export function getDateType(date) {
  return date && Object.prototype.toString(date)
}

export function updateDataToArrayOfIndex(list, data, index = 0) {
  const array = Object.assign([], list)

  if (!array.length || !data) {
    return []
  }

  array.splice(index, 1, data)
  return array
}

export function deleteDataToArrayOfIndex(list, index) {
  const array = Object.assign([], list)

  array.splice(index, 1)
  return array
}

export function addDataToArrayOfIndex(list, data) {
  const array = Object.assign([], list)

  array.unshift(data)

  return array
}

export const thumbUrl = (imageUrl, width, height) => {
  let url = `${imageUrl}?imageView2/1/w/${width}`
  if (height) {
    url = `${url}/h/${height}`
  }
  return url
}

export const meetingRoomImage = room => {
  return room.images && room.images.length > 0
    ? room.images[0]
    : 'https://media-dev-ssl.kuban.io/meeting-room-default.png'
}

export const locationImage = location => {
  return location && location.images && location.images.length > 0
    ? location.images[0]
    : `https://media-ssl.kuban.io/static/locations/subbranch${getRandom(
      location.id,
    )}.jpg`
}

export const getRandom = id => {
  let random = id % 10

  if (random < 10) {
    random = `0${random}`
  } else if (random > 12) {
    random = 11
  }

  return random
}
// 判断当前空间是不是 星库
export const isXingKuSpace = space_id => {
  if (space_id && space_id === '6197') {
    return true
  }
  return false

}

export function fillHttp(herf) {
  if (/^http/.test(herf)) {
    return herf
  }

  return `http://${herf}`
}

/**
 * 对比两个日期  默认年月日比较
 * @param  {[string || moment]} start   [第二个日期]
 * @param  {[string || moment]} end     [第二个日期]
 * @return {[Boolean]}         [是否相同  true 为 相同  false 不相同]
 */
export function contrastYMDDate(start, end) {
  return moment(start).isSame(moment(end), 'year', 'month', 'day')
}

/**
 * 判断 currentValue 是否为 value 中的一个
 * @param {[string || array]} value [value]
 * @param {[string]} currentValue [当前value]
 */
export function isListValue(value, currentValue) {
  if (Object.prototype.toString.call(value) === '[object Array]') {
    const t = value.find(json => json === currentValue)
    return !!(t || t === '')
  }

  return value === currentValue
}

/**
 * 如果为 array 返回数组第一个 [1,2] => 1
 * @param {[string || array]} value [value]
 */
export function getListFirstValue(value, index = 0) {
  let firstValue = value
  if (Object.prototype.toString.call(value) === '[object Array]') {
    firstValue = value[index]
  }
  return firstValue // .split('/').slice(-1)[0]
}

export const getUUID = () => {
  let d = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (
    c,
  ) => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

export function preventDefaultPropagation(e) {
  e.stopPropagation()
  e.preventDefault()
}

export function getYMD(t) {
  const year = moment(t).get('year')
  const month = moment(t).get('month')
  const day = moment(t).get('date')

  return {
    year,
    month,
    day,
  }
}

// 将筛选条件转为string
export function queryFilter(object) {
  if (Object.keys(object).length == 0) {
    return ''
  }

  const arrayKeys = Object.keys(object)
  const arrayValues = Object.values(object)
  let str = ''

  arrayKeys.map((data, index) => {
    str +=
      `${data
      }=${arrayValues[index]
      }${index + 1 == arrayKeys.length ? '' : '&'}`
  })
  return `?${str}`
}
// 比较原有query与filter
export function getFilterAndQuery(query, paramsArray) {
  const newQuery = { ...query }
  const filters = {}
  paramsArray.forEach(filter => {
    const value = newQuery[filter]
    if (value) {
      filters[filter] = value
      delete newQuery[filter]
    }
  })
  return {
    filters,
    queryAll: query,
    query: newQuery,
  }
}

export function parseStringFilesToArray(files) {
  if (!files) {
    return []
  }
  const fileList = files.split(',')
  return fileList.map(json => {
    const i = json
    return {
      name: i.substring(i.lastIndexOf('/') + 1, i.lastIndexOf('.')),
      file_url: i,
    }
  })
}

export function parseListFilesToString(files) {
  if (!files) {
    return ''
  }
  return files.map(json => json.file_url).join(',')
}

// 筛选数组最大值与最小值
export function getMaximin(arr, maximin) {
  if (maximin === 'max') {
    return Math.max.apply(Math, arr)
  } if (maximin === 'min') {
    return Math.min.apply(Math, arr)
  }
}

// 货币格式化
// precision - deprecated
export const fmoney = (amount, options) => {
  return accounting.formatMoney(parseFloat(amount) || 0, options)
}

// 数字格式化
export const fnumber = (amount, precision = 0) => {
  return `${accounting.formatNumber(parseFloat(amount), {
    precision,
  })}`
}

export const virtualCurrencyName = () => {
  const store = window.__store__
  return _.get(
    store,
    'spaceSetting.virtual_currency_settings.virtual_currency_name',
    intl.get('orders.orders_org.string.point'),
  )
}

/** 是否有订阅, 其中任何一个就可以 */
export const hasSaasFeature = (features = []) => {
  if (!Array.isArray(features)) {
    features = [features]
  }

  const store = window.__store__
  const planIds = features.map(feature => {
    const plan = SAAS_PLANS.find(x => x.feature === feature)
    return plan && plan.id
  })
  const subscribedSubs = getSaasSubscriptions(store.getState())
  const subscribedPlanIds = subscribedSubs.map(x => _.get(x, 'plan.id'))
  return _.intersection(planIds, subscribedPlanIds).length > 0
}

/** 返回订阅名称数组 */
export const saasFeatures = () => {
  const store = window.__store__
  const subscribedSubs = getSaasSubscriptions(store.getState())
  const subscribedPlanIds = subscribedSubs.map(x => _.get(x, 'plan.id'))
  return _.compact(
    subscribedPlanIds.map(planId =>
      _.get(
        SAAS_PLANS.find(x => x.id === planId),
        'feature',
      ),
    ),
  )
}
// saas可见的常量
export const saasConstants = constants => {
  const myFeatures = saasFeatures()
  let data
  if (myFeatures.includes('custom')) {
    data = constants
  } else {
    data = constants.filter(x => {
      return _.intersection(myFeatures, x.features).length > 0
    })
  }
  return data
}

// 积分格式化 - 自动获取积分名称
export const fPoint = amount => {
  return `${amount}${virtualCurrencyName()}`
}

// 小数转百分数
export const toPercent = (point, x) => {
  let str = Number(point * 100).toFixed(x)
  str += '%'
  return str
}

export const getFloatOfSetting = (num, type) => {
  switch (type) {
    case 'ceiling':
      return Math.ceil(num)
    case 'floor':
      return Math.floor(num)
    case 'round':
      return Math.round(num)
    default:
      return num
  }
}

export const formatYearDayCHS = date => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format(intl.get('util.year_day_chs'))
  }
  return moment(date).format(intl.get('util.year_day_chs'))
}

export const formatYearDayEN = date => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format('YYYY-MM-DD')
  }
  return moment(date).format('YYYY-MM-DD')
}

export const formatYearDaySOL = date => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format('YYYY/MM/DD')
  }
  return moment(date).format('YYYY/MM/DD')
}

export const formatOnlyMinuteSecondEN = date => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format('HH:mm:ss')
  }
  return moment(date).format('HH:mm:ss')
}

export const formatOnlyMinuteEN = date => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format('HH:mm')
  }
  return moment(date).format('HH:mm')
}

export const formatMinuteSecondCHS = date => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format(intl.get('util.year_minute_second_chs'))
  }
  return moment(date).format(intl.get('util.year_minute_second_chs'))
}

export const formatMinuteSecondSOL = date => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format('YYYY-MM-DD HH:mm:ss')
  }
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

export const formatHoursMinutesCHS = date => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format(intl.get('util.hours_minutes_chs'))
  }
  return moment(date).format(intl.get('util.hours_minutes_chs'))
}
export const formatHoursMinutesPON = date => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format('YYYY-MM-DD HH:mm')
  }
  return moment(date).format('YYYY-MM-DD HH:mm')
}

export const formatHoursMinutesEN = date => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format('YYYY-MM-DD HH:mm')
  }
  return moment(date).format('YYYY-MM-DD HH:mm')
}

export const formatMinuteSecondEN = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format(format)
  }
  return moment(date).format(format)
}

export const formatMonthDayCHS = date => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format(intl.get('util.month_day_chs'))
  }
  return moment(date).format(intl.get('util.month_day_chs'))
}

export const formatMonthDayEN = date => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format('MM-DD')
  }
  return moment(date).format('MM-DD')
}

export const formatOnlyHmStageEN = date => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format('h:mm a')
  }
  return moment(date).format('h:mm a')
}

export const formatHmsStageEN = date => {
  if (!date) {
    return ''
  }
  if (typeof date === 'number') {
    return moment.unix(date).format('h:mm:ss a')
  }
  return moment(date).format('h:mm:ss a')
}

export const formatYMDN4TwoDate = (date, date1) => {
  return `${formatYearDayCHS(date)} - ${formatYearDayCHS(date1)}`
}

export const formatHmN4TwoDate = (date, date1) => {
  return `${formatOnlyMinuteEN(date)} - ${formatOnlyMinuteEN(date1)}`
}

export const kbCloseDropDown = (mine, ref_array, ref_name) => {
  ref_array.forEach(ref_item => {
    if (ref_item == ref_name) {
      return
    }
    const closeDropDowns = mine[ref_item]
    closeDropDowns && closeDropDowns.hide && closeDropDowns.hide()
  })
}

// 转换时间数字为 HH:MM 格式
export const formatTimeNumber = t_number => {
  const zeroFormat = num => {
    if (num < 10) {
      return `0${num}`
    }
    return String(num)
  }
  if (typeof t_number !== 'number' || t_number < 0) {
    return ''
  }
  const hour = Math.floor(t_number / 60)
  const minute = Math.floor(t_number % 60)
  return `${zeroFormat(hour)}:${zeroFormat(minute)}`
}

// 转换时间数字为 HH:MM 格式
export const time_to_sec = time => {
  if (time !== null) {
    let s = ''
    const hour = time.split(':')[0]
    const min = time.split(':')[1]
    s = Number(hour * 60) + Number(min)
    return s
  }
}

export const getDataOfSourceByIDS = (ids, source) => {
  if (!ids || !ids.length) {
    return []
  }

  const list = []
  const idsList = (ids && ids.split(',')) || []
  idsList.forEach(id => {
    list.push(source[id])
  })
  return list
}

export const getFieldErrorMsg = fields => {
  const v = Object.values(fields)
  const field = v.find(j => j.touched && j.error && typeof j.error === 'string')
  return (field && field.error) || ''
}

export const listIsNullCallBack = (list, callback) => {
  if (!list || !list.length) {
    callback && callback()
  }
}

// 获取API错误信息返回
export const getServiceErrorMessage = error => {
  if (!error) {
    return null
  }
  const errorResponse = error.response && JSON.parse(error.response)
  const errorMessage =
    (error && error.message) ||
    (errorResponse
      ? errorResponse.message ||
      (errorResponse._error && errorResponse._error.message)
      : null)
  if (error.status === 404 || error.code === 404) {
    return intl.get('util.service_error_404')
  }

  if (error.status === 500 || error.code === 500) {
    return intl.get('util.service_error_500')
  }

  return errorMessage
}

// fengmap 蜂鸟
export var fengmap = window.fengmap || {}

export const createFengMap = (obj = {}) => {
  const fmapID = obj.fmapID || 'kubantest1' // mapId
  const config = {
    container: document.getElementById(obj.idName), // 渲染dom
    appName: 'kubantest1', // 开发者应用名称
    key: '784ce34083545d12ea066be442670b8d', // 开发者申请应用下web服务的key
    defaultViewMode: fengmap.FMViewMode.MODE_2D,
    mapThemeURL: 'https://media-ssl.kuban.io/static/ent-web/data/theme',
    defaultThemeName: '2001',
    defaultMapScaleLevel: obj.level || 23,
  }

  if (obj.level === 'false') {
    delete config.defaultMapScaleLevel
  }

  const map = new fengmap.FMMap(config)

  // 放大、缩小控件配置
  const ctlOpt1 = new fengmap.controlOptions({
    // 设置显示的位置为左上角
    position: fengmap.controlPositon.LEFT_TOP,
    // 位置x,y的偏移量
    offset: {
      x: 20,
      y: 20,
    },
    size: 20,
    imgURL: 'https://media-ssl.kuban.io/static/ent-web/file/',
  })

  // 地图加载完成事件
  map.on('loadComplete', () => {
    // 放大、缩小控件
    const zoomControl = new fengmap.zoomControl(map, ctlOpt1)

    const searchAnalyser = map.searchAnalyser
    const searchReq = new fengmap.FMSearchRequest(fengmap.FMNodeType.MODEL)
    // searchAnalyser.query(searchReq, function(request, result) {
    //   result.map(res => {
    //     // res && res.setColor('#B8CAD3')
    //     res && res.setColor('#EDEEEE')
    //   })
    // })

    obj.loadComplete && obj.loadComplete()
  })

  // 打开Fengmap服务器的地图数据和主题
  map.openMapById(fmapID)

  return map
}

// 字符串bool
export const dealBoolStr = bool => {
  if (typeof bool === 'string') {
    return bool != 'false'
  }
  return bool
}

/**
  format 数据统计的不正常返回数据
  @param obj: { 201701: 20, 201812: 9 }
  @param date_type: 'month','day'
  @return(Array) [{ date: '2017-01-12', formated_date: '2017年1月12日', value: 99 }]
*/
export const formatStatisticToArray = (obj, date_type) => {
  if (!obj) {
    return []
  }
  const keys = Object.keys(obj)
  const values = Object.values(obj)
  const newArr = []
  keys.map((key, index) => {
    const singleValue = {}
    let date = ''
    const formatedDateStr = String(key)
      .replace(/(.{6})/, '$1-')
      .replace(/(.{4})/, '$1-')
    if (date_type === 'month') {
      date = `${moment(formatedDateStr).month() + 1}月`
    } else if (date_type === 'day') {
      date = `${moment(formatedDateStr).date()}日`
    } else {
      date = formatedDateStr
    }
    singleValue.formated_date = date
    singleValue.value = values[index]
    singleValue.date = formatedDateStr
    newArr.push(singleValue)
    return key
  })
  return newArr
}

/**
 * 根据合同租金和税率获取合同税额
 */
export const getTaxAmountOfContract = (rent, tax_rate) => {
  const rentAmount = rent || 0
  const rate = tax_rate || 0
  return Math.round(parseFloat((rentAmount * rate) / (1 + rate)) * 100) / 100
}

export const getUrlParam = name => {
  // FIXME: remove this
  if (name === 'space_id') {
    return getUrlParam('spaceid')
  }
  if (name === 'location_id') {
    return getUrlParam('locationid')
  }

  const pairs = qs.parse(window.location.search)
  return pairs[name]
}

/*
 * export json response to filename.xls
 */
export const exportCsv = (text, filename) => {
  const csvContent = `data:text/csv;charset=utf-8,${`\ufeff${text}`}` || ''
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', `${filename}.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/*
 * export json response to filename.docx
 */
export const exportWord = async (text, filename) => {
  // const content =
  //   `data:application/vnd:ms-word;charset=utf-8,${`\ufeff${text}`}` || ''
  // const encodedUri = encodeURI(content)
  const href = await (window.webkitURL || window.URL).createObjectURL(
    new Blob([text]),
    {
      type:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
  )
  const link = document.createElement('a')
  link.setAttribute('href', href)
  link.setAttribute('download', `${filename}.docx`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const exportFile = (data, filename, mime, bom) => {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()

    // Fixes "webkit blob resource error 1"
    setTimeout(() => {
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(blobURL)
    }, 200)
  }
}

// 用于仪表盘相关页面计算环比
export const cycleRatio = (last, now) => {
  let rate = 0
  if (!last || last === 0) {
    if (!now || now === 0) {
      return rate
    }
    return (rate = 100)
  }
  rate = ((now - last) / last).toFixed(2) * 100
  if (isNaN(rate)) {
    return (rate = 0)
  }
  return rate
}

// start - 开始时间
// end - 结束时间
// date - 日期
// meetingRoom - 会议室
// reservationsOfMeetingRoom - 会议室的预定列表
export const canSelectSlot = (
  start,
  end,
  date,
  meetingRoom,
  reservationsOfMeetingRoom,
  meetingRoomManagePermission,
) => {
  // const step = 30; // 每个slot的时间长度
  // const currentTime = moment(date); // 当前日期
  const startMoment = moment(start) // 当前时间 + 日期
  const endMoment = moment(end) // 当前时间 + 日期
  // const currentTime = moment();
  // convert currentTime to 30 minutes ago
  // const currentSlotTime = currentTime.startOf('day').add(currentTime.minutes() / step * step, 'minutes');

  if (meetingRoom && meetingRoom.state === 'disabled') {
    // message.error(tr('meetingInfo.roomDisabled'))
    // cannot reserve
    return false
  }

  if (startMoment.isBefore(moment().subtract(30, 'minutes'))) {
    // message.error(tr('meetingInfo.startBeforeCurrent'))
    // cannot reserve if start before current currentSlotTime
    return false
  }

  if (endMoment.isBefore(moment())) {
    // message.error(tr('meetingInfo.endBeforeCurrent'))
    // cannot reserve if start before current currentSlotTime
    return false
  }
  //
  // const availableTimeRange = getAvailableTimeRange(meetingRoom, startMoment, meetingRoomManagePermission);

  // if (startMoment.isBefore(availableTimeRange.start)) {
  //   message.error(tr('meetingInfo.startBeforeAvailable'))
  //   return false;
  // }

  // if (endMoment.isAfter(availableTimeRange.end)) {
  //   message.error(tr('meetingInfo.endAfterAvailable'))
  //   return false;
  // }

  const canSelect = isNonConflictingTime(start, end, reservationsOfMeetingRoom)

  if (!canSelect) {
    // message.error(tr('meetingInfo.conflict'))
    return false
  }
  return true
}

// 判断开始和结束时间是否与预定重合
export const isNonConflictingTime = (startAt, endAt, reservations) => {
  for (let i = 0; i < reservations.length; i++) {
    const { start_at, end_at } = reservations[i]
    const startMoment = moment.unix(start_at)
    const endMoment = moment.unix(end_at)
    if (
      !(endMoment.isSameOrBefore(startAt) || startMoment.isSameOrAfter(endAt))
    ) {
      return false
    }
  }

  return true
}

// 将省市县的id拼接成为数组
export const getCityCascaderValue = obj => {
  return [
    _.get(obj, 'province.id'),
    _.get(obj, 'city.id'),
    _.get(obj, 'district.id'),
  ]
}

/**
 * 根据00:00到HH:MM的间隔分钟数转换为当天HH:MM
 * @param {[number]} 00:00到HH:MM的间隔分钟数
 * @returns HH:mm
 * from @sunjingjing Tel 13602085683
 */
export const getTimeFromMins = mins => {
  // do not include the first validation check if you want, for example,
  // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
  if (mins >= 24 * 60 || mins < 0) {
    return
  }
  const h = Math.floor(mins / 60)
  const m = Math.floor(mins % 60)
  return moment(`${h}:${m}`, 'HH:mm')
}

/**
 * 计算当天00：00到HH:mm的分钟数
 * @param {[string]} HH:mm格式的时间
 * @returns {[number]} 分钟数
 * from @sunjingjing Tel 13602085683
 */
export const getMinsFromTime = time => {
  if (_.isNil(time)) {
    return
  }
  const timeArr = time.split(':') || []
  const mins = timeArr[0] * 60 + timeArr[1] * 1
  return mins
}

export const formatTimeToMoment = (date, defaultMoment) => {
  if (!date) {
    return defaultMoment
  }
  if (typeof date === 'number') {
    return moment.unix(date)
  }
  const d = moment(date)
  return d
}

export const formatDate = (date, format = 'YYYY-MM-DD') => {
  if (!date) {
    return undefined
  }
  if (typeof date === 'number') {
    return moment.unix(date).format(format)
  }
  if (typeof date === 'string') {
    return moment(date).format(format)
  }
  return date.format(format)
}

/**
 * 将秒数转化为X天X小时X分（时间差格式化）
 * 86400 = 24小时*60分钟*60秒
 * @param {[number]} 秒数或计算出的时间差（以秒为单位）
 * @returns {[string]} X天X小时X分
 * from @sunjingjing Tel 13602085683
 */
export const secondsToTime = time => {
  if (_.isNil(time)) {
    return
  }
  if (time < 60) {
    return `${time}秒`
  }
  let str = ''
  const day = Math.floor(time / 86400)
  if (day > 0) {
    str += `${day}天`
  }
  const time_h = Math.floor((time % 86400) / 3600)
  if (time_h > 0) {
    str += `${time_h}小时`
  }
  const time_m = Math.floor(((time % 86400) % 3600) / 60)
  if (time_m > 0) {
    str += `${time_m}分钟`
  }
  if (day < 0) {
    const time_s = Math.floor(((time % 86400) % 3600) % 60)
    if (time_s > 0) {
      str += `${time_s}秒`
    }
  }
  return str
}

/**
 * reverse geocode, return
 */
export const reverseGeocode = async (city = '北京', address = '北京市') => {
  const str = querystring.encode({
    city,
    address,
    key: import.meta.env.VITE_GAODE_MAP_KEY,
  })

  const url = `https://restapi.amap.com/v3/geocode/geo?${str}`

  try {
    const res = await axios({
      url,
      adapter: jsonpAdapter,
      callbackParamName: 'callback', // optional, 'callback' by default
    })
    const geocode = _.get(res.data, 'geocodes[0]')
    if (geocode) {
      const [longitude, latitude] = (geocode.location || '').split(',')
      return {
        ...geocode,
        latitude,
        longitude,
      }
    }
    return {}

  } catch (e) {
    console.warn('*** failed to reverse geocode', e)
    return {}
  }
}

/**
 * reverse geocode, return
 */
export const getAddressSuggestions = async (
  city = '北京',
  keywords = '北京市',
) => {
  const str = querystring.encode({
    city,
    keywords,
    key: import.meta.env.VITE_GAODE_MAP_KEY,
    output: 'JSON',
    // types: ['050000', '060000', '070000', '080000', '100000', '110000', '120000', '130000', '140000', '170000', '190000'].join('&') // types = 120000: 商务住宅
  })

  const url = `https://restapi.amap.com/v3/place/text?${str}`

  try {
    const res = await axios({
      url,
      adapter: jsonpAdapter,
      callbackParamName: 'callback', // optional, 'callback' by default
    })
    const pois = _.get(res.data, 'pois')
    return pois
  } catch (e) {
    console.warn('*** failed to get addressSuggestions', e)
    return []
  }
}

export const chartsXAxisFormat = (data = [], key = '') => {
  return data.map(usage => usage[key].slice(0, 7))
}

// 图表X轴按时间format
export const formatDateAxis = (data = [], key = 'key', format = 'YYYY-MM') => {
  return data.map(x => moment(_.get(x, key, 0)).format(format))
}

export const chartsDataFormat = (data = [], key = '') => {
  return data.map(usage =>
    parseFloat(Number(_.get(usage, key) || 0).toFixed(2)),
  )
}

// unitName - 单位
export const chartsDataName = (data = [], unitName = '') => {
  return data.map(item => chartsItemName(item, unitName))
}

export const chartsItemName = (item, unitName = '') => {
  return !item.to
    ? `大于${item.from}${unitName}`
    : `${item.from}-${item.to}${unitName}`
}

export const paramsRangeFormat = (
  params = {},
  start = 'start_date',
  end = 'end_date',
) => {
  if (params.range) {
    params[start] = moment(params.range[0])
      .startOf('month')
      .format('YYYY-MM-DD')
    params[end] = moment(params.range[1])
      .endOf('month')
      .format('YYYY-MM-DD')
    delete params.range
  } else {
    params[start] = moment()
      .add(-1, 'y')
      .startOf('month')
      .format('YYYY-MM-DD')
    params[end] = moment()
      .endOf('month')
      .format('YYYY-MM-DD')
  }
  return params
}

export const isMobile = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  console.log('***userAgent***', userAgent)
  return userAgent.match(
    /ipad|midp|rv:1.2.3.4|iphone os|ucweb|android|windows ce|windows mobile/i,
  )
}

/**
 * 自定义字段列表dynamic_attributes，拆分成form表单
 * 例：{dynamic_attributes: [{"name"=>"n19880001", "value"=>"test"}]} ==> {n19880001: 'test'}
 */
export const dynamicAttributeList2Form = form => {
  if (form === undefined || form === null) {
    return form
  }

  const dynamicAttributes = {}
  _.get(form, 'dynamic_attributes', []).forEach(item => {
    dynamicAttributes[item.name] = item.value
  })

  return {
    ...dynamicAttributes,
    ...form,
  }
}

export const windowIncludes = name => {
  return window.location.href.includes(name)
}
// 多级数据取有当前id的一条
export const deepQuery = (tree, id) => {
  let isGet = false
  let retNode = null
  function deepSearch(tree, id) {
    for (let i = 0; i < tree.length; i++) {
      if (tree[i].children && tree[i].children.length > 0) {
        deepSearch(tree[i].children, id)
      }
      if (id == tree[i].id || isGet) {
        isGet || (retNode = tree[i])
        isGet = true
        break
      }
    }
  }
  deepSearch(tree, id)
  return retNode
}

// 扁平化对象数组
export const flatten = (arr, key = 'children') => {
  return arr.reduce((result, item) => {
    return result.concat(
      item,
      Array.isArray(item[key]) ? flatten(item[key]) : [],
    )
  }, [])
}


// 把url后面的参数转化成对象的形式

export const parseQueryString = (url) => {
  if (!url) {
    return {}
  }
  const obj = {}
  const params = url.slice(1)
  const arr = (params.split('&'))
  if (arr?.length && arr[0]) {
    arr.forEach((v, i) => {
      const str = v.split('=')[1]
      const key = v.split('=')[0]
      if (str.indexOf('%') === -1) {
        obj[key] = str
      } else {
        try {
          obj[key] = JSON.parse(decodeURIComponent(str))
        } catch (error) {
          obj[key] = decodeURIComponent(str)
        }
      }

    })

  }

  return obj
}
