import React from 'react'
import { Route } from 'react-router-dom'
import lazyLoad from 'utils/lazyLoad'

const AdditionalServices = lazyLoad(() => import('containers/additionalService/AdditionalServices'))
const ApplicationsBox = lazyLoad(() => import('containers/application/ApplicationsBox'))
const ApplicationManagements = lazyLoad(() => import('containers/ApplicationManagement/ApplicationManagements'))
const FinanceSetBox = lazyLoad(() => import('containers/invoices/FinanceSetBox'))
const TenementBox = lazyLoad(() => import('containers/invoices/TenementBox'))
const DeliveryPoint = lazyLoad(() => import('containers/logistics/DeliveryPoint'))
const MeetingSettings = lazyLoad(() => import('containers/meeting/MeetingSettings'))
const LocationSettingBox = lazyLoad(() => import('containers/shuck/LocationSettingBox'))
const GuideLocation = lazyLoad(() => import('containers/spaces/GuideLocation'))
const LocationSetArea = lazyLoad(() => import('containers/spaces/LocationSetArea'))
const LocationSetAssociated = lazyLoad(() => import('containers/spaces/LocationSetAssociated'))
const LocationSetFinance = lazyLoad(() => import('containers/spaces/LocationSetFinance'))
const LocationSetInvoice = lazyLoad(() => import('containers/spaces/LocationSetInvoice'))
const LocationSetPayment = lazyLoad(() => import('containers/spaces/LocationSetPayment'))
const LocationSettingListener = lazyLoad(() => import('containers/spaces/LocationSettingListener'))
const LocationSettingRole = lazyLoad(() => import('containers/spaces/LocationSettingRole'))
const OvertimeSettings = lazyLoad(() => import('containers/spaces/setting/location/OvertimeSettings'))
const LocationBasicSettingsBox = lazyLoad(() => import('containers/spaces/setting/location/BasicSettingsBox'))
const LoadingScreen = lazyLoad(() => import('containers/spaces/setting/location/LoadingScreen'))
const LocationSettingStation = lazyLoad(() => import('containers/spaces/setting/location/LocationSettingStation'))

const ScreenConfig = lazyLoad(() => import('containers/spaces/setting/location/ScreenConfig'))
const LocationSettingOptions = lazyLoad(() => import('containers/spaces/setting/SpaceSettingOptions'))
const UtilitiesSettings = lazyLoad(() => import('containers/spaces/UtilitiesSettings'))
const VisitAuditSet = lazyLoad(() => import('containers/visit/VisitAuditSet'))
const VisitDeviceManage = lazyLoad(() => import('containers/visit/VisitDeviceManage'))
const VisitNotifySet = lazyLoad(() => import('containers/visit/VisitNotifySet'))
const VisitQrCodeSet = lazyLoad(() => import('containers/visit/VisitQrCodeSet'))
const VisitSettingsBox = lazyLoad(() => import('containers/visit/VisitSettingsBox'))
const VisitSignInSet = lazyLoad(() => import('containers/visit/VisitSignInSet'))
const VisitSignOutSet = lazyLoad(() => import('containers/visit/VisitSignOutSet'))
const VisitTypeEditForm = lazyLoad(() => import('containers/visit/VisitTypeEditForm'))
const VisitWelcomeSet = lazyLoad(() => import('containers/visit/VisitWelcomeSet'))
const ShoppingBox = lazyLoad(() => import('containers/shopping/ShoppingBox'))
const Employees = lazyLoad(() => import('containers/spaces/Employees'))
const Employee = lazyLoad(() => import('containers/spaces/Employee'))

const CostType = lazyLoad(() => import('../containers/invoices/CostType'))
const LocationProviders = lazyLoad(() => import('../containers/spaces/components/LocationProviders'))


import clientRoutes from './clientRoutes'

export default function () {
    return (
        <Route
            path="settings"
            element={LocationSettingBox}>
            <Route
                path="basic"
                element={LocationBasicSettingsBox} />
            <Route
                path="employees"
                element={ShoppingBox}>
                <Route
                    index
                    element={Employees} />
                <Route
                    path=":employeeId"
                    element={Employee} />
            </Route>
            {/*
        <Route path="basic" element={<LocationBasicSettings />} /> */}
            {/* </Route> */}
            <Route
                path="role"
                element={LocationSettingRole} />
            <Route
                path="notifications"
                element={LocationSettingListener} />
            <Route
                path="overtime"
                element={OvertimeSettings} />
            <Route
                path="associated"
                element={LocationSetAssociated} />
            <Route
                path="area"
                element={LocationSetArea} />
            <Route
                path="value_added"
                element={AdditionalServices} />
            <Route
                path="import_data"
                element={GuideLocation} />
            <Route
                path="finance"
                element={FinanceSetBox}>
                <Route
                    index
                    element={LocationSetInvoice} />
                <Route
                    path="invoice_setting"
                    element={LocationSetInvoice} />
                <Route
                    path="finance_setting"
                    element={LocationSetFinance} />
                <Route
                    path="payment_setting"
                    element={LocationSetPayment} />
                <Route
                    path="additional_services"
                    element={AdditionalServices} />
                <Route
                    path="providers"
                    element={LocationProviders} />
                <Route
                    path="cost_type"
                    element={CostType} />
            </Route>
            <Route
                path="visit_settings"
                element={VisitSettingsBox}>
                <Route
                    index
                    element={VisitWelcomeSet} />
                <Route
                    path="welcome"
                    element={VisitWelcomeSet} />
                <Route
                    path="sign_in"
                    element={VisitSignInSet} />
                <Route
                    path="visitor_type_edit"
                    element={VisitTypeEditForm} />
                <Route
                    path="notify"
                    element={VisitNotifySet} />
                <Route
                    path="sign_out"
                    element={VisitSignOutSet} />
                <Route
                    path="qrcode_setting"
                    element={VisitQrCodeSet} />
                <Route
                    path="audit"
                    element={VisitAuditSet} />
                <Route
                    path="devices"
                    element={VisitDeviceManage} />
            </Route>
            <Route
                path="application"
                element={ApplicationsBox} />
            <Route
                path="application_management"
                element={ApplicationManagements} />
            <Route
                path="options"
                element={LocationSettingOptions} />
            <Route
                path="delivery_point"
                element={DeliveryPoint} />
            <Route
                path="station"
                element={LocationSettingStation} />
            {/* <Route path="loading_screen" element={<LoadingScreen />} /> */}
            <Route
                path="meeting_settings"
                element={MeetingSettings} />
            <Route
                path="tenement_settings"
                element={TenementBox}>
                <Route
                    index
                    element={UtilitiesSettings} />
                <Route
                    path="utilities"
                    element={UtilitiesSettings} />
            </Route>

            <Route
                path="loading_screen"
                element={LoadingScreen} />
            <Route
                path="screen_config"
                element={ScreenConfig} />
            {/* <Route path="message" element={<LocationSendMessageForm />} /> */}
            {clientRoutes()}
        </Route>
    )
}
