import React, { useEffect, useState } from 'react'
const WAS_REFRESHED_BEFORE = 'was-refreshed-before'
import { Modal } from 'antd'
import { reloadWithTimestamp } from '@/utils/utils'

// 版本对比
function semverGreaterThan(versionA, versionB) {
  const versionsA = versionA.split(/\./g)
  const versionsB = versionB.split(/\./g)
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())
    const b = Number(versionsB.shift())
    if (a === b) continue
    return a > b || isNaN(b)
  }
  return false
}

function refreshCacheAndReload() {
  localStorage.setItem(WAS_REFRESHED_BEFORE, 'true')
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) caches.delete(name)
    })
  }
  // delete browser cache and hard reload
  reloadWithTimestamp()
}

const defaultStyle = {
  position: 'fixed',
  bottom: 0,
  zIndex: 99999,
  padding: '3px 7px',
  fontSize: '12px',
  background: '#5d5d5d',
  color: 'white',
  letterSpacing: '1px',
  fontFamily: 'Helvetica',
}

/**
 * sometimes browsers cashes websites, thus presenting old version of the site.
 * the VersionCheck component check if your version is the latest,
 * and if not, it forces hard refresh on the site, to bring its latest version.
 * */
export default function VersionCheck(props) {
  const {
    currentVersion,
    logs = true,
    display = false,
    dependencies = [],
    time = 60000,
    serverFilePath = '/meta.json',
    className = '',
    style = {},
    side = 'left',
  } = props
  const [loading, setLoading] = useState(false)
  const [isLatestVersion, setIsLatestVersion] = useState(true)
  const [error, setError] = useState(false)
  let timer
  function print(msg, logger = console.log) {
    // if (logs) logger(`VERSION-CHECK: ${msg}`)
  }

  function Render(p) {
    const newStyle = {
      [side]: 0,
      ...defaultStyle,
      ...style,
    }

    return (
      <div
        style={newStyle}
        className={className}>
        {p.children}
      </div>
    )
  }

  function checkTime () {
    // if (localStorage.getItem(WAS_REFRESHED_BEFORE)) {
    //   localStorage.removeItem(WAS_REFRESHED_BEFORE)
    //   print('server version is bigger then local version', console.error)
    //   setError(true)
    //   return
    // }
    // reset all
    setLoading(true)
    setIsLatestVersion(false)
    setError(false)

    // 获取远端版本
    fetch(`${serverFilePath  }?t=${Date.now().toString()}`)
      .then((response) => response.json())
      .then((meta) => {
        print('fetching version...')
        const latestVersion = meta.version
        if (!latestVersion)
          throw new Error(
            `property "version" was not found in the file: ${serverFilePath}.json`,
          )

        // 版本对比
        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion,
        )

        if (shouldForceRefresh) {
          print(
            `有一个新的版本 - ${latestVersion}.`,
          )
          // setIsLatestVersion(false)
          clearInterval(timer)
          Modal.info({
            title: '版本更新',
            content: '有一个新的系统版本，需要立即更新',
            onOk: () => {
              refreshCacheAndReload()
            },
          })
        } else {
          print(
            `当前是最新版本无需更新，version: ${currentVersion}. `,
          )
          setIsLatestVersion(true)
        }
      })
      .catch((e) => {
        print(e, console.error)
        setError(true)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    timer = setInterval(checkTime, time)
    return () => {
      if (timer) {
        clearInterval(timer)
        timer = null
      }
    }
  }, [])

  if (loading || error || isLatestVersion) {
    if (!display) return null

    if (loading) {
      return <Render>loading version...</Render>
    }
    if (error) {
      return <Render>version error</Render>
    }
    return (
      <Render>
        version: <code>{currentVersion}</code>
      </Render>
    )
  }

  print('clearing cache and hard reloading...')
  // refreshCacheAndReload()
  return null
}
