import * as _ from 'lodash'

function locationGroupRole(state = {}, action = {}) {
  if (action.type === 'GETLOCATIONGROUPROLE_SUCCESS') {
    return action.response.json
  }

  if (action.type === 'UPDATELOCATIONROUPROLE_SUCCESS') {
    return action.response.json
  }

  return state
}

export default locationGroupRole
