import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoad from 'utils/lazyLoad'

const AllProductOrders = lazyLoad(() => import('containers/shopping/AllProductOrders'))
const AllShopActivity = lazyLoad(() => import('containers/shopping/AllShopActivity'))
const ProductCategories = lazyLoad(() => import('containers/shopping/ProductCategories'))
const ProductOrders = lazyLoad(() => import('containers/shopping/ProductOrders'))
const SalesProducts = lazyLoad(() => import('containers/shopping/SalesProducts'))
const Shop = lazyLoad(() => import('containers/shopping/Shop'))
const ShoppingBox = lazyLoad(() => import('containers/shopping/ShoppingBox'))
const Shops = lazyLoad(() => import('containers/shopping/Shops'))
const ShopSetting = lazyLoad(() => import('containers/shopping/shopSetting'))

export default function() {
    return (
        <Route path="shopping" element={ShoppingBox}>
            <Route path="list" element={Shops} />
            <Route path="list/:shopId" element={Shop}>
                <Route index element={SalesProducts} />
                <Route path="product_categories" element={ProductCategories} />
                <Route path="product_orders" element={ProductOrders} />
            </Route>
            <Route path="list/:shopId/setting" element={ShopSetting} />
            <Route path="orders" element={AllProductOrders} />
            <Route path="activity" element={AllShopActivity} />
        </Route>
    // <Route path="goods_warehouse" element={<GoodsWarehouse />} />
    // <Route path="selling_goods" element={<SellingGoods />} />
    )
}
