
import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoad from 'utils/lazyLoad'

const AssetResourceList = lazyLoad(() => import('containers/assetResources/AssetResourceList'))
const AssetResourceItemList = lazyLoad(() => import('../containers/assetResources/AssetResourceItemList'))

export default function () {
    return (
        <Route path="asset_resources" element={AssetResourceList}>
            {/* 处理自动跳转 */}
            <Route path=":asset_resouce_items" element={AssetResourceItemList}>
                {/* <Route path="type" element={<TasksList />} /> */}
            </Route>
        </Route>
    )
}
