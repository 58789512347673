import antD_zhCN from 'antd/lib/locale-provider/zh_CN'
import antD_enUS from 'antd/lib/locale-provider/en_US'
import ZHCNJSON from '@/locales/zh-CN.json'
import ENUSJSON from '@/locales/en-US.json'

// 支持的语言

export const SUPPORTED_LOCALES = [
  {
    name: "English",
    value: "en-US",
    data: ENUSJSON,
    // logo: enUS,
    antD: antD_enUS
  },
  {
    name: "简体中文",
    value: "zh-CN",
    data: ZHCNJSON,
    // logo: zhCN,
    antD: antD_zhCN
  },
  // {
  //   name: "繁體中文",
  //   value: "zh-TW"
  // },
  // {
  //   name: "français",
  //   value: "fr-FR"
  // },
  // {
  //   name: "日本語",
  //   value: "ja-JP"
  // }
]
