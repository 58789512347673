import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoad from 'utils/lazyLoad'

const AssistBoxSaas = lazyLoad(() => import('../containers/shuck/AssistBoxSaas'))
const LandingMobile = lazyLoad(() => import('../containers/assist/LandingMobile'))
const LoginSelfService = lazyLoad(() => import('../containers/assist/LoginSelfService'))
const Logout = lazyLoad(() => import('../containers/assist/Logout'))
const RegisterSelfService = lazyLoad(() => import('../containers/assist/RegisterSelfService'))
const LockBox = lazyLoad(() => import('../containers/shuck/LockBox'))

// import Login from '../containers/assist/Login';
// import WechatLogin from '../containers/login/WechatLogin'

export default function() {
    return (
        <>
            <Route element={AssistBoxSaas}>
                {/* <Route element={<Login />} /> */}
                <Route path="login" element={LoginSelfService} />
                {/* <Route path='login_with_wechat_code' element={<WechatLogin/>} /> */}
                <Route path="logout" element={Logout} />
                <Route path="register" element={RegisterSelfService} />
            </Route>
            <Route element={LockBox}>
                <Route path="landing_mobile" element={LandingMobile} />
            </Route>
        </>
    )
}