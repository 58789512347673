import React from 'react'
import { Route } from 'react-router-dom'
import lazyLoad from 'utils/lazyLoad'

const ActivitiesInfo = lazyLoad(() => import('containers/activities/ActivitiesInfo'))
const ActivitiesList = lazyLoad(() => import('containers/activities/ActivitiesList'))
const ActivityBox = lazyLoad(() => import('containers/activities/ActivityBox'))
const ActivityForm = lazyLoad(() => import('containers/activities/ActivityForm'))
const Orders = lazyLoad(() => import('containers/invoices/Orders'))
const Subscriptions = lazyLoad(() => import('containers/invoices/Subscriptions'))
const LockEvents = lazyLoad(() => import('containers/locks/LockEvents'))
const LockInfo = lazyLoad(() => import('containers/locks/LockInfo'))
const LockPermissions = lazyLoad(() => import('containers/locks/LockPermissions'))
const Locks = lazyLoad(() => import('containers/locks/Locks'))
const Consignment = lazyLoad(() => import('containers/logisticsManagement/Consignment'))
const LocationMemberInfoBox = lazyLoad(() => import('containers/members/LocationMemberInfoBox'))
const MemberInfo = lazyLoad(() => import('containers/members/MemberInfo'))
const MemberOrders = lazyLoad(() => import('containers/members/MemberOrders'))
const MembersCoupons = lazyLoad(() => import('containers/members/MembersCoupons'))
const MembersLockPermission = lazyLoad(() => import('containers/members/MembersLockPermission'))
const MembersLocksRecord = lazyLoad(() => import('containers/members/MembersLocksRecord'))
const MembersMeetRecord = lazyLoad(() => import('containers/members/MembersMeetRecord'))
const MembersPermissionRecord = lazyLoad(() => import('containers/members/MembersPermissionRecord'))

const MembersPoint = lazyLoad(() => import('containers/members/MembersPoint'))
const ContractInfoBox = lazyLoad(() => import('containers/organization/ContractInfoBox'))
const InvoiceApplication = lazyLoad(() => import('containers/organization/InvoiceApplication'))
const Organization = lazyLoad(() => import('containers/organization/Organization'))
const OrganizationAnnex = lazyLoad(() => import('containers/organization/OrganizationAnnex'))
const OrganizationLocks = lazyLoad(() => import('containers/organization/OrganizationLocks'))
const OrganizationAreas = lazyLoad(() => import('containers/organization/organizationAreas/OrganizationAreas'))
const OrganizationMembers = lazyLoad(() => import('containers/organization/OrganizationMembers'))
const OrganizationPoint = lazyLoad(() => import('containers/organization/OrganizationPoint'))
const OrgContractDetail = lazyLoad(() => import('containers/organization/OrgContractDetail'))
const OrgContractForm = lazyLoad(() => import('containers/organization/OrgContractForm'))
const OrgCouponRecords = lazyLoad(() => import('containers/organization/OrgCouponRecords'))
const OrgCoupons = lazyLoad(() => import('containers/organization/OrgCoupons'))
const OrgDetails = lazyLoad(() => import('containers/organization/OrgDetails'))
const OrgMemberForm = lazyLoad(() => import('containers/organization/orgMemberForm/OrgMemberForm'))
const OrgReservations = lazyLoad(() => import('containers/organization/OrgReservations'))
const SubscriptionAttrlogs = lazyLoad(() => import('containers/organization/SubscriptionAttrlogs'))
const SubscriptionHistories = lazyLoad(() => import('containers/organization/SubscriptionHistories'))
const LockBox = lazyLoad(() => import('containers/shuck/LockBox'))
const LockManageBox = lazyLoad(() => import('containers/shuck/LockManageBox'))

const LogisticsManagementBox = lazyLoad(() => import('containers/shuck/LogisticsManagementBox'))
const SupplierManagementBox = lazyLoad(() => import('containers/shuck/SupplierManagementBox'))
const UserSettingBox = lazyLoad(() => import('containers/shuck/UserSettingBox'))
const GoodsClassification = lazyLoad(() => import('containers/supplierManagement/GoodsClassification'))
const OrderManagement = lazyLoad(() => import('containers/supplierManagement/OrderManagement'))
const ShelvesGoods = lazyLoad(() => import('containers/supplierManagement/ShelvesGoods'))
const PassWord = lazyLoad(() => import('containers/user/PassWord'))
const UserInfo = lazyLoad(() => import('containers/user/UserInfo'))
const MeetingIndex = lazyLoad(() => import('containers/meeting/MeetingIndex'))
const MeetingInfo = lazyLoad(() => import('containers/meeting/MeetingInfo'))

const MeetingInfoBox = lazyLoad(() => import('containers/meeting/MeetingInfoBox'))
const MeetingInfoSettingBox = lazyLoad(() => import('containers/meeting/MeetingInfoSettingBox'))
const MeetingManage = lazyLoad(() => import('containers/meeting/MeetingManage'))
const MeetingRecord = lazyLoad(() => import('containers/meeting/MeetingRecord'))
const SubscriptionInvoices = lazyLoad(() => import('../containers/organization/SubscriptionInvoices'))
const Tasks = lazyLoad(() => import('../containers/organization/Tasks'))

import invoiceRoutes from './invoiceRoutes'
import subscriptionRoutes from './subscriptionRoutes'

export default function() {
  return (
    <Route path="organizations/:organization_id"
      element={Organization}>
      <Route index
        element={OrgDetails} />
      <Route path="members"
        element={OrganizationMembers} />
      {/* <Route path="members" element={<OrganizationMembers />} /> */}
      <Route path="members/:member_id"
        element={LocationMemberInfoBox}>
        <Route index
          element={MemberInfo} />
        <Route path="info"
          element={MemberInfo} />
        <Route path="lock_permissions"
          element={MembersLockPermission} />
        <Route
          path="permissions_record"
          element={MembersPermissionRecord}
        />
        <Route path="lock_events"
          element={MembersLocksRecord} />
        <Route path="meeting"
          element={MembersMeetRecord} />
        <Route path="point"
          element={MembersPoint} />
        <Route path="coupons"
          element={MembersCoupons} />
        <Route path="orders"
          element={MemberOrders} />
      </Route>
      <Route path="areas"
        element={OrganizationAreas} />
      <Route path="contract"
        element={Subscriptions} />
      <Route path="invoice_application"
        element={InvoiceApplication} />

      <Route path="point"
        element={OrganizationPoint} />
      <Route path="coupons"
        element={OrgCoupons} />
      <Route path="market"
        element={OrgCouponRecords} />
      <Route path="meeting"
        element={OrgReservations} />
      <Route path="meeting/book"
        element={MeetingIndex} />
      <Route path="meeting/manage"
        element={MeetingManage} />
      <Route path="meeting/record"
        element={MeetingRecord} />
      <Route path="meeting/:meeting_id"
        element={MeetingInfoBox}>
        <Route index
          element={MeetingInfo} />
        <Route path="info"
          element={MeetingInfo} />
        <Route path="settings"
          element={MeetingInfoSettingBox} />
      </Route>

      <Route path="locks"
        element={LockBox}>
        <Route index
          element={LockEvents} />
        <Route path="lock_info"
          element={LockManageBox}>
          <Route path="locks"
            element={Locks} />
          <Route path="locks/:lock_id"
            element={LockInfo} />
        </Route>
        <Route path="lock_permission"
          element={LockPermissions} />
      </Route>
      {/* 商品管理 */}
      <Route path="supplier_management"
        element={SupplierManagementBox}>
        <Route index
          element={GoodsClassification} />
        <Route path="shelves_goods"
          element={ShelvesGoods} />
        <Route path="order_management"
          element={OrderManagement} />
      </Route>
      {/* 物流管理 */}
      <Route path="logistics_management"
        element={LogisticsManagementBox}>
        <Route index
          element={Consignment} />
      </Route>

      <Route path="annex"
        element={OrganizationAnnex} />
      <Route path="employee_locks"
        element={OrganizationLocks} />
      <Route path="new_member"
        element={OrgMemberForm} />
      <Route path="activity"
        element={ActivityBox}>
        <Route index
          element={ActivitiesList} />
        <Route path="edit"
          element={ActivityForm} />
        <Route path=":activity_id/info"
          element={ActivitiesInfo} />
      </Route>

      {/* TODO: taoh - remove?? */}
      <Route path="new_subscription"
        element={OrgContractForm} />

      {invoiceRoutes()}
      {subscriptionRoutes()}
      <Route path="orders"
        element={Orders} />
      <Route path="tasks"
        element={Tasks} />

      <Route path="contract/:subscription_id"
        element={ContractInfoBox}>
        <Route index
          element={OrgContractDetail} />
        <Route path="info"
          element={OrgContractDetail} />
        <Route path="attrlogs"
          element={SubscriptionAttrlogs} />
        <Route path="histories"
          element={SubscriptionHistories} />
        <Route path="invoices"
          element={SubscriptionInvoices} />
      </Route>
      <Route path="user_setting"
        element={UserSettingBox}>
        <Route path="info"
          element={UserInfo} />
        {/* <Route element={<UserInfo />} /> */}
        <Route path="password"
          element={PassWord} />
      </Route>
    </Route>
  )
}
