export default function workbench(state = {}, action = {}) {
  if (action.type === 'UPDATE_TASK_BADGE') {
    return {
      pending_task_list: action.pending_task_list
    }
  }
  if (action.type === 'TASK_CONNECTED') {
    return {
      connected: action.connected
    }
  }
  if (action.type === 'UPDATE_TASK_LATEST_MESSAGE') {
    return {
      latestTaskMessage: action.latestTaskMessage
    }
  }
  return state
}
