import { STATE_COLOR } from "./StateColor"

/** plan id 和后端的一致，是固定的 */
export const SAAS_PLANS = [
  {
    id: 10000, // 资管云
    feature: 'asset_management'
  },
  {
    id: 10001, // 定制版
    feature: 'custom'
  }
]


// 付款方式
export const SAAS_PAYMENT_METHODS = [
  {
    id: 'no_payment'
  },
  {
    id: 'alipay'
  },
  {
    id: 'offline'
  },
  {
    id: 'balance'
  }
]

// 付款方式
export const SAAS_SUBSCRIPTION_STATES = [
  {
    id: 'pending',
    color: STATE_COLOR.processing
  },
  {
    id: 'free',
    color: STATE_COLOR.blue
  },
  {
    id: 'trial',
    color: STATE_COLOR.orange
  },
  {
    id: 'active',
    color: STATE_COLOR.green
  },
  {
    id: 'past_due',
    color: STATE_COLOR.red
  },
  {
    id: 'expired',
    color: STATE_COLOR.gray
  }
]
