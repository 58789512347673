export { setLocale } from './locale'
export { setCurrentLocationAction } from './currentLocation'
export { setCurrentLocationGroupAction } from './currentLocationGroup'
export {
    setWorkbenchBadgeAction,
    setWorkbenchConnectedAction
} from './workbench'
export { setTaskConnectedAction, setTaskBadgeAction, setLatestTaskMessage } from './task'

// Define constants for action type naming
const REQUEST = 'REQUEST'
const SUCCESS = 'SUCCESS'
const FAILURE = 'FAILURE'

// Creates 3 action types for Async Requests
function createRequestTypes(base) {
    const res = {}
  ;[REQUEST, SUCCESS, FAILURE].forEach(type => (res[type] = `${base}_${type}`))
    return res
}

// Action Creator function to create actions
function action(type, payload = {}) {
    return { type, ...payload }
}

// Create Action Creators
// Such as login.request, login.success, login.failure
// NOTE: the parameter names becomes the keys on the final JSON output
function createAction(request_type, entityName) {
    return {
        request: params => action(request_type.REQUEST, params),
        success: (response, params) => action(request_type.SUCCESS, { response, entityName, ...params }),
        failure: (error, params) =>
            action(request_type.FAILURE, { error, entityName, ...params })
    }
}

// Create a set of CRUD actions
// including create, update, delete, get, and all
function CRUDActions(
    entity,
    cruds = ['create', 'update', 'delete', 'get', 'all', 'replace']
) {
    const actions = {}
    cruds.map(crud => {
        const request_type = crud.toUpperCase() + entity.toUpperCase()
        const action = createAction(createRequestTypes(request_type), entity)
        actions[crud] = action
    })
    return actions
}

/*
 * To define an API async action, you only need to write the following 2 lines.
 * It creates action types as LOGIN_REQUEST, LOGIN_SUCCESS and LOGIN_FAILURE
 * which can be accessed through actions.LOGIN.REQUEST, actions.LOGIN.SUCCESS
 * and actions.LOGIN.FAILURE
 * It also creates 3 action creators:
 * actions.loginAction.request(params),
 * actions.loginAction.response({response}),
 * actions.loginAction.failure({error})
 * These action creators are then used in Sagas to trigger actions.
 */
// Define Async Action Types
export const LOGIN = createRequestTypes('LOGIN')
// Define Login Action Creators
export const loginAction = createAction(LOGIN)

export const REGISTER = createRequestTypes('REGISTER')
export const registerAction = createAction(REGISTER)

export const CREATESPACE = createRequestTypes('CREATESPACE')
export const createSpaceAction = createAction(CREATESPACE)

export const RESETPASSWORDEMAIL = createRequestTypes('RESETPASSWORDEMAIL')
export const resetPasswordEmailAction = createAction(RESETPASSWORDEMAIL)

export const EMAILUNLOCKVERIFY = createRequestTypes('EMAILUNLOCKVERIFY')
export const emailUnlockVerifyAction = createAction(EMAILUNLOCKVERIFY)

export const GETSPACE = createRequestTypes('GETSPACE')
export const getSpaceAction = createAction(GETSPACE)

export const GET_RECORDS_BY_OPPORTUNITY = createRequestTypes(
    'GET_RECORDS_BY_OPPORTUNITY'
)
export const getRecordsByOpportunity = createAction(GET_RECORDS_BY_OPPORTUNITY)

// Members
export const memberActions = CRUDActions('member')

export const currentUserActions = CRUDActions('current_user')

export const membersActions = CRUDActions('members')

// USERS
export const usersActions = CRUDActions('users')

// org users
export const orgUserActions = CRUDActions('orgUsers')

// Locations
export const locationActions = CRUDActions('locations')

// LocationGroups
export const locationGroupActions = CRUDActions('locationsGroups')

// Location Members
export const GETLOCATIONMEMBERS = createRequestTypes('GETLOCATIONMEMBERS')
export const getLocationMembersAction = createAction(GETLOCATIONMEMBERS)

// space
export const spacesActions = CRUDActions('spaces')
export const getHomeInfo = createRequestTypes('GET_HOME_INFO')
export const getHomeInfoAction = createAction(getHomeInfo)

export const getCustomerIndex = createRequestTypes('GET_CUSTOMER_INDEX')
export const getCustomerIndexAction = createAction(getCustomerIndex)

// Departments
export const deptActions = CRUDActions('department')

// Organizations
export const orgActions = CRUDActions('organizations')

// Organization Annex
export const orgAnnexActions = CRUDActions('orgAnnex')

// Organization Notes
export const orgNoteActions = CRUDActions('orgnote')

// Areas
export const areaActions = CRUDActions('area')

// simpleAreas
export const simpleAreaActions = CRUDActions('simpleAreas')

// IntentAreas 客户意向办公室
export const intentAreaActions = CRUDActions('intentAreas')

/**
 * 设备
 * @type {[type]}
 */
export const devicesActions = CRUDActions('devices')

/**
 * 会议室
 * @type {[type]}
 */
export const meetingsActions = CRUDActions('meetings')

export const meetingLocationSettingActions = CRUDActions(
    'meetingLocationSetting'
)

/**
 * 会议室主题
 */
export const meetingThemesActions = CRUDActions('meetingThemes')

/**
 * 会议室设备（平板）
 */
export const meeetingDevicesActions = CRUDActions('meeetingDevices')

/**
 * 会议室设备信息
 */
export const meetingDeviceMessageActions = CRUDActions('deviceMessage')

/**
 * 入驻 / 退租 待办事项
 */
export const leaveEnterOptionActions = CRUDActions('leaveOrEnterOptions')

export const serializeLocationsActions = CRUDActions('serializeLocations', [
    'get'
])
export const serializeLocationsAction = CRUDActions('serializeLocations')
// 入驻方式变化
export const orgEnterSettingActions = CRUDActions('orgEnterSetting')

// Desks
export const deskActions = CRUDActions('desk')

// 服务商应用
export const appModulesActions = CRUDActions('appModules')

// Subscriptions
export const subscriptionActions = CRUDActions('contract')

// invoices
export const invoicesActions = CRUDActions('invoices')

// 融资情况
export const raisingsActions = CRUDActions('raisings')

// 预定会议室
export const reservationsActions = CRUDActions('reservations')

// 取消会议室
export const recordsActions = CRUDActions('meetingRoots')

// FAQ 文件列表
export const faqFilesActions = CRUDActions('faqFiles')

// FAQ 文章列表
export const topicsActions = CRUDActions('topics')

// FAQ 分类列表
export const sectionsActions = CRUDActions('sections')

/**
 * CRM
 */
export const opportunitiesActions = CRUDActions('opportunities')

export const leadsActions = CRUDActions('leads')

export const contactActions = CRUDActions('contacts')

export const customerRecordsActions = CRUDActions('crm_records')

export const customerRecordCommentsActions = CRUDActions('crm_record_comments')
// base
export const industriesActions = CRUDActions('industries')

// 商城
export const salesProductsActions = CRUDActions('salesProducts')

export const shopsActions = CRUDActions('shops')

export const productCategoriesActions = CRUDActions('productCategories')

export const productInventoriesActions = CRUDActions('productInventories')

/**
 * 自定义动态字段
 */
export const dynamicAttributesActions = CRUDActions('dynamicAttributes')

/**
 * crm 来源
 */
export const crmSourcesActions = CRUDActions('crm_sources')

/**
 * 门禁
 */
export const locksActions = CRUDActions('locks')

// tasks
export const tasksActions = CRUDActions('tasks')
export const taskFollowerActions = CRUDActions('taskFollower', [
    'create',
    'delete'
])
export const taskCommentsActions = CRUDActions('taskComments', ['create'])

// 门禁开门记录
export const lockEventsActions = CRUDActions('lockEvents')

// 门禁权限列表
export const lockPermissionsActions = CRUDActions('lockPermissions')

// 管理员对服务申请发表评论
export const serviceRequestCommentsActions = CRUDActions(
    'serviceRequestComments'
)

// subscriptions
export const subscriptionsAction = CRUDActions('subscriptions')

// Common
export const CITIES = createRequestTypes('CITIES')
export const getCitiesAction = createAction(CITIES)

export const TRADINGAREA = createRequestTypes('TRADINGAREA')
export const getTradingAreaAction = createAction(TRADINGAREA)

// 公告
export const announcementsActions = CRUDActions('announcements')

// 优惠券
export const couponsActions = CRUDActions('coupons')

// 广播消息
export const broadcastMessagesActions = CRUDActions('messageLists')

// 报表中心
export const reportActions = CRUDActions('report')

// Login and Logout
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'

export const API_CALL_FAILURE = 'API_CALL_FAILURE'
export const CLEAR_ERROR_STATE = 'CLEAR_ERROR_STATE'

export const loadingAction = CRUDActions('loading')

export const SUCCESS_STATE = 'SUCCESS_STATE'
export const CLEAR_SUCCESS_STATE = 'CLEAR_SUCCESS_STATE'

// 企业服务
export const serviceProvidersAction = CRUDActions('serviceProviders')

// 服务
export const servicesAction = CRUDActions('services')

// 服务请求订单
export const serviceRequestsAction = CRUDActions('serviceRequests')

// 空间设置
export const spacesSettingAction = CRUDActions('spacesSetting')

// 语言设置
export const spacesLangSettingAction = CRUDActions('spacesLangSetting')

// 分店设置
export const locationSettingAction = CRUDActions('locationSetting')

// 分店权限
export const locationRoleAction = CRUDActions('locationRole')

// 权限设置
export const spaceRoleAction = CRUDActions(`spaceRole`)

// 税率设置
export const taxesAction = CRUDActions('taxes')

// 收款配置
export const spacesSalesSettingAction = CRUDActions('spacesSalesSetting')

// 服务分类
export const serviceCategoriesAction = CRUDActions('serviceCategories')

// 平面图
export const floorplansAction = CRUDActions('floorplans')

// 分店楼层图
export const floorInfosAction = CRUDActions('floorInfos')

// 大楼
export const buildingInfoAction = CRUDActions('buildingInfo')

// 合同模板
export const subscriptionTempletesAction = CRUDActions('subscriptionTempletes')

// 模板占位符
export const subscriptionPlaceholder = CRUDActions('subscriptionPlaceholder')

// 会员等级Action
export const membershipsAction = CRUDActions('memberships')

// 会员制资源设置
export const membershipResourcesAction = CRUDActions('membershipResources')

// 积分计划列表
export const pointPlansAction = CRUDActions('pointPlans')

// 操作记录
export const auditLogsAction = CRUDActions('auditLogs')

// 活动模块
export const activitiesAction = CRUDActions('activities')

export const activitiesOrdersAction = CRUDActions('activitiesOrders')

export const activitiesCommentsAction = CRUDActions('activitiesComments')

// 新闻资讯模块
export const newsAction = CRUDActions('news')

// 分店打印机管理
export const printersAction = CRUDActions('printers')

// 项目组
export const locationGroupsAction = CRUDActions('locationGroups')

// 项目设置
export const locationGroupSettingAction = CRUDActions('locationGroupSetting')

// 项目权限
export const locationGroupRoleAction = CRUDActions('locationGroupRole')

// 储物柜
export const lockersAction = CRUDActions('lockers')

// 储物柜预定列表
export const lockerReservationsAction = CRUDActions('lockerReservations')

// 发票库
export const invoicingPoolsAction = CRUDActions('invoicingPools')

// 第三方商户
export const shopVendorsAction = CRUDActions('shopVendors')

// 群组列表
export const groupsAction = CRUDActions('groups')

// 圈子列表
export const pollsAction = CRUDActions('polls')

// 门禁组
export const lockGroupsAction = CRUDActions('lockGroups')

// 储物柜规格
export const lockerCategoriesAction = CRUDActions('lockerCategories')

// 平板
export const padAction = CRUDActions('pad')

// 数字屏幕模板
export const screenTemplateAction = CRUDActions('screenTemplate')

// 写字楼楼层
export const locationFloorsAction = CRUDActions('locationFloors')

export const additionalServicesAction = CRUDActions('additionalServices')

// 通知状态更新Actions
export const informActions = CRUDActions('inform', [
    'remove',
    'reset',
    'update'
])

export const successState = (success, params) =>
    action(SUCCESS_STATE, { success, ...params })
export const clearSuccessState = (success, params) =>
    action(CLEAR_SUCCESS_STATE, { success, ...params })

export const apiCallFailure = (error, params) =>
    action(API_CALL_FAILURE, { error, ...params })
export const clearErrorState = (error, params) =>
    action(CLEAR_ERROR_STATE, { error, ...params })

export function logout() {
    return {
        type: USER_LOGGED_OUT
    }
}
