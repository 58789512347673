import React from 'react'
import { Route } from 'react-router-dom'
import lazyLoad from 'utils/lazyLoad'

const Login = lazyLoad(() => import('../containers/assist/Login'))
const ResetPassword = lazyLoad(() => import('../containers/assist/ResetPassword'))
const ResetPasswordSuccess = lazyLoad(() => import('../containers/assist/ResetPasswordSuccess'))
const SelectOrg = lazyLoad(() => import('../containers/assist/SelectOrg'))
const SendResetPassword = lazyLoad(() => import('../containers/assist/SendResetPassword'))
const SendUnlockEmail = lazyLoad(() => import('../containers/assist/SendUnlockEmail'))
const UnlockUser = lazyLoad(() => import('../containers/assist/UnlockUser'))
const ValidateEmail = lazyLoad(() => import('../containers/assist/ValidateEmail'))
const VerifyState = lazyLoad(() => import('../containers/assist/VerifyState'))
const CorpService = lazyLoad(() => import('../containers/saas/invoices/Invoices'))
const AssistBox = lazyLoad(() => import('../containers/shuck/AssistBox'))
const SystemLock = lazyLoad(() => import('../containers/shuck/SystemLock'))
const WechatLogin = lazyLoad(() => import('../containers/login/WechatLogin'))

export default function () {
    return (
            <Route element={AssistBox}>
                <Route
                    path="tenant_login"
                    element={Login} />
                <Route
                    path="selectOrg"
                    element={SelectOrg} />
                <Route
                    path="send_unlock_email"
                    element={SendUnlockEmail} />
                <Route
                    path="unlock_user"
                    element={UnlockUser} />
                <Route
                    path="send_reset_password"
                    element={SendResetPassword} />
                <Route
                    path="reset_password"
                    element={ResetPassword} />
                <Route
                    path="reset_password_success"
                    element={ResetPasswordSuccess} />
                <Route
                    path="verify_state"
                    element={VerifyState} />
                <Route
                    path="validate_email"
                    element={ValidateEmail} />
                <Route
                    path="system_lock"
                    element={SystemLock} />
                <Route
                    path="corpSettingsInfo"
                    element={CorpService} />
            </Route>
    )
}
