import { lazy, Suspense, useState, useEffect } from 'react'
import { Skeleton } from 'antd'


function DelayedFallback() {
  const [show, setShow] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  if (show) {
    return (
      <div>
        <Skeleton
          active
          title
          paragraph={{ rows: 4 }}
          style={{ paddingTop: '5px' }} />
        <Skeleton
          active
          title
          paragraph={{ rows: 2 }}
          style={{ paddingTop: '5px' }} />
      </div>
    )
  }

  return null
}

function LazyWrapper(children) {
  return (
    <Suspense fallback={
      <DelayedFallback />
    }
    maxDuration={1000}>
      { children }
    </Suspense>
  )
}

/**
 * 包装 React.lazy函数
 * @param {*} fn 例子：() => import('../containers/HomePage')，写外面方便定位
 * @returns RN组件
 */
function lazyLoad(fn) {
  const Component = lazy(fn)
  return LazyWrapper(<Component />)
}

export default lazyLoad