import dayjs from 'dayjs'
const DATE_TIME_FORMATS = {
  'zh-CN': {
    date: 'YYYY-MM-DD',
    time: 'HH:mm',
    datetime: 'YYYY-MM-DD HH:mm',
    year: 'YYYY',
    day: 'dddd',
    'YYYY-MM': 'YYYY-MM',
    'YYYY-MM-DD': 'YYYY-MM-DD HH:mm',
    'HH:mm': 'HH:mm',
    'YYYY-MM-DD HH:mm': 'YYYY-MM-DD HH:mm',
    'MM-DD': 'MM月DD日',
    'DD': 'DD',
    'MM-DD HH:mm': 'MM月DD日 HH:mm',
  },
  'zh-TW': {
    date: 'YYYY-MM-DD',
    time: 'HH:mm',
    datetime: 'YYYY-MM-DD HH:mm',
    year: 'YYYY',
    day: 'dddd',
    'YYYY-MM': 'YYYY-MM',
    'YYYY-MM-DD': 'YYYY-MM-DD HH:mm',
    'HH:mm': 'HH:mm',
    'YYYY-MM-DD HH:mm': 'YYYY-MM-DD HH:mm',
    'MM-DD': 'MM月DD日',
    'DD': 'DD',
    'MM-DD HH:mm': 'MM月DD日 HH:mm',
  },
  'en-US': {
    date: 'MMM D, YYYY',
    time: 'h:mm A',
    datetime: 'MMM D, YYYY h:mm A',
    'YYYY-MM-DD': 'MMM D, YYYY',
    'HH:mm': 'h:mm A',
    'YYYY-MM-DD HH:mm': 'MMM D, YYYY h:mm A',
    year: 'YYYY',
    day: 'dddd',
    'DD': 'D',
    'YYYY-MM': 'YYYY-MM',
    'MM-DD': 'MMM D',
    'MM-DD HH:mm': 'MMM D h:mm A',
  },
}
export function colorRgba(color, x) {
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  let sColor = color
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    } // 处理六位的颜色值
    const sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt(`0x${sColor.slice(i, i + 2)}`))
    }
    if (x) {
      return `rgba(${sColorChange.join(',')},${x})`
    }
    return `rgb(${sColorChange.join(',')})`
  }
  return sColor
}

// 返回两个日期时间差（分钟）
export function dayjsDiff(startTime, endTime, unit = 'minutes') {
  let time = 0
  startTime = toDayjsObject(startTime)
  endTime = toDayjsObject(endTime)
  if (startTime && endTime) {
    time = endTime.diff(startTime, unit)
  }
  return time
}

const toDayjsObject = (time) => {
  try {
    if (typeof (time) === 'object' || typeof (time) === 'string') {
      return dayjs(time)
    } if (typeof (time) === 'number') {
      return dayjs.unix(time)
    }
    return null

  } catch (err) {
    return null
  }
}

// time可以为dayjs, Date, String  如果为string，必须带时区信息
export const formatMs = (time, format = 'datetime') => {
  if (!time) return ''
  const localEnv = 'zh-CN'
  format = DATE_TIME_FORMATS[localEnv][format] || format
  try {
    time = toDayjsObject(time)
    return time ? time.tz().format(format) : ''
  } catch (err) {
    return ''
  }
}

// 修改页面url,加时间戳重新刷新
export function reloadWithTimestamp(preUrl) {
  const currentUrl = preUrl || window.location.href
  let newUrl

  // 检测当前URL是否已有'?'，即是否存在其他参数
  if (currentUrl.indexOf('?') !== -1) {
    // 存在其他参数，检测是否已有时间戳参数
    const urlParts = currentUrl.split('?')
    const queryParams = new URLSearchParams(urlParts[1])

    // 如果存在时间戳参数，更新它的值
    if (queryParams.has('t')) {
      queryParams.set('t', new Date().getTime())
    } else {
      // 否则添加时间戳参数
      queryParams.append('t', new Date().getTime())
    }

    newUrl = `${urlParts[0]  }?${  queryParams.toString()}`
  } else {
    // 没有其他参数，直接添加时间戳参数
    newUrl = `${currentUrl  }?t=${  new Date().getTime()}`
  }

  // 使用新的URL重新加载页面
  window.location.href = newUrl
}

// easier format message
export function tr(messageId, params) {
  return messageId
  // HACK: 因为constants.jsx里面有tr调用，而react-intl-universal
  // 还没有init，所以会报warning。hack了一下直接跳过
  // if (!window.intl_inited) return messageId;
  // if (!messageId) return null;
  // return intl.get(messageId, params) || `tr(${messageId})`;
}

// momentSetTime 把time的date改成date的time
export const momentSetTime = (time, date) => {
  const momentDate = dayjs(date)
  return dayjs(time).set('hour', momentDate.hour()).set('minute', momentDate.minute()).set('second', momentDate.second())
}

export const imageResize = (url, width, height, resizing_type = 'fill') => {
  if (!url) {
    return
  }

  return url

  // face++图片不裁切，直接展示
  // if (url.indexOf('43.227.255.237') >= 0) {
  //   return url
  // }

  // return toImageProxy(url, {
  //   resizing_type,
  //   width,
  //   height
  // })
}

