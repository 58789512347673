import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoad from 'utils/lazyLoad'

const RechargeBalance = lazyLoad(() => import('../containers/saas/account/RechargeBalance'))
const Invoices = lazyLoad(() => import('../containers/saas/invoices/Invoices'))
const Orders = lazyLoad(() => import('../containers/saas/orders/Orders'))
const Subscriptions = lazyLoad(() => import('../containers/saas/subscriptions/Subscriptions'))
const IndexRedirectBox = lazyLoad(() => import('../containers/shuck/IndexRedirectBox'))

export default function () {
    return (
        <Route path="appstore" element={IndexRedirectBox}>
            <Route path="recharge" element={RechargeBalance} />
            <Route path="invoices" element={Invoices} />
            <Route path="orders" element={Orders} />
            <Route path="subscriptions" element={Subscriptions}/>
        </Route>
    )
}
