import * as _ from 'lodash'

function spaceRole(state = {}, action = {}) {
  const data = action.response

  if (action.type === 'GETSPACEROLE_SUCCESS') {
    return data.json
  }

  if (action.type === 'UPDATESPACEROLE_SUCCESS') {
    const roles = action.roles || [] // must pass in roles
    const locationId = action.location_id

    // key is name of role, like 'hr'
    // eslint-disable-next-line eqeqeq
    let newStateRoles =
      state.roles &&
      state.roles.filter(
        r => !(r.location_id == locationId && roles.includes(r.role))
      )
    let newState = {}
    newState.roles = (newStateRoles || []).concat(data.json.roles || [])
    newState.roles_settings = {
      ...state.roles_settings,
      ...data.json.roles_settings
    }
    return newState
  }

  return state
}

export default spaceRole
