import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoad from 'utils/lazyLoad'

const Invoice = lazyLoad(() => import('containers/invoices/Invoice'))
const Invoices = lazyLoad(() => import('containers/invoices/Invoices'))
const CostBooks = lazyLoad(() => import('../containers/invoices/CostBooks'))
const CostContracts = lazyLoad(() => import('../containers/invoices/CostContracts'))
const IncomeBooksLocation = lazyLoad(() => import('../containers/invoices/IncomeBooksLocation'))
const CostContractDetail = lazyLoad(() => import('../containers/organization/OrgContractDetail/CostContractDetail'))
const SubscriptionInfoBox = lazyLoad(() => import('../containers/organization/SubscriptionInfoBox'))
const SubscriptionInvoices = lazyLoad(() => import('../containers/organization/SubscriptionInvoices'))
const IndexRedirectBox = lazyLoad(() => import('../containers/shuck/IndexRedirectBox'))

export default function() {
    return (
        <Route path="operation" element={IndexRedirectBox}>
            <Route path="cost_books" element={CostBooks} />
            <Route path="cost_contracts" element={CostContracts} />
            <Route path="invoices" element={Invoices} />
            <Route path="invoices/:invoice_id" element={Invoice} />
            <Route
                path="cost_contracts/:cost_contracts_id"
                element={SubscriptionInfoBox}
            >
                <Route index element={CostContractDetail} />
                <Route path="info" element={CostContractDetail} />
                <Route path="invoices" element={SubscriptionInvoices} />
            </Route>
            <Route path="income_books" element={IncomeBooksLocation} />
        </Route>
    )
}
