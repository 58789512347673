export default function workbench(state = {}, action = {}) {
  if (action.type === 'UPDATE_WORKBENCH_BADGE') {
    return {
      count: action.count
    }
  }
  if (action.type === 'WORKBENCH_CONNECTED') {
    return {
      connected: action.connected
    }
  }
  return state
}
