// Location 菜单
// - name: 菜单名，会用intl.get国际化
// - value: 代表URL。如果有多个，可选择
// - roles: 可看见的角色名
// - childrens: 如果有，增加下拉分店列表,注意目前只支持分店列表dropdown
// - excludeSpaces: 用于对某些空间隐藏该菜单， spaceid 数组。例如[345, 455]。默认所有空间显示。可选
// - spaceTypes：对该spaceType显示，可选，默认只对正常空间显示 space_type === 'space'。目前支持：'thinkplus' - 联想空间（等于正常空间） space - 正常空间，temporary - 暂时性空间, 同时展示 = ['space', 'temporary', 'thinkplus']
// - locationTypes: 对该locationType显示
export const DEFAULT_ORGANIZANTION_MENU = [
  {
    name: 'organization.organization.menu.overview',
    value: ''
  },
  {
    name: 'organization.organization.menu.members',
    value: 'members'
  },
  {
    name: 'organization.organization.menu.meeting',
    value: 'meeting',
    submenu: [
      {
        name: 'shuck.meeting_box.menu.book',
        value: 'book'
      },
      {
        name: 'shuck.meeting_box.menu.manage',
        value: 'manage'
      },
      {
        name: 'shuck.meeting_box.menu.record',
        value: 'record'
      }
      // {
      //   name: 'shuck.meeting_box.menu.application',
      //   value: 'application'
      // },
      // {
      //   name: 'shuck.meeting_box.menu.cancel',
      //   value: 'cancel'
      // },
      // {
      //   name: 'shuck.meeting_box.menu.devices',
      //   value: 'devices'
      // }
      // {
      //   name: 'location_menu.meeting_settings',
      //   value: 'settings'
      // }
    ]
  },
  {
    name: 'location_menu.locks',
    value: 'locks',
    submenu: [
      {
        name: 'shuck.announcement_box.lock_record_name',
        value: ''
      },
      {
        name: 'shuck.announcement_box.lock_permission',
        value: 'lock_permission'
      }
    ]
  },
  {
    name: 'location_menu.supplier_management',
    value: 'supplier_management',
    //判断这个公司是否有供应商数据 有则展示
    showSupplierManagement: true,
    submenu: [
      {
        name: 'supplier_management.goods_classification',
        value: ''
      },
      {
        name: 'supplier_management.shelves_goods',
        value: 'shelves_goods'
      },
      {
        name: 'supplier_management.order_management',
        value: 'order_management'
      }
    ]
  },
  {
    name: 'location_menu.logistics_management',
    value: 'logistics_management',
    //判断这个公司是否有供应商数据 有则展示
    showSupplierManagement: true,
    submenu: [
      {
        name: 'logistics_management.consignment',
        value: ''
      }
      // {
      //   name: 'logistics_management.shelves_goods',
      //   value: 'shelves_goods'
      // },
      // {
      //   name: 'logistics_management.order_management',
      //   value: 'order_management'
      // }
    ]
  },
  {
    name: 'organization.organization.menu.subscriptions',
    value: 'contract'
  },
  {
    name: 'organization.organization.menu.payments',
    value: 'invoices'
  },
  {
    name: 'organization.organization.menu.orders',
    value: 'invoices/orders'
  },
  {
    name: 'organization.organization.menu.areas',
    value: 'areas'
  },

  {
    name: 'organization.organization.menu.point',
    value: 'point'
  },
  {
    name: 'organization.organization.menu.coupons',
    value: 'coupons'
  },
  {
    name: 'organization.organization.menu.annex',
    value: 'annex'
  }
]
