import * as _ from 'lodash'

function spaceSetting(state = {}, action = {}) {
  if (action.type === 'GETSPACESSETTING_SUCCESS') {
    return _.get(action, 'response.json', null)
  } else if (
    action.type === 'UPDATESPACESSETTING_SUCCESS' ||
    action.type === 'UPDATESPACESLANGSETTING_SUCCESS'
  ) {
    return { ...state, ..._.get(action, 'response.json', {}) }
  }

  return state
}

export default spaceSetting
