import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoad from 'utils/lazyLoad'

const CostType = lazyLoad(() => import('containers/invoices/CostType'))
const CycleFees = lazyLoad(() => import('containers/invoices/CycleFees'))
const Deposits = lazyLoad(() => import('containers/invoices/Deposits'))
const FashionableBox = lazyLoad(() => import('containers/invoices/FashionableBox'))
const FashionableDelayed = lazyLoad(() => import('containers/invoices/FashionableBox/FashionableDelayed'))
const FashionableExport = lazyLoad(() => import('containers/invoices/FashionableBox/FashionableExport'))
const FashionableRealtime = lazyLoad(() => import('containers/invoices/FashionableBox/FashionableRealtime'))
const FashionableRefund = lazyLoad(() => import('containers/invoices/FashionableBox/FashionableRefund'))
const FinanceSetBox = lazyLoad(() => import('containers/invoices/FinanceSetBox'))
const IncomeExpense = lazyLoad(() => import('containers/invoices/IncomeExpense'))
const IncomeExpenses = lazyLoad(() => import('containers/invoices/IncomeExpenses'))
const Invoice = lazyLoad(() => import('containers/invoices/Invoice'))
const InvoiceManagement = lazyLoad(() => import('containers/invoices/InvoiceManagement'))
const InvoicePayments = lazyLoad(() => import('containers/invoices/InvoicePayments'))
const InvoiceReceipts = lazyLoad(() => import('containers/invoices/InvoiceReceipts'))
const Invoices = lazyLoad(() => import('containers/invoices/Invoices'))
const InvoicesApplications = lazyLoad(() => import('containers/invoices/InvoicesApplications'))
const Orders = lazyLoad(() => import('containers/invoices/Orders'))
const StatisticInvoiceMonthly = lazyLoad(() => import('containers/invoices/StatisticInvoiceMonthly'))
const StatisticsInvoiceConfirmed = lazyLoad(() => import('containers/invoices/StatisticsInvoiceConfirmed'))
const Subscriptions = lazyLoad(() => import('containers/invoices/Subscriptions'))
const LocationSetFinance = lazyLoad(() => import('containers/spaces/LocationSetFinance'))
const LocationSetInvoice = lazyLoad(() => import('containers/spaces/LocationSetInvoice'))
const LocationSetPayment = lazyLoad(() => import('containers/spaces/LocationSetPayment'))

// 路由
import subscriptionRoutes from './subscriptionRoutes';

export default function() {
    return (
        <Route path="invoices">
            {/* <Route path="/" element={<Invoices />} /> */}
            <Route index element={Invoices} />
            <Route path="invoices" element={Invoices} />
            <Route path="subscriptions" element={Subscriptions} />
            <Route path="invoices_applications" element={InvoicesApplications} />
            <Route path="invoice_payments" element={InvoicePayments} />
            <Route path="invoices/:invoice_id" element={Invoice} />
            <Route path="orders" element={Orders} />
            <Route path="finance" element={FinanceSetBox}>
                <Route path="finance_setting" element={LocationSetFinance} />
                <Route path="payment_setting" element={LocationSetPayment} />
                <Route path="invoice_setting" element={LocationSetInvoice} />
            </Route>

            <Route path="invoice_management" element={InvoiceManagement} />
            <Route path="invoicing_receipts" element={InvoiceReceipts} />

            <Route path="cost_type" element={CostType} />
            <Route path="monthly_invoices" element={StatisticInvoiceMonthly} />
            <Route path="confirmed_invoices" element={StatisticsInvoiceConfirmed} />
            <Route path="deposits" element={Deposits} />
            <Route path="cycle_fees" element={CycleFees} />
            {/* <Route path="point_plans" element={<PointPlansLedgers />} /> */}
            <Route path="fashionable" element={FashionableBox}>
                <Route index element={FashionableRealtime} />
                <Route path="realtime_details" element={FashionableRealtime} />
                <Route path="refund" element={FashionableRefund} />
                <Route path="delayed_details" element={FashionableDelayed} />
                <Route path="export_details" element={FashionableExport} />
            </Route>
            <Route path="income_expenses" element={IncomeExpenses} />
            <Route path="income_expense/:flow_id" element={IncomeExpense} />
            {subscriptionRoutes()}
        </Route>
    )
}
