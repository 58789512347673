export const STATE_COLOR = {
  // success: 'success', //阶段性成功，已支付、已审批、已开票、已签约
  // processing: 'warning', //进行中的状态，审批中、签字中、退款中、待签字、待支付
  // warning: 'error', //警示作用，如：逾期、快到期
  // default: 'default', //阶段性结束或取消，已结束、已取消、已拒绝
  success: '#2BAE82', //阶段性成功，已支付、已审批、已开票、已签约
  green: '#2BAE82', //已收款,已支付,已提交
  orange: '#F37C51', //待支付
  processing: '#F37C51', //进行中的状态，审批中、签字中、退款中、待签字、待支付
  red: '#FC5A5A', //已逾期
  warning: '#FC5A5A', //警示作用，如：逾期、快到期
  error: '#FC5A5A', //已逾期
  gray: '#B5B5BE', //已关闭,已验收
  default: '#B5B5BE', //阶段性结束或取消，已结束、已取消、已拒绝
  pink: '#D64F98', //不受理
  pink_purple: '#BA55D3', //部分支付 部分退款
  purple: '#7667D2', //待分派
  blue: '#0090E9', //待退款
  yellow: '#D39A05' //处理中
}
