import * as _ from 'lodash'

function locationRole(state = {}, action = {}) {
  if (action.type === 'GETLOCATIONROLE_SUCCESS') {
    return action.response.json
  }

  if (action.type === 'UPDATELOCATIONROLE_SUCCESS') {
    return action.response.json
  }

  return state
}

export default locationRole
