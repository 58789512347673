import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoad from 'utils/lazyLoad'

// 活动
const ActivitiesInfo = lazyLoad(() => import('containers/activities/ActivitiesInfo'))
const ActivitiesList = lazyLoad(() => import('containers/activities/ActivitiesList'))
const ActivityForm = lazyLoad(() => import('containers/activities/ActivityForm'))

// 服务
const ServiceActivity = lazyLoad(() => import('containers/activities/ServiceActivity'))
const ServiceProviders = lazyLoad(() => import('containers/service/Providers'))
const ServiceCategories = lazyLoad(() => import('containers/service/ServiceCategories'))
const ServiceCooperativeResources = lazyLoad(() => import('containers/service/ServiceCooperativeResources'))
const ServiceLists = lazyLoad(() => import('containers/service/ServiceLists'))
const ServiceProvider = lazyLoad(() => import('containers/service/ServiceProvider'))
const ServiceRequestDetail = lazyLoad(() => import('containers/service/ServiceRequestDetail'))
const ServiceRequests = lazyLoad(() => import('containers/service/ServiceRequests'))

// 分店相关
const LocationAgreementSetting = lazyLoad(() => import('containers/spaces/LocationAgreementSetting'))
const LocationAreas = lazyLoad(() => import('containers/area/LocationAreas'))
const LocationGroupsManagement = lazyLoad(() => import('containers/spaces/LocationGroupsManagement'))
const Locations = lazyLoad(() => import('containers/spaces/Locations'))

// 优惠券
const CouponsDetails = lazyLoad(() => import('containers/coupons/CouponsDetails'))
const CouponsManageBox = lazyLoad(() => import('containers/coupons/CouponsManageBox'))
const CouponsRecord = lazyLoad(() => import('containers/coupons/CouponsRecord'))
const CouponsReleaseRecord = lazyLoad(() => import('containers/coupons/CouponsReleaseRecord'))
const CouponsUsageRecord = lazyLoad(() => import('containers/coupons/CouponsUsageRecord'))

// 空间设置
const SpaceSettingCurrency = lazyLoad(() => import('containers/spaces/setting/SpaceSettingCurrency'))
const SpaceSettingInvoice = lazyLoad(() => import('containers/spaces/setting/SpaceSettingInvoice'))
const SpaceSettingPayment = lazyLoad(() => import('containers/spaces/setting/SpaceSettingPayment'))
const SpaceSettingsSecurity = lazyLoad(() => import('containers/spaces/setting/SpaceSettingsSecurity'))
const SpaceSettingCrmAgentForm = lazyLoad(() => import('containers/spaces/setting/SpaceSettingCrmAgentForm'))
const SpaceSettingCrmAgentType = lazyLoad(() => import('containers/spaces/setting/SpaceSettingCrmAgentType'))
const SpacesSettingLocationGroups = lazyLoad(() => import('containers/spaces/setting/SpacesSettingLocationGroups'))
const SpacesSettingsBox = lazyLoad(() => import('containers/spaces/setting/SpacesSettingsBox'))
const SpacesSettingsInfo = lazyLoad(() => import('containers/spaces/setting/SpacesSettingsInfo'))

const Announcements = lazyLoad(() => import('containers/announcements/Announcements'))
const ApplicationManagements = lazyLoad(() => import('containers/ApplicationManagement/ApplicationManagements'))

const MarketingActivitiesRecord = lazyLoad(() => import('containers/coupons/MarketingActivitiesRecord'))
const MembershipsPointsRecord = lazyLoad(() => import('containers/memberships/MembershipsPointsRecord'))
const NewsDetail = lazyLoad(() => import('containers/news/NewsDetail'))
const NewsList = lazyLoad(() => import('containers/news/NewsList'))
const PostDetail = lazyLoad(() => import('containers/posts/PostDetail'))
const ReportIndex = lazyLoad(() => import('containers/report/ReportIndex'))
const ProviderDetail = lazyLoad(() => import('containers/service/ProviderDetail'))
const ProviderRequests = lazyLoad(() => import('containers/service/ProviderRequests'))
const ShoppingBox = lazyLoad(() => import('containers/shopping/ShoppingBox'))
const UserSettingBox = lazyLoad(() => import('containers/shuck/UserSettingBox'))
const AnnouncementSettings = lazyLoad(() => import('containers/spaces/AnnouncementSettings'))
const AssetAppraisal = lazyLoad(() => import('containers/spaces/AssetAppraisal'))
const ClientAnalysis = lazyLoad(() => import('containers/spaces/ClientAnalysis'))
const CrmChannelOperations = lazyLoad(() => import('containers/spaces/components/DataAnalysis/crm/CrmChannelOperations'))
const CrmCustomerOperation = lazyLoad(() => import('containers/spaces/components/DataAnalysis/crm/CrmCustomerOperation'))

const CrmDataAnalysisBox = lazyLoad(() => import('containers/spaces/CrmDataAnalysisBox'))
const Employees = lazyLoad(() => import('containers/spaces/Employees'))
const LeaseAnalysis = lazyLoad(() => import('containers/spaces/LeaseAnalysis'))

const ResourceAnalysis = lazyLoad(() => import('containers/spaces/ResourceAnalysis'))
const DynamicAttributeBox = lazyLoad(() => import('containers/spaces/setting/DynamicAttributeBox'))
const SpaceLogs = lazyLoad(() => import('containers/spaces/setting/SpaceLogs'))
const SpaceOperation = lazyLoad(() => import('containers/spaces/setting/SpaceOperation'))

const SpaceTags = lazyLoad(() => import('containers/spaces/setting/SpaceTags'))
const CloudBusinessServicesSettingBox = lazyLoad(() => import('containers/spaces/setting/templete/CloudBusinessServicesSettingBox'))
const FinanceSettingBox = lazyLoad(() => import('containers/spaces/setting/templete/FinanceSettingBox'))
const SpaceTask = lazyLoad(() => import('containers/spaces/SpaceTask'))
const TenantsAnalysis = lazyLoad(() => import('containers/spaces/TenantsAnalysis'))

const SurveyInfoBox = lazyLoad(() => import('containers/surveys/SurveyInfoBox'))
const SurveyInfoData = lazyLoad(() => import('containers/surveys/SurveyInfoData'))
const SurveyInfoReport = lazyLoad(() => import('containers/surveys/SurveyInfoReport'))
const PassWord = lazyLoad(() => import('containers/user/PassWord'))
const UserInfo = lazyLoad(() => import('containers/user/UserInfo'))

const Loading = lazyLoad(() => import('containers/assist/Loading'))
const CrmSettings = lazyLoad(() => import('containers/crm/CrmSettings'))
const AdminBox = lazyLoad(() => import('containers/shuck/AdminBox'))
const IndexRedirectBox = lazyLoad(() => import('containers/shuck/IndexRedirectBox'))
const SpaceBox = lazyLoad(() => import('containers/shuck/SpaceBox'))
const Employee = lazyLoad(() => import('containers/spaces/Employee'))
const CrmSettingBox = lazyLoad(() => import('containers/spaces/setting/CrmSettingBox'))

const WechatSettings = lazyLoad(() => import('containers/spaces/setting/WechatSettings'))
const WorkBench = lazyLoad(() => import('containers/workbench/WorkBench'))

const SpaceCustomTypes = lazyLoad(() => import('../containers/spaces/setting/SpaceCustomTypes'))
const MemberApplicationList = lazyLoad(() => import('containers/member/MemberApplicationList'))
const MemberList = lazyLoad(() => import('containers/member/MemberList'))
const RotationChartList = lazyLoad(() => import('containers/rotationChart/RotationChartList'))
const MemberBox = lazyLoad(() => import('containers/shuck/MemberBox'))
const ContentBox = lazyLoad(() => import('containers/shuck/ContentBox'))
const FaqIndex = lazyLoad(() => import('containers/faq/FaqIndex'))


import invoiceRoutes from './invoiceRoutes';
import taskRoutes from './taskRoutes';
import operationRoutes from './operationRoutes';

import organizationRoutes from './organizationRoutes';
import memberRoutes from './memberRoutes';
import shoppingRoutes from './shoppingRoutes';

import clientRoutes from './clientRoutes';
import crmRoutes from './crmRoutes';
import locationGroupRoutes from './locationGroupRoutes';

import locationRoutes from './locationRoutes';
import saasRoutes from './saasRoutes';
import assetResourceRoutes from './assetResourceRoutes'



// import AssetResourceSettingBox from '../containers/spaces/setting/AssetResourceSettingBox';

export default function(props) {
    return (
        <Route path="admin" element={AdminBox}>
            <Route path="loading" element={Loading} />
            <Route element={SpaceBox}>
                <Route path="member" element={MemberBox} >
                    <Route path="application" element={MemberApplicationList} />
                    <Route path="list" element={MemberList} />
                </Route>
                <Route path="content" element={ContentBox} >
                    <Route path="rotation" element={RotationChartList} />
                    <Route path="faq" element={FaqIndex} />
                </Route>
                <Route path="manage">
                    <Route path="locations" element={Locations} />
                    <Route path="groups" element={LocationGroupsManagement} />
                    {/* <Route path="groups" element={<LocationGroups />} /> */}
                    <Route path="areas" element={LocationAreas} />
                </Route>
                <Route path="work_bench" element={WorkBench} />

                {invoiceRoutes()}
                {taskRoutes()}
                {operationRoutes()}

                <Route path="service" element={IndexRedirectBox}>
                    <Route path="service_list" element={ServiceLists} />
                    <Route path="list" element={ServiceProviders} />
                    <Route path="categories" element={ServiceCategories} />

                    <Route path="requests" element={ServiceRequests} />
                    <Route path="requests/:request_id" element={ServiceRequestDetail} />

                    <Route path=":provider_id" element={ServiceProvider}>
                        <Route path="info" element={ProviderRequests} />
                        <Route path="detail" element={ProviderDetail} />
                    </Route>
                </Route>
                {/* 云商服务 */}
                <Route path="cloud_business_services" element={IndexRedirectBox}>
                    <Route path="list" element={ServiceLists} />
                    <Route path="activity" element={ServiceActivity} />
                    <Route path="rights_and_interests" element={ServiceLists} />
                    <Route
                        path="cooperative_resources"
                        element={ServiceCooperativeResources}
                    />
                    <Route path="cooperative_resources/:post_id" element={PostDetail} />
                    <Route path="activity/:activity_id/info" element={ActivitiesInfo} />
                </Route>

                {organizationRoutes()}
                {memberRoutes()}
                {shoppingRoutes()}
                <Route path="marketing" element={IndexRedirectBox}>
                    <Route path="announcements" element={Announcements} />
                    <Route path="coupons" element={CouponsManageBox}>
                        <Route path="coupons" element={CouponsRecord} />
                        <Route path="release_record" element={CouponsReleaseRecord} />
                        <Route path="usage_record" element={CouponsUsageRecord} />
                    </Route>
                    <Route
                        path="coupons/release_record/:coupon_id"
                        element={CouponsDetails}
                    />
                    <Route path="news" element={NewsList} />
                    <Route path="news/:news_id" element={NewsDetail} />
                    <Route path="activity/edit" element={ActivityForm} />
                    <Route path="activity/:activity_id/info" element={ActivitiesInfo} />
                    <Route path="service" element={ActivitiesList} />
                    <Route path="service/edit" element={ActivityForm} />
                    <Route path="service/:activity_id/info" element={ActivitiesInfo} />
                    {/* <Route path='surveys/:id' element={<SurveyInfo />} /> */}

                    <Route path="surveys/:survey_id" element={SurveyInfoBox}>
                        <Route index element={SurveyInfoReport} />
                        <Route path="report" element={SurveyInfoReport} />
                        <Route path="data" element={SurveyInfoData} />
                    </Route>
                </Route>
                <Route path="settings" element={IndexRedirectBox}>
                    <Route path="basic" element={SpacesSettingsBox}>
                        <Route index element={SpacesSettingsInfo} />
                        <Route path="info" element={SpacesSettingsInfo} />
                        <Route
                            path="location_groups"
                            element={SpacesSettingLocationGroups}
                        />
                        <Route path="currency" element={SpaceSettingCurrency} />
                        <Route path="invoice" element={SpaceSettingInvoice} />
                        <Route path="payment" element={SpaceSettingPayment} />
                        <Route path="wechat" element={WechatSettings} />
                        <Route path="release" element={AnnouncementSettings} />
                    </Route>
                    <Route path="security" element={SpaceSettingsSecurity} />
                    <Route path="crm_settings" element={CrmSettings} />
                    <Route
                        path="application_management"
                        element={ApplicationManagements}
                    />
                    {clientRoutes()}
                    <Route path="currency" element={SpaceSettingCurrency} />
                    <Route path="operation" element={SpaceOperation} />
                    <Route path="tags" element={SpaceTags} />
                    <Route path="logs" element={SpaceLogs} />
                    {/* <Route path="asset_resource" element={<AssetResourceSettingBox />} /> */}
                    <Route path="custom_field" element={SpaceCustomTypes} />
                    <Route path="employees" element={ShoppingBox}>
                        <Route index element={Employees} />
                        <Route path=":employeeId" element={Employee} />
                    </Route>
                    <Route path="agreement" element={LocationAgreementSetting} />
                    <Route path="finance" element={FinanceSettingBox} />
                    <Route
                        path="cloud_business_services_setting"
                        element={CloudBusinessServicesSettingBox}
                    />
                    <Route path="crm" element={CrmSettingBox}>
                        <Route path="crm_type" element={SpaceSettingCrmAgentType} />
                        <Route path="agent" element={SpaceSettingCrmAgentForm} />
                    </Route>
                    <Route path="dynamic_attribute" element={DynamicAttributeBox} />
                </Route>

                <Route path="card_coupon_marketing" element={IndexRedirectBox}>
                    <Route path="coupons" element={CouponsManageBox}>
                        <Route index element={CouponsRecord} />
                        <Route path="coupons" element={CouponsRecord} />
                        <Route
                            path="marketing_activities"
                            element={MarketingActivitiesRecord}
                        />
                        <Route path="release_record" element={CouponsReleaseRecord} />
                        <Route path="usage_record" element={CouponsUsageRecord} />
                    </Route>
                    <Route path="activity" element={ActivitiesList} />
                    <Route path="activity/edit" element={ActivityForm} />
                    <Route path="activity/:activity_id/info" element={ActivitiesInfo} />
                    <Route path="pointsRecord" element={MembershipsPointsRecord} />
                </Route>
                {crmRoutes()}
                <Route path="data_analysis" element={IndexRedirectBox}>
                    <Route path="asset_appraisal" element={AssetAppraisal} />
                    <Route path="resource_analysis" element={ResourceAnalysis} />
                    <Route path="lease_analysis" element={LeaseAnalysis} />
                    <Route path="tenants_analysis" element={TenantsAnalysis} />
                    <Route path="client_analysis" element={ClientAnalysis} />
                    <Route path="task" element={SpaceTask} />
                    <Route path="crm" element={CrmDataAnalysisBox}>
                        <Route index path="customer_operation" element={CrmCustomerOperation} />
                        <Route path="channel_operations" element={CrmChannelOperations} />
                    </Route>

                    <Route path="report" element={ReportIndex} />

                </Route>

                <Route path="user_setting" element={UserSettingBox}>
                    <Route path="info" element={UserInfo} />
                    {/* <Route element={<UserInfo />} /> */}
                    <Route path="password" element={PassWord} />
                </Route>

                {locationRoutes()}
                {locationGroupRoutes()}
                {saasRoutes()}
                {assetResourceRoutes()}
            </Route>
        </Route>
    )
}
