import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoad from 'utils/lazyLoad'

const CrmOpportunities = lazyLoad(() => import('containers/crm/CrmOpportunities'))
const CrmReported = lazyLoad(() => import('containers/crm/CrmReported'))
const CrmBox = lazyLoad(() => import('containers/shuck/CrmBox'))

// import CrmAgents from '../containers/crm/CrmAgents';
// import CrmAgentsApproval from '../containers/crm/CrmAgentsApproval';
// import CrmAgentsCompany from '../containers/crm/CrmAgentsCompany';

const CrmAgentsBox = lazyLoad(() => import('../containers/crm/CrmAgentsBox'))
const CrmCommissionsBox = lazyLoad(() => import('../containers/crm/CrmCommissionsBox'))
const CrmCustomers = lazyLoad(() => import('../containers/crm/CrmCustomers'))
const CrmCustomerBox = lazyLoad(() => import('../containers/crm/CrmCustomerBox'))

// import CrmOpportunityForm from 'containers/crm/CrmOpportunityForm'
// import CrmWinSubscriptions from 'containers/crm/CrmWinSubscriptions'
// import CrmAgent from '../containers/crm/CrmAgentbak'
// import CrmCommission from '../containers/crm/CrmCommission'
// import CrmCommissions from '../containers/crm/CrmCommissions'
// import CrmApprovalCommissions from '../containers/crm/CrmApprovalCommissions'
// import CrmSettings from '../containers/crm/CrmSettings'

export default function() {
    return (
        <Route path="crm" element={CrmBox}>
            {/* 客户全录 */}
            <Route path="customers" element={CrmCustomers} />
            <Route path="customers/:customer_id" element={CrmCustomerBox} />
            {/* 招商渠道 */}
            <Route path="agents" element={CrmAgentsBox}>
                {/* <Route  path="list" element={<CrmAgents />} />
        <Route path="approval" element={<CrmAgentsApproval />} />
        <Route path="company" element={<CrmAgentsCompany />} /> */}
            </Route>
            {/* 佣金管理 */}
            <Route path="commissions" element={CrmCommissionsBox} />
            {/* 商机管理 */}
            <Route path="opportunities" element={CrmOpportunities} />
            {/* 报备管理 */}
            <Route path="reported" element={CrmReported} />
            {/* <Route path="winsubscription" element={<CrmWinSubscriptions />} />
      <Route path="settings" element={<CrmSettings />} /> */}
        </Route>
    )
}
