export default function currentLocation(state = null, action = {}) {
  if (action.type === 'CURRENT_LOCATION') {
    return {
      id: action.currentLocation && action.currentLocation.id,
      mode: action.currentLocation
        ? 'location'
        : action.currentLocationGroup
        ? 'location_group'
        : 'space'
    }
  }
  return state
}
