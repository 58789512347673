import React from 'react'
import { Route } from 'react-router-dom'
import lazyLoad from 'utils/lazyLoad'

const ActivitiesBox = lazyLoad(() => import('containers/activities/ActivitiesBox'))
const ActivitiesTaskBox = lazyLoad(() => import('containers/activities/ActivitiesTaskBox'))
const UserTaskBox = lazyLoad(() => import('containers/activities/UserTaskBox'))
const ActivitiesList = lazyLoad(() => import('containers/activities/ActivitiesList'))
const BroadcastMessages = lazyLoad(() => import('containers/announcements/BroadcastMessages'))
const FaqIndex = lazyLoad(() => import('containers/faq/FaqIndex'))
const LocationMemberInfoBox = lazyLoad(() => import('containers/members/LocationMemberInfoBox'))
const MemberInfo = lazyLoad(() => import('containers/members/MemberInfo'))
const MembersCoupons = lazyLoad(() => import('containers/members/MembersCoupons'))
const MembersLockPermission = lazyLoad(() => import('containers/members/MembersLockPermission'))
const MembersLocksRecord = lazyLoad(() => import('containers/members/MembersLocksRecord'))
const MembersMeetRecord = lazyLoad(() => import('containers/members/MembersMeetRecord'))
const MembersPermissionRecord = lazyLoad(() => import('containers/members/MembersPermissionRecord'))
const MembersPoint = lazyLoad(() => import('containers/members/MembersPoint'))
const MembershipsMembers = lazyLoad(() => import('containers/memberships/MembershipsMembers'))
const MembershipsPointsRecord = lazyLoad(() => import('containers/memberships/MembershipsPointsRecord'))
const NonResidentMembershipsMembers = lazyLoad(() => import('containers/memberships/NonResidentMembershipsMembers'))

const OrgMemberForm = lazyLoad(() => import('containers/organization/orgMemberForm/OrgMemberForm'))
const GroupDetail = lazyLoad(() => import('containers/posts/GroupDetail'))
const GroupMembers = lazyLoad(() => import('containers/posts/GroupMembers'))
const Groups = lazyLoad(() => import('containers/posts/Groups'))
const Polls = lazyLoad(() => import('containers/posts/Polls'))
const PostBox = lazyLoad(() => import('containers/posts/PostBox'))
const PostDetail = lazyLoad(() => import('containers/posts/PostDetail'))
const PostList = lazyLoad(() => import('containers/posts/PostList'))
const SurveyInfoBox = lazyLoad(() => import('containers/surveys/SurveyInfoBox'))
const SurveyInfoData = lazyLoad(() => import('containers/surveys/SurveyInfoData'))
const SurveyInfoReport = lazyLoad(() => import('containers/surveys/SurveyInfoReport'))
const SurveyList = lazyLoad(() => import('containers/surveys/SurveyList'))

const MemberOrders = lazyLoad(() => import('../containers/members/MemberOrders'))
const IndexRedirectBox = lazyLoad(() => import('../containers/shuck/IndexRedirectBox'))

export default function() {
  return (
    <Route path="memberships"
      element={IndexRedirectBox}>
      <Route path="members"
        element={MembershipsMembers} />
      <Route
        path="non_resident_members"
        element={NonResidentMembershipsMembers}
      />
      <Route path="members/new_member"
        element={OrgMemberForm} />
      <Route path="faq"
        element={FaqIndex} />
      <Route path="members/:member_id"
        element={LocationMemberInfoBox}>
        <Route index
          element={MemberInfo} />
        <Route path="info"
          element={MemberInfo} />
        <Route path="lock_permissions"
          element={MembersLockPermission} />
        <Route path="permissions_record"
          element={MembersPermissionRecord} />
        <Route path="lock_events"
          element={MembersLocksRecord} />
        <Route path="meeting"
          element={MembersMeetRecord} />
        <Route path="point"
          element={MembersPoint} />
        <Route path="coupons"
          element={MembersCoupons} />
        <Route path="orders"
          element={MemberOrders} />
      </Route>
      <Route path="messages"
        element={BroadcastMessages} />
      <Route path="pointsRecord"
        element={MembershipsPointsRecord} />
      <Route path="activity"
        element={ActivitiesList} />
      <Route path="activity_box"
        element={ActivitiesBox} />
      <Route path="activity_task"
        element={ActivitiesTaskBox} />
      <Route path="user_task"
        element={UserTaskBox} />
      <Route path="surveys"
        element={SurveyList} />
      <Route path="surveys/:survey_id"
        element={SurveyInfoBox}>
        <Route index
          element={SurveyInfoReport} />
        <Route path="report"
          element={SurveyInfoReport} />
        <Route path="data"
          element={SurveyInfoData} />
      </Route>
      <Route path="posts"
        element={PostBox}>
        <Route index
          element={PostList} />
        <Route  path="normal"
          element={PostList} />
        <Route path="demand"
          element={PostList} />
        <Route path="groups"
          element={Groups} />
        <Route path="groups/:group_id"
          element={GroupDetail}>
          <Route path="posts"
            element={PostList} />
          <Route path="members"
            element={GroupMembers} />
        </Route>
        <Route path="polls"
          element={Polls} />
        <Route path=":post_id"
          element={PostDetail} />
      </Route>
    </Route>
  )
}
