import * as _ from 'lodash'

// customizer handles merging
export function mergeArray(objValue, srcValue) {
  if (Array.isArray(objValue) && srcValue) {
    return _.union(_.isArray(srcValue) ? srcValue : [srcValue], objValue)
  }
}

export function omitArray(objValue, srcValue) {
  if (Array.isArray(objValue) && srcValue) {
    return _.pull(objValue, srcValue)
  }
}

export function unsetObject(obj, path) {
  let t = Object.assign({}, obj)
  if (_.unset(t, path)) {
    return t
  }
  return obj
}

export function unsetBatchObject(obj, key, ids) {
  let t = Object.assign({}, obj)
  ids &&
    ids.map(id => {
      let path = `${key}.${id}`
      _.unset(t, path)
    })
  return t
}

export function updateArray(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return srcValue
  }
}

export function replaceArray(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return srcValue
  }
}

// arrayify ensures an array of item
const arrayify = item => {
  if (!item) {
    return []
  } else if (Array.isArray(item)) {
    return item
  } else {
    return [item]
  }
}

// mergeState merges json responses result with redux tree
// must pass in the objectId and key
// where objectId is the id on the state tree, such as state.locations.[id]
// key is the subkey to store the result, such as state.locations.[id].[key]
// e.g. state.locations.1.area_ids: 1 === objectId, area_ids === key
const mergeState = (state, action, objectId, key) => {
  let result = get(action, ['response', 'json', 'result'])
  if (!result) {
    return state
  }
  return _.mergeWith(
    {},
    state,
    { [objectId]: { [key]: arrayify(result) } },
    mergeArray
  )
}

// omitState removes json responses result from redux tree
// require the action.id is set
const omitState = (state, action, objectId, key) => {
  return _.mergeWith({}, state, { [objectId]: { [key]: action.id } }, omitArray)
}
// Get object as associated array from Redux entities tree.
// json - the object usually returned from API and through nomralizr
// key - the type of the object, such as 'organizations', locations
const getEntitiesJson = (json, key) => _.get(json, ['json', 'entities', key])

// Get object as an array from Redux entities tree.
// json - the object usually returned from API and through nomralizr
// key - the type of the object, such as 'organizations', locations
const getEntitiesJsonArray = (json, key) => {
  let entities = getEntitiesJson(json, key)
  if (!entities) {
    return []
  }
  let result = _.get(json, ['json', 'result'])
  if (result) {
    if (Array.isArray(result)) {
      return result.map(id => entities[id])
    } else {
      return entities[result]
    }
  }
  return Object.values(entities)
}

const mergeKayState = (state, action) => {
  let result = get(action, ['response', 'json', 'result'])
  result = result || get(action, ['response', 'result'])

  if (!result || !action.key) {
    return state
  }
  return _.mergeWith({}, state, { [action.key]: result }, mergeArray)
}

const omitKayState = (state, action) => {
  if (!action.id || !action.key) {
    return state
  }
  return _.mergeWith({}, state, { [action.key]: action.id }, omitArray)
}

const get = _.get

export {
  get,
  getEntitiesJson,
  getEntitiesJsonArray,
  mergeState,
  omitState,
  mergeKayState,
  omitKayState
}
