// Space 菜单
// - name: 菜单名，会用intl.get国际化
// - value: 代表URL。如果有多个，可选择
// - showNum: 展示徽标
// - roles: 可看见的角色名
// - excludeSpaces: 用于对某些空间隐藏该菜单， spaceid 数组。例如[345, 455]。默认所有空间显示。可选
// - spaceTypes：对该spaceType显示，可选，默认只对正常空间显示 space_type === 'space'。目前支持： space - 正常空间，temporary - 暂时性空间, 同时展示 = ['space', 'temporary']
// - features：菜单可见所需订阅
export const DEFAULT_SPACE_MENU = [
  {
    name: 'shuck.admin_box.default_top_menu.manage',
    value: 'manage',
    // roles: ['admin', 'crm_admin']
    features: ['asset_management', 'custom'],
    submenu: [
      {
        name: 'shuck.admin_box.default_top_menu.manage_locations',
        value: 'locations'
        //features: ['asset_management', 'custom']
      },
      {
        name: 'shuck.admin_box.default_top_menu.manage_groups',
        value: 'groups'
        // roles: ['admin', 'location_manager', 'location_operator']
        //features: ['asset_management', 'custom']
      },
      {
        name: 'shuck.admin_box.default_top_menu.manage_areas',
        value: 'areas'
        //features: ['asset_management', 'custom']
      }
    ]
  },

  {
    name: 'shuck.admin_box.default_top_menu.crm',
    value: 'crm',
    roles: ['admin', 'crm', 'crm_admin'],
    features: ['asset_management', 'custom'],
    submenu: [
      // {
      //   name: 'shuck.announcement_box.crm_home',
      //   value: 'home'
      // },
      // {
      //   name: 'shuck.announcement_box.crm_leads',
      //   value: 'leads'
      //   //features: ['custom']
      // },
      {
        name: 'shuck.announcement_box.crm_customers',
        value: 'customers'
        //features: ['asset_management', 'custom']
      },
      {
        name: 'shuck.announcement_box.crm_opportunities',
        value: 'opportunities'
        //features: ['asset_management', 'custom']
      },

      {
        name: 'shuck.announcement_box.reported',
        value: 'reported'
        //features: ['custom']
      },
      {
        name: 'shuck.announcement_box.crm_agents',
        value: 'agents'
        //features: ['asset_management', 'custom']
      },
      {
        name: 'shuck.announcement_box.crm_opportunity_commissions',
        value: 'commissions'
        //features: ['custom']
      }
      // {
      //   name: 'shuck.announcement_box.crm_win_sub',
      //   value: 'winsubscription'
      //   //features: ['custom']
      // }
    ]
  },
  {
    name: 'shuck.admin_box.default_top_menu.members_ships',
    value: 'memberships',
    roles: ['admin', 'membership', 'content', 'point_recharge'],
    features: ['custom'],
    submenu: [
      {
        name: 'memberships.memberships_manage.menu.members',
        value: 'members',
        roles: ['admin', 'membership', 'point_recharge']
        //features: ['custom']
      },

      {
        name: 'memberships.memberships_manage.menu.posts',
        value: 'posts',
        roles: ['admin', 'membership', 'content', 'point_recharge']
        //features: ['custom']
      },

      {
        name: 'memberships.memberships_manage.menu.activities',
        value: 'activity_box',
        roles: ['admin', 'membership', 'content', 'activity']
        //features: ['custom']
      },
      {
        name: 'memberships.memberships_manage.menu.activity_task',
        value: 'activity_task',
        roles: ['admin', 'membership', 'content', 'activity']
        //features: ['custom']
      },
      {
        name: 'memberships.memberships_manage.menu.user_task',
        value: 'user_task',
        roles: ['admin', 'membership', 'content', 'activity']
        //features: ['custom']
      },
      {
        name: 'shuck.location_box.default_location_menu.surveys',
        value: 'surveys'
        //features: ['custom']
      },
      {
        name: 'shuck.admin_box.default_top_menu.app_manage',
        value: 'faq',
        roles: ['admin', 'faq']
        //features: ['custom']
      }
    ]
  },
  {
    name: 'memberships.memberships_manage.menu.message_management',
    value: 'marketing',
    roles: ['admin', 'membership', 'content', 'activity'],
    features: ['custom'],
    submenu: [
      // {
      //   name: 'memberships.memberships_manage.menu.coupons',
      //   value: 'coupons',
      //   roles: ['admin', 'membership'],
      // },
      // {
      //   name: 'memberships.memberships_manage.menu.activities',
      //   value: 'activity',
      //   roles: ['admin', 'membership', 'content', 'activity']
      //   //features: ['custom']
      // },
      // {
      //   name: 'shuck.location_box.default_location_menu.service',
      //   value: 'service',
      //   roles: ['admin', 'membership', 'content', 'activity'],
      //   showSpace: [364, 50495]
      //   //features: ['custom']
      // },
      {
        name: 'memberships.memberships_manage.menu.announcement',
        value: 'announcements',
        roles: ['admin', 'membership', 'content']
        //features: ['custom']
      },
      {
        name: 'memberships.memberships_manage.menu.news',
        value: 'news',
        roles: ['admin', 'membership', 'content']
        //features: ['custom']
      }
    ]
  },

  {
    name: 'location_menu.marketing',
    value: 'card_coupon_marketing',
    roles: ['admin', 'membership', 'content', 'activity'],
    features: ['custom'],
    submenu: [
      {
        name: 'memberships.memberships_manage.menu.coupons',
        value: 'coupons',
        roles: ['admin', 'membership']
      },
      // {
      //   name: 'memberships.memberships_manage.menu.activities',
      //   value: 'activity',
      //   roles: ['admin', 'membership', 'content', 'activity']
      //   //features: ['custom']
      // },
      {
        name: 'spaces.space_setting_point.name',
        value: 'pointsRecord',
        roles: ['admin', 'membership', 'point_recharge']
        //features: ['custom']
      }
    ]
  },
  // {
  //   name: 'shuck.admin_box.default_top_menu.dashboard',
  //   value: 'dashboard', // URL: /admin/dashboard
  //   roles: [
  //     'admin',
  //     'location_manager',
  //     'location_operator',
  //     'observer',
  //     'statistic'
  //   ],
  //   submenu: [
  //     {
  //       name: 'organization.organization.menu.overview',
  //       value: 'overall'
  //     },
  //     {
  //       name: 'statistic.statistic_desk_enter.chart_yaxis_text',
  //       value: 'occupancy'
  //     },
  //     {
  //       name: 'shuck.dashboard.menu.companyStatistics',
  //       value: 'company_statistics'
  //     },
  //     {
  //       name: 'crm.crm_win_subscriptions.thead.deposit',
  //       value: 'margin'
  //     },
  //     // {
  //     //   name: 'crm.opportunities.string.subscription',
  //     //   value: 'contract'
  //     // },
  //     {
  //       name: 'organization.organization.menu.meeting',
  //       value: 'meetingRoom'
  //     },
  //     {
  //       name: 'shuck.location_box.default_location_menu.members',
  //       value: 'membersStatistics'
  //     },
  //     {
  //       name: 'location_menu.visit',
  //       value: 'visitors'
  //     },
  //     {
  //       name: 'tasks.tasks_list.task_types.repair',
  //       value: 'repair'
  //     },
  //     {
  //       name: 'shuck.task_box.clean.document_title',
  //       value: 'cleaning'
  //     },
  //     {
  //       name: 'shuck.dashboard.menu.complaints',
  //       value: 'complaints'
  //     }
  //   ]
  // },
  {
    name: 'shuck.admin_box.default_top_menu.invoices',
    value: 'invoices',
    roles: [
      'admin',
      'finance',
      'contract',
      'contract_terminate',
      'abnormal_subscription',
      'invoice_check',
      'invoice_confirm',
      'invoice_check_notify',
      'invoice_refund',
      'crm_admin',
      'crm'
    ],
    excludeSpaces:[135638],
    //features: ['asset_management', 'custom'],
    submenu: [
      {
        name: 'shuck.announcement_box.invoice_one',
        value: 'subscriptions'
        //features: ['asset_management', 'custom']
      },
      {
        name: 'shuck.announcement_box.invoice_list',
        value: 'invoices'
        //features: ['asset_management', 'custom']
      },
      {
        name: 'shuck.announcement_box.invoice_orders',
        value: 'orders',
        features: ['custom']
      },

      {
        name: 'shuck.announcement_box.income_expenses',
        value: 'income_expenses'
        // features: ['asset_management', 'custom']
      },
      // {
      //   name: 'shuck.announcement_box.invoice_payments',
      //   value: 'invoice_payments'
      //   //features: ['asset_management', 'custom']
      // },
      //发票管理隐藏
      {
        name: 'location_menu.invoice_management',
        value: 'invoice_management'
        // isShow: false
        //features: ['custom']
      },
      {
        name: 'location_menu.invoicing_receipts',
        value: 'invoicing_receipts'
        // isShow: false
        //features: ['custom']
      },
      {
        name: 'shuck.announcement_box.invoice_deposit',
        value: 'deposits'
        //features: ['custom']
      },
      // {
      //   name: 'shuck.announcement_box.invoice_application',
      //   value: 'invoices_applications',
      //   isShow: true
      // },
      // {
      //   name: 'shuck.announcement_box.invoice_pool',
      //   value: 'invoicing_pool'
      // },
      {
        name: 'shuck.announcement_box.monthly_invoices',
        value: 'monthly_invoices',
        features: ['custom']
      },
      {
        name: 'shuck.announcement_box.confirmed_invoices',
        value: 'confirmed_invoices',
        features: ['custom']
      }
    ]
  },
  {
    name: 'shuck.admin_box.default_top_menu.tasks',
    value: 'tasks',
    showNum: true,
    roles: [
      'admin',
      'task_admin',
      'repair_manager',
      'repair',
      'clean_manager',
      'clean',
      // 'custom_service_manager',
      // 'custom_service',
      'task_complaint_manager',
      'task_complaint',
      'task_suggest_manager',
      'task_suggest',
      'feedback_manager',
      'feedback'
    ],
    features: ['custom'],
    type: 'repair',
    submenu: [
      {
        name: 'shuck.announcement_box.tasks_repair',
        value: 'repair',
        showNum: true,

        roles: ['task_admin', 'repair', 'repair_manager', 'admin']
        //features: ['custom']
      },
      {
        name: 'shuck.announcement_box.tasks_cleaner',
        value: 'clean',
        showNum: true,
        roles: ['task_admin', 'clean', 'clean_manager', 'admin']
        //features: ['custom']
      },
      // {
      //   name: 'shuck.announcement_box.tasks_custom_service',
      //   value: 'custom_service',
      //    showNum: true,
      //   roles: ['custom_service', 'custom_service_manager', 'admin']
      // },
      // {
      //   name: 'shuck.announcement_box.tasks_feedback',
      //   value: 'feedback',
      //   showNum: true,
      //   roles: [
      //     'feedback',
      //     'feedback_manager',
      //     'admin',
      //     'task_complaint',
      //     'task_admin',
      //     'task_complaint_manager',
      //     'task_suggest',
      //     'task_suggest_manager'
      //   ]
      // },
      {
        name: 'shuck.announcement_box.complaint',
        value: 'complaint',
        showNum: true,
        roles: [
          'feedback',
          'feedback_manager',
          'admin',
          'task_complaint',
          'task_admin',
          'task_complaint_manager',
          'task_suggest',
          'task_suggest_manager'
        ]
      },
      {
        name: 'shuck.announcement_box.suggest',
        value: 'suggest',
        showNum: true,
        roles: [
          'feedback',
          'feedback_manager',
          'admin',
          'task_complaint',
          'task_admin',
          'task_complaint_manager',
          'task_suggest',
          'task_suggest_manager'
        ]
      },
      {
        name: 'shuck.announcement_box.return_visit',
        value: 'return_visit',
        roles: ['task_admin', 'admin']
      },
      {
        name: 'shuck.announcement_box.service_work_order',
        value: 'service_work_order',
        showNum: true,
        roles: ['task_admin', 'admin']
      },
      {
        name: 'shuck.announcement_box.task_type',
        value: 'task_type',
        roles: [
          'admin',
          'task_admin',
          'repair_manager',
          'clean_manager',
          'task_complaint_manager',
          'task_suggest_manager',
          'feedback_manager'
        ]
      }
    ]
  },

  {
    name: 'shuck.admin_box.default_top_menu.enterprise',
    value: 'service',
    roles: ['admin', 'enterprise', 'clound_business_service_manager'],
    features: ['custom'],
    submenu: [
      {
        name: 'const.post.category.list',
        value: 'service_list'
        //features: ['custom']
      },
      {
        name: 'shuck.announcement_box.service_provider',
        value: 'list'
        //features: ['custom']
      },
      {
        name: 'shuck.announcement_box.service_provider.set',
        value: 'categories'
        //features: ['custom']
      }
      // {
      //   name: 'shuck.announcement_box.service_request',
      //   value: 'request'
      // }
    ]
  },
  //云商服务
  {
    name: 'shuck.admin_box.default_top_menu.cloud_business_services',
    value: 'cloud_business_services',
    roles: ['admin', 'enterprise', 'clound_business_service_manager'],
    features: ['custom'],
    excludeSpaces:[135638],
    submenu: [
      {
        name: 'shuck.admin_box.default_top_menu.cloud_business_services',
        value: 'list'
        //features: ['custom']
      },
      {
        name: 'shuck.admin_box.default_top_menu.activity',
        value: 'activity'
        //features: ['custom']
      },
      {
        name: 'shuck.admin_box.default_top_menu.rights_and_interests',
        value: 'rights_and_interests'
        //features: ['custom']
      },
      {
        name: 'shuck.admin_box.default_top_menu.cooperative_resources',
        value: 'cooperative_resources'
        //features: ['custom']
      }
    ]
  },
  // 资产耗材管理
  // {
  //   name: 'shuck.admin_box.default_top_menu.asset_resources',
  //   value: 'asset_resources',
  //   roles: ['admin', 'enterprise'],
  //   features: ['custom'],
  //   excludeSpaces:[135638],
  //   submenu: [
  //     {
  //       name: 'shuck.admin_box.default_top_menu.asset_resources_list',
  //       value: ''
  //     },
  //     {
  //       name: 'shuck.admin_box.default_top_menu.asset_resources_item',
  //       value: 'asset_resources_item'
  //     },
  //     {
  //       name: 'shuck.admin_box.default_top_menu.asset_resources_plan',
  //       value: 'asset_resources_plan'
  //     },
  //     {
  //       name: 'shuck.admin_box.default_top_menu.asset_resources_log',
  //       value: 'asset_resources_log'
  //     }
  //   ]
  // },
  // 数据分析
  {
    name: 'location.data_analysis',
    value: 'data_analysis',
    roles: [
      'admin',
      'location_manager',
      'location_operator',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'statistic'
    ],
    submenu: [
      {
        name: 'organization.organization.menu.lease_analysis',
        value: 'lease_analysis'
      },
      {
        name: 'organization.organization.menu.resource_analysis',
        value: 'resource_analysis'
      },
      {
        name: 'organization.organization.menu.tenants_analysis',
        value: 'tenants_analysis'
      },
      {
        name: 'organization.organization.menu.asset_appraisal',
        value: 'asset_appraisal'
      },
      {
        name: 'organization.organization.menu.task',
        value: 'task'
      },
      {
        name: 'organization.organization.menu.crm',
        value: 'crm'
      },
      {
        name: 'shuck.admin_box.default_top_menu.report',
        value: 'report'
        //features: ['custom']
        // roles: ['admin', 'location_manager', 'location_operator', 'observer', 'location_finance', 'location_repair', 'location_clean', 'location_feedback', 'location_subscription', 'sales', 'point_recharge']
      }
    ]
  },
  {
    name: 'shuck.admin_box.default_top_menu.shopping_management',
    value: 'shopping',
    roles: ['admin', 'shop_manager', 'shop'],
    features: ['custom'],
    submenu: [
      {
        name: 'shuck.admin_box.default_top_menu.shopping_list',
        value: 'list'
      },
      {
        name: 'shopping.orders',
        value: 'orders'
      },
      {
        name: 'shopping.activity',
        value: 'activity'
      }
    ]
  },
  {
    name: 'shuck.admin_box.default_top_menu.operation',
    value: 'operation',
    roles: ['admin'],
    features: ['custom', 'asset_management'],
    excludeSpaces:[135638],
    submenu: [
      {
        name: 'shuck.announcement_box.cost_books',
        value: 'cost_books'
      },
      {
        name: 'shuck.announcement_box.cost_contract',
        value: 'cost_contracts'
      },
      {
        name: 'shuck.announcement_box.invoice_list',
        value: 'invoices'
      },
      {
        name: 'shuck.announcement_box.income_books',
        value: 'income_books'
      }
    ]
  },
  {
    name: 'shuck.admin_box.default_top_menu.app_store',
    value: 'appstore',
    roles: ['admin'],
    //features: ['asset_management'],
    excludeSpaces:[135638],
    submenu: [
      {
        name: 'shuck.admin_box.default_top_menu.my_account',
        value: 'recharge',
        roles: ['admin']
        //features: ['asset_management']
      },
      {
        name: 'shuck.admin_box.default_top_menu.my_subscribe',
        value: 'subscriptions',
        roles: ['admin']
        //features: ['asset_management']
      },
      {
        name: 'shuck.admin_box.default_top_menu.order_management',
        value: 'orders',
        roles: ['admin']
        //features: ['asset_management']
      },
      {
        name: 'shuck.admin_box.default_top_menu.corp_info',
        value: 'invoices', // 发票
        roles: ['admin']
        //features: ['asset_management']
      }
    ]
  },
  {
    name: 'shuck.admin_box.default_top_menu.setting',
    value: 'settings',
    roles: [
      'admin',
      'shop_manager',
      'shop',
      'hr',
      'app',

      'finance',
      'contract',
      'contract_terminate',
      'abnormal_subscription',
      'invoice_check',
      'invoice_confirm',
      'invoice_check_notify',
      'invoice_refund',
      'crm_admin',
      'crm'
    ],
    //features: ['asset_management', 'custom'],
    submenu: [
      {
        name: 'shuck.admin_box.default_top_menu.space_setting',
        value: 'basic',
        roles: ['admin', 'hr']
        //features: ['asset_management', 'custom']
      },
      {
        name: 'shuck.admin_box.default_top_menu.employee',
        value: 'employees',
        roles: ['admin', 'hr']
        //features: ['asset_management', 'custom']
      },
      {
        name: 'shuck.spaces_setting_box.menu.security',
        value: 'security',
        roles: ['admin', 'hr']
        //features: ['asset_management', 'custom']
      },
      // {
      //   name: 'shuck.spaces_setting_box.menu.crm',
      //   value: 'crm_settings',
      //   features: ['custom', 'crm'] // 招商设置，资管云不显示
      // },
      {
        name: 'location_menu.finance',
        value: 'finance',
        roles: [
          'admin',
          'finance',
          'finance',
          'contract',
          'contract_terminate',
          'abnormal_subscription',
          'invoice_check',
          'invoice_confirm',
          'invoice_check_notify',
          'invoice_refund',
          'crm_admin',
          'crm'
        ]
        // features: ['asset_management', 'custom']
      },
      {
        name: 'location_menu.application.cloud_business_services_setting',
        value: 'cloud_business_services_setting',
        showSpace: [50495],

        roles: [
          'admin',
          'location_manager',
          'location_operator',
          'enterprise',
          'location_group_operator',
          'location_group_manager'
        ]
      },
      {
        name: 'location_menu.crm',
        value: 'crm',
        roles: ['admin', 'crm_admin', 'crm']
        // features: ['asset_management', 'custom']
      },
      {
        name: 'shuck.spaces_setting_box.menu.custome_field',
        value: 'custom_field',
        roles: ['admin']
        // features: ['asset_management', 'custom']
      },
      {
        name: 'location_menu.application.dynamic_attribute',
        value: 'dynamic_attribute',
        roles: [
          'admin',
          'location_manager',
          'location_operator',
          'location_group_operator',
          'location_group_manager'
        ]
      },
      // {
      //   name: 'location_menu.application.management',
      //   value: 'application_management',
      //   spaceTypes: ['space', 'thinkplus'],
      //   roles: ['admin', 'location_manager', 'location_operator'],
      //   features: ['custom']
      // },
      {
        name: 'location_menu.clients',
        value: 'clients',
        spaceTypes: ['space', 'thinkplus'],
        roles: [
          'admin',
          'location_manager',
          'location_operator',
          'location_group_operator',
          'location_group_manager'
        ],
        features: ['custom']
      },
      // {
      //   name: 'shuck.admin_box.default_top_menu.screen',
      //   value: 'screen',
      //   roles: ['admin']
      // },

      {
        name: 'spaces.location_form.label.tags',
        value: 'tags',
        roles: ['admin'],
        features: ['asset_management', 'custom']
      },
      {
        name: 'spaces.location_form.label.operation',
        value: 'operation',
        roles: ['admin'],
        features: ['asset_management', 'custom']
      },
      {
        name: 'visit.visit_type_edit_form.server_text',
        value: 'agreement',
        roles: ['admin'],
        features: ['custom']
        //features: ['asset_management', 'custom']
      },
      {
        name: 'const.membership.resource.type.point',
        value: 'currency',
        roles: ['admin'],
        features: ['custom']
        //features: ['asset_management', 'custom']
      },
      {
        name: 'shuck.spaces_setting_box.menu.logs',
        value: 'logs',
        roles: ['admin'],
        features: ['asset_management', 'custom']
      }
    ]
  }
]

export const IDEAPOD_SPACE_MENU = [
 {
  name: 'shuck.menu.member',
  value: 'member',
  roles: [
    'admin',
    'location_operator',
    'location_manager',
    'location_group_operator',
    'location_group_manager',
    'observer',
    'crm_admin',
    'crm'
  ],
  features: ['asset_management', 'custom'],
  spaceTypes: ['space', 'temporary', 'thinkplus'],
  submenu: [
    {
      name: 'shuck.member_box.menu.applicationList',
      value: 'application'
    },
    {
      name: 'shuck.member_box.menu.list',
      value: 'list'
    }
  ]
},
{
  name: 'shuck.menu.content',
  value: 'content',
  roles: [
    'admin',
    'location_operator',
    'location_manager',
    'location_group_operator',
    'location_group_manager',
    'observer',
    'crm_admin',
    'crm'
  ],
  features: ['asset_management', 'custom'],
  spaceTypes: ['space', 'temporary', 'thinkplus'],
  submenu: [
    {
      name: 'shuck.content_box.menu.rotation',
      value: 'rotation'
    },
    {
      name: 'shuck.admin_box.default_top_menu.app_manage',
      value: 'faq',
      roles: ['admin', 'faq']
      //features: ['custom']
    }
  ]
},
  {
    name: 'shuck.admin_box.default_top_menu.setting',
    value: 'settings',
    roles: [
      'admin',
      'shop_manager',
      'shop',
      'hr',
      'app',

      'finance',
      'contract',
      'contract_terminate',
      'abnormal_subscription',
      'invoice_check',
      'invoice_confirm',
      'invoice_check_notify',
      'invoice_refund',
      'crm_admin',
      'crm'
    ],
    //features: ['asset_management', 'custom'],
    submenu: [
      {
        name: 'shuck.admin_box.default_top_menu.employee',
        value: 'employees',
        roles: ['admin', 'hr']
        //features: ['asset_management', 'custom']
      },
      {
        name: 'visit.visit_type_edit_form.server_text',
        value: 'agreement',
        roles: ['admin'],
        features: ['custom']
        //features: ['asset_management', 'custom']
      },
  ]
}
]
export const KINEER_SPACE_MENU = [
  {
    name: 'shuck.admin_box.default_top_menu.manage',
    value: 'manage',
    // roles: ['admin', 'crm_admin']
    features: ['asset_management', 'custom'],
    submenu: [
      {
        name: 'shuck.admin_box.default_top_menu.manage_locations',
        value: 'locations'
        //features: ['asset_management', 'custom']
      },
      {
        name: 'shuck.admin_box.default_top_menu.manage_groups',
        value: 'groups'
        // roles: ['admin', 'location_manager', 'location_operator']
        //features: ['asset_management', 'custom']
      },
      {
        name: 'shuck.admin_box.default_top_menu.manage_areas',
        value: 'areas'
        //features: ['asset_management', 'custom']
      }
    ]
  },
  {
    name: 'shuck.admin_box.default_top_menu.enterprise',
    value: 'service',
    roles: ['admin', 'enterprise', 'clound_business_service_manager'],
    features: ['custom'],
    submenu: [
      {
        name: 'const.post.category.list',
        value: 'service_list'
        //features: ['custom']
      },
      {
        name: 'shuck.announcement_box.service_provider',
        value: 'list'
        //features: ['custom']
      },
      {
        name: 'shuck.announcement_box.service_provider.set',
        value: 'categories'
        //features: ['custom']
      }
      // {
      //   name: 'shuck.announcement_box.service_request',
      //   value: 'request'
      // }
    ]
  },
  {
    name: 'memberships.memberships_manage.menu.message_management',
    value: 'marketing',
    roles: ['admin', 'membership', 'content', 'activity'],
    features: ['custom'],
    submenu: [
      // {
      //   name: 'memberships.memberships_manage.menu.coupons',
      //   value: 'coupons',
      //   roles: ['admin', 'membership'],
      // },
      // {
      //   name: 'memberships.memberships_manage.menu.activities',
      //   value: 'activity',
      //   roles: ['admin', 'membership', 'content', 'activity']
      //   //features: ['custom']
      // },
      // {
      //   name: 'shuck.location_box.default_location_menu.service',
      //   value: 'service',
      //   roles: ['admin', 'membership', 'content', 'activity'],
      //   showSpace: [364, 50495]
      //   //features: ['custom']
      // },
      {
        name: 'memberships.memberships_manage.menu.announcement',
        value: 'announcements',
        roles: ['admin', 'membership', 'content']
        //features: ['custom']
      },
      {
        name: 'memberships.memberships_manage.menu.news',
        value: 'news',
        roles: ['admin', 'membership', 'content']
        //features: ['custom']
      }
    ]
  },
  {
    name: 'shuck.admin_box.default_top_menu.members_ships',
    value: 'memberships',
    roles: ['admin', 'membership', 'content', 'point_recharge'],
    features: ['custom'],
    submenu: [
      {
        name: 'memberships.memberships_manage.menu.members',
        value: 'members',
        roles: ['admin', 'membership', 'point_recharge']
        //features: ['custom']
      },

      {
        name: 'memberships.memberships_manage.menu.posts',
        value: 'posts',
        roles: ['admin', 'membership', 'content', 'point_recharge']
        //features: ['custom']
      },

      {
        name: 'memberships.memberships_manage.menu.activities',
        value: 'activity_box',
        roles: ['admin', 'membership', 'content', 'activity']
        //features: ['custom']
      },
      {
        name: 'memberships.memberships_manage.menu.activity_task',
        value: 'activity_task',
        roles: ['admin', 'membership', 'content', 'activity']
        //features: ['custom']
      },
      {
        name: 'memberships.memberships_manage.menu.user_task',
        value: 'user_task',
        roles: ['admin', 'membership', 'content', 'activity']
        //features: ['custom']
      },
      {
        name: 'shuck.location_box.default_location_menu.surveys',
        value: 'surveys'
        //features: ['custom']
      },
      {
        name: 'shuck.admin_box.default_top_menu.app_manage',
        value: 'faq',
        roles: ['admin', 'faq']
        //features: ['custom']
      }
    ]
  },
  {
    name: 'location_menu.marketing',
    value: 'card_coupon_marketing',
    roles: ['admin', 'membership', 'content', 'activity'],
    features: ['custom'],
    submenu: [
      {
        name: 'memberships.memberships_manage.menu.coupons',
        value: 'coupons',
        roles: ['admin', 'membership']
      },
      // {
      //   name: 'memberships.memberships_manage.menu.activities',
      //   value: 'activity',
      //   roles: ['admin', 'membership', 'content', 'activity']
      //   //features: ['custom']
      // },
      {
        name: 'spaces.space_setting_point.name',
        value: 'pointsRecord',
        roles: ['admin', 'membership', 'point_recharge']
        //features: ['custom']
      }
    ]
  },
  {
    name: 'shuck.admin_box.default_top_menu.shopping_management',
    value: 'shopping',
    roles: ['admin', 'shop_manager', 'shop'],
    features: ['custom'],
    submenu: [
      {
        name: 'shuck.admin_box.default_top_menu.shopping_list',
        value: 'list'
      },
      {
        name: 'shopping.orders',
        value: 'orders'
      },
      {
        name: 'shopping.activity',
        value: 'activity'
      }
    ]
  },
  {
    name: 'shuck.admin_box.default_top_menu.setting',
    value: 'settings',
    roles: [
      'admin',
      'shop_manager',
      'shop',
      'hr',
      'app',

      'finance',
      'contract',
      'contract_terminate',
      'abnormal_subscription',
      'invoice_check',
      'invoice_confirm',
      'invoice_check_notify',
      'invoice_refund',
      'crm_admin',
      'crm',
      'location_group_operator'
    ],
    //features: ['asset_management', 'custom'],
    submenu: [
      {
        name: 'location_menu.basic_setting',
        value: 'basic',
        roles: ['admin', 'hr', 'location_group_operator'],
        features: ['asset_management', 'custom']
      },
      {
        name: 'location_menu.role',
        value: 'location_group_role',
        roles: ['admin', 'hr', 'location_group_operator'],
        spaceTypes: ['space', 'temporary', 'thinkplus'],
        features: ['asset_management', 'custom']
      }
    ]
  }
]