import accounting from 'accounting'
import { initContantsContent, SUPPORTED_LOCALES } from '@/app/constants'
import { initHintContent } from '@/app/constants/hint'
import update, { extend } from 'immutability-helper'
import React from 'react'
import ReactDOM from 'react-dom'
import intl from 'react-intl-universal'
import { Provider } from 'react-redux'
import { getCurrentLocale } from '@/utils/kbUtil'
import routes from './routes'
import store from './store'
import { ConfigProvider, Result, Button } from 'antd'
import { BrowserRouter, Routes, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'
import { getLocalStorage } from './utils/kbUtil'
import NiceModal from '@ebay/nice-modal-react'
import * as Sentry from '@sentry/react'

import VersionCheck from './containers/VersionCheck'
import packageJson from '../package.json'

import './init.scss'
import './tailwind.scss'

// extend immutability-helper to auto add array elements and object key
extend('$auto', (value, object) => {
  return object ? update(object, value) : update({}, value)
})

extend('$autoArray', (value, object) => {
  return toString.call(object) === '[object Array]'
    ? update(object, value)
    : update([], value)
})

accounting.settings = {
  currency: {
    symbol: '￥', // default currency symbol is '$'
    format: '%s%v', // controls output: %s = symbol, %v = value/number (can be object: see below)
    decimal: '.', // decimal point separator
    thousand: ',', // thousands separator
    precision: 2, // decimal places
  },
  number: {
    precision: 2, // default precision on numbers is 0
    thousand: ',',
    decimal: '.',
  },
}

function initStaticVariable() {
  // TODO: taoh - remove this! don't use intl.get or tr in constants file
  initHintContent()
  initContantsContent()
}

const locales = {}
SUPPORTED_LOCALES.forEach(lang => {
  locales[lang.value] = lang.data
})

// let currentLocale = intl.determineLocale({
//   urlLocaleKey: "lang",
//   cookieLocaleKey: "lang",
//   localStorageLocaleKey: "lang"
// })
let currentLocale = getLocalStorage('lang') || 'zh-CN'

if (!SUPPORTED_LOCALES.find(x => x.value === currentLocale)) {
  currentLocale = import.meta.env.VITE_DEFAULT_LOCALE
}

export function initLocale(locale, callback) {
  return intl
    .init({
      currentLocale: locale,
      locales,
      fallbackLocale: import.meta.env.VITE_DEFAULT_LOCALE,
    })
    .then(() => {
      initStaticVariable()
      callback && callback()
    })
}

function FallbackComponent({ error, componentStack }) {
  return (
    <>
      <div>{error?.toString()}</div>
      <div>{componentStack}</div>
      <Result
        status="warning"
        title="You have encountered an error."
        extra={
          <Button
            onClick={() => {
              /* When resetError() is called it will remove the Fallback component */
              /* and render the Sentry ErrorBoundary's children in their initial state */
              window.location.reload()
              // 跳转到login页面, 并追加随机时间戳
              // window.location.href = `/login?_=${Date.now()}`
            }}
            type="primary"
            key="console">
            Reload
          </Button>
        }/>
    </>
  )
}

const fallback = <FallbackComponent />

function App(props) {
  const currentLocaleData = getCurrentLocale()
  const AppRender = (
    <Provider store={store}>
      <ConfigProvider
        locale={currentLocaleData.antD}
        getPopupContainer={(node) => {
          if (node) {
            return node
          }
          return document.body
        }}>
        <BrowserRouter>
          <NiceModal.Provider>
            <VersionCheck currentVersion={packageJson.version} />
            <Routes>{routes}</Routes>
          </NiceModal.Provider>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  )
  if (import.meta.env.VITE_APP_ENV === 'production') {
    return (
      (
        <Sentry.ErrorBoundary
          fallback={fallback}>
          {
            AppRender
          }
        </Sentry.ErrorBoundary>
      )
    )
  }
  return AppRender
}


if (import.meta.env.VITE_APP_ENV === 'production') {
  // 正式环境添加sentry错误监听和跟踪平台
  Sentry.init({
    dsn: 'https://e6d3a852cc184eacbadc8d23929c7a3a@o1398563.ingest.sentry.io/4505508568170496',
    integrations: [
      // new Sentry.Replay(),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    ignoreErrors: [
      'Non-Error promise rejection captured',
      'ResizeObserver loop limit exceeded',
      'Large Render Blocking Asset',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
    // initialScope: {

    // },
    // 是否100%捕获错误，如果不是100%，可以写成0.1，表示10%的错误会被捕获
    tracesSampleRate: 1.0,
    // 回话重播的参数
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    // replaysOnErrorSampleRate: 1.0,
  })
}

intl
  .init({
    currentLocale,
    locales,
    fallbackLocale: import.meta.env.VITE_DEFAULT_LOCALE,
  })
  .then(() => {
    window.intl_inited = true
    initStaticVariable()
    ReactDOM.render(<App />, document.getElementById('root'))
  })
