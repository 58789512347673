import React from 'react'
import { Route } from 'react-router-dom'
import lazyLoad from 'utils/lazyLoad'

const SubscriptionAttrlogs = lazyLoad(() => import('containers/organization/SubscriptionAttrlogs'))
const SubscriptionHistories = lazyLoad(() => import('containers/organization/SubscriptionHistories'))
const SubscriptionInfoBox = lazyLoad(() => import('containers/organization/SubscriptionInfoBox'))
const OrgContractDetail = lazyLoad(() => import('../containers/organization/OrgContractDetail'))
const SubscriptionInvoices = lazyLoad(() => import('../containers/organization/SubscriptionInvoices'))

export default function() {
  return (
    <Route
      path="subscriptions/:subscription_id"
      element={SubscriptionInfoBox}
    >
      <Route index
        element={OrgContractDetail} />
      <Route path="info"
        element={OrgContractDetail} />
      <Route path="attrlogs"
        element={SubscriptionAttrlogs} />
      <Route path="histories"
        element={SubscriptionHistories} />
      <Route path="invoices"
        element={SubscriptionInvoices} />
    </Route>
  )
}
