import { get } from '../reducers/reducer_utils'
import { deepQuery } from 'utils/kbUtil'

import * as Constants from '../constants'
import * as _ from 'lodash'
import accounting from 'accounting'

export const getUserById = (state, user_id = null) => {
  if (user_id && user_id === state.user.id) return state.user
  return user_id
    ? state.entities.users && state.entities.users[user_id]
    : state.user
}

export const getMemberById = (state, member_id = null) => {
  if (member_id && member_id === state.user.id) return state.user
  return member_id ? state.entities.users[member_id] : state.user
}

// get current logged in user
export const getCurrentUser = state => state && state.user

export const getPendingTaskList = state => state && state.task.pending_task_list || JSON.parse(localStorage.getItem('pending_task_list')) || []

export const getLatestTaskMessage = state => state && state.task.latestTaskMessage

export const getSaasSubscriptions = state =>
  _.get(state, 'user.saas_subscriptions', [])

// current displaying location, null if on space level
export const getCurrentLocation = state => {
  const isTenant = JSON.parse(localStorage.getItem('isTenant'))
  if (isTenant) {
    const location = JSON.parse(localStorage.getItem('location'))
    return location
  }
  const locationId = _.get(state, 'currentLocation.id')
  return _.get(state, ['entities', 'locations', locationId])

}
export const getCurrentLocationId = state => {
  const location = JSON.parse(localStorage.getItem('location'))

  return _.get(state, 'currentLocation.id') || _.get(location, 'id')
}

export const getCurrentOrg = state => {
  const organization = JSON.parse(localStorage.getItem('organization'))

  return organization
}

// if current user is logged in
export const isAuthenticated = state => getCurrentUser(state) || false

// get current space or given space id
// note that this only gets the current space description
export const getSpace = state => {
  const user = getCurrentUser(state)
  const spaceId = user && user.space_id
  return _.get(state, ['entities', 'spaces', spaceId], {})
}

export const getLocationsAsDictionary = (state, space_id = null) => {
  return get(state, ['entities', 'locations'], {})
}

export const getLocation = (state, location_id) => {
  return _.get(state, `entities.locations[${location_id}]`)
}

// 用户可以access的分店
export const getLocations = (state, useLocationGroup) => {
  const locationGroupId = JSON.parse(localStorage.getItem('location_group_id'))
  if (
    locationGroupId &&
    get(state, 'currentLocationGroup') &&
    useLocationGroup
  ) {
    const locationIds = get(state, 'currentLocationGroup').locations
    const filteredLocations = locationIds && Array.isArray(locationIds)
      ? _.compact(locationIds?.map(id => getLocation(state, id)))
      : []
    return filteredLocations
  }
  const locationIds = Object.keys(_.get(state, 'entities.locations', {}))
    .length
    ? Object.keys(_.get(state, 'entities.locations', {}))
    : _.get(state, 'user.locations', [])
    // const locationIds = _.get(state, 'user.locations', [])
  const filteredLocations = _.compact(
    (locationIds||[]).map(id => getLocation(state, id)),
  )
  return filteredLocations

}

// 用户当前所在位置的分店id
export const getLocationIds = state => {
  const locationGroupId = JSON.parse(localStorage.getItem('location_group_id'))
  if (locationGroupId && get(state, 'currentLocationGroup')) {
    const locationIds = get(state, 'currentLocationGroup').locations
    return locationIds.map(item => (item.id ? item.id : item))
  }
  const locationIds = _.get(state, 'user.locations', [])
  return locationIds.map(item => (item.id ? item.id : item))

}

// 用户可以access的分店id
export const getUserLocationIds = state => {
  const locationIds = _.get(state, 'user.locations', [])
  return locationIds.map(item => (item.id ? item.id : item))
}

// 用户的权限
export const getRoles = (state, user_id = null, location_id = null) => {
  const user = getMemberById(state, user_id)
  if (user && user.roles) {
    return user.roles && Object.values(user.roles)
  }
  return null

}

// 用户指定权限（包括所有分店）
export const findRolesByName = (state, roleName) => {
  const roles = getRoles(state) || []
  return roles.filter(role => role.role === roleName)
}

// 用户在分店的权限
export const findRolesOfLocation = (state, location_id, user_id = null) => {
  const roles = getRoles(state, user_id)
  if (roles) {
    return roles.filter(role => role.location_id === location_id)
  }
  return []

}

export const getSubScriptions = (state, id) => {
  const subscriptions = get(state, ['entities', 'subscriptions'])

  if (id) {
    return subscriptions && subscriptions[id]
  }

  return (subscriptions && Object.values(subscriptions)) || []
}

export const getSubScriptionsByOrg = (state, org_id) => {
  const subscriptions = getSubScriptions(state)
  if (!subscriptions.length) {
    return []
  }

  return subscriptions.filter(
    subscription => subscription.organization_id == org_id,
  )
}

export const getObjectMembers = state => {
  const users = getEntities(state, 'users')

  return users || {}
}

// 用户在空间的权限
export const getRolesOfSpace = (state, user_id = null) => {
  const roles = getRoles(state, user_id)
  if (!roles) return null
  return roles.filter(role => role.location_id === null)
}

// 是否分店管理员
export const isLocationAdmin = (state, location_id, user_id = null) => {
  const roles = getRoles(state, user_id, location_id)
  if (roles) {
    const manmageRole = roles.filter(role => role.role === 'location_manager')
    return !!manmageRole[0]
  }
  return false

}
// 是否空间管理员
export const isSpaceAdmin = (state, user_id = null) => {
  const roles = getRoles(state, user_id)
  if (roles) {
    const admin = roles.find(
      role => role.location_id === null && role.role === 'admin',
    )
    return !!admin
  }
  return false

}
// 用户上级
export const getParentOfUser = (state, user_id = null) => {
  const user = getMemberById(state, user_id)
  if (user.parent_id) {
    return _.get(state, ['entities', 'members', user.parent_id], null)
  }
  return null

}
// 全部空间管理公司用户
export const getSpaceUsers = (state, space_id = null) => {
  const space = getSpace(state, space_id)
  if (!space) {
    return null
  }
  return objectToArray(state.entities.members)
}
// 查询空间管理员工
export const getMembersByIds = (state, ids = []) => {
  return _.compact(ids.map(id => _.get(state, `entities.members.${id}`)))
}

// 查询所有的用户通过Ids
export const getUsersByUserIds = (state, ids = []) => {
  return _.compact(ids.map(id => _.get(state, `entities.users.${id}`)))
}

// 全部部门列表(数组)
export const getDepartments = (state, space_id = null) => {
  const space = getSpace(state, space_id)
  if (!space) {
    return null
  }
  return objectToArray(state.entities.departments)
}

// 全部部门列表(Object)
export const getDepartmentsObj = (state, space_id = null) => {
  const space = getSpace(state, space_id)
  if (!space) {
    return null
  }
  return state.entities.departments
}
export const getDepartment = (state, department_id) => {
  return (
    department_id &&
    state.entities.departments &&
    state.entities.departments[department_id]
  )
}
// 下级部门
export const getChildDepartments = (state, department_id) => {
  const children_ids = state.entities.departments[department_id].children
  const departments = state.entities.departments
  return children_ids.map(id => departments[id])
}
// 上级部门
export const getParentDepartment = (state, department_id) => {
  const department = state.entities.departments[department_id]
  if (!department || !department.parent_id) return null
  return state.entities.departments[department.parent_id]
}
// 用户所在的所有部门
export const getDepartmentsOfUser = (state, user_id) => {
  const user = getMemberById(state, user_id)
  if (!user) return null
  const departments = state.entities.departments || []
  return user.department_ids && user.department_ids.map(id => departments[id])
}
// 部门成员
export const getUsersOfDepartment = (state, department_id) => {
  const users = getSpaceUsers(state)
  return (
    users &&
    users.filter(
      user =>
        user.department_ids && user.department_ids.indexOf(department_id) > -1,
    )
  )
}
// 分店管理公司
export const getOperatorsOfLocation = (state, location_id) => {
  const teams = get(state, ['entities', 'locations', location_id, 'team'])
  return (
    teams &&
    teams.filter(team =>
      team.roles_list.find(
        json =>
          json.location_id == location_id && json.role == 'location_operator',
      ),
    )
  )
}

// 获取公司角色
export const getTeamRoles = user => {
  return user.roles
}

// 分店角色, 返回有该权限的用户
// FIXME: taoh - 改用 getSpaceUsersOfRole
export const getTeamRolesOfLocation = (state, location_id, roleStr = '') => {
  // let teams = get(state, ['entities', 'locations', location_id, 'team'])
  // let teamRoles = []
  // teams && teams.forEach(team => {
  //   team.roles.forEach(roleList => {
  //     if (roleList === roleStr) {
  //       teamRoles.push(team)
  //     }
  //   })
  // })
  // return teamRoles
  return getSpaceUsersOfRole(state, roleStr, location_id)
}

// 返回有该权限的员工array，如果location_id指定，只返回该location的role，else，只返回空间的role
export const getSpaceUsersOfRole = (state, role, location_id = undefined) => {
  const spaceRoles = get(state, ['spaceRole', 'roles'], [])
  let roles = spaceRoles.filter(x => x.role === role)
  if (location_id) {
    roles = roles.filter(x => x.location_id === location_id)
  } else {
    roles = roles.filter(x => !x.location_id)
  }
  const spaceUsers = getSpaceUsers(state)
  if (spaceUsers.length < 1) {
    return null
  }
  return _.compact(
    roles.map(role => spaceUsers.find(x => x.id === role.user_id)),
  )
}

// 分店负责人
export const getManagerOfLocation = (state, location_id) => {
  const teams = get(state, ['entities', 'locations', location_id, 'team'])
  return teams && teams.find(team => team.is_manager)
}
// 分店所有管理人员
export const getUsersOfLocation = (state, location_id) => {
  const teams = get(state, ['entities', 'locations', location_id, 'team'])
  return teams
}

/// //////// 空间会员 ///////////////////////////////////////////////////

// 空间所有会员:
export const getMembersOfSpace = (state, space_id = null) => {
  const space = getSpace(state, space_id)
  if (!space) {
    return null
  }
  const member_ids = get(state, ['spaces', space.id, 'users'])
  return member_ids && member_ids.map(id => state.entities.users[id])
}
// 空间分店会员:
export const getMembersOfLocation = (state, location_id) => {
  const member_ids = get(state, ['locations', location_id, 'member_ids'])
  return member_ids && member_ids.map(id => state.entities.users[id])
}
// 空间公司
export const getOrgsOfLocation = (state, location_id) => {
  const organizations = get(state, ['entities', 'organizations'])
  const organizationsList = Object.assign([], objectToArray(organizations))

  organizationsList.sort().reverse()
  if (!location_id) {
    return organizationsList
  }

  return organizationsList
  // .filter(org => {
  //     let loc_ids = []
  //     org.locations && org.locations.map(loc => loc_ids.push(loc.id))
  //     if (loc_ids.includes(Number(location_id))) {
  //         return true
  //     }
  //     return false
  // })
}

// 报表列表
export const getReports = state => {
  const reports = get(state, ['entities', 'reportLists'])
  const reportsList = Object.assign([], objectToArray(reports))

  return reportsList.sort().reverse()
}

/**
 * 根据Key值获取loading状态
 * @method getLoadingStatusOfKey
 * @param  {[obj]}              state [store]
 * @param  {[string]}              key   [key]
 */
export const getLoadingStatusOfKey = (state, key) => {
  if (!key) {
    return
  }

  const status = get(state, ['loading', key])

  return status
}

// 空间公司
export const getOrg = (state, org_id) => {
  return get(state, ['entities', 'organizations', org_id])
}
// 公司的会员（所有人）
export const getMembersOfOrg = (state, org_id) => {
  const users = Object.values(_.get(state, ['entities', 'users'], {}))
  const orgUsers = users.filter(user => {
    return (
      user &&
      user.organizations &&
      user.organizations.find(o => {
        return (
          o.id === org_id || (o.organization && o.organization.id === org_id)
        )
      })
    )
  })

  return orgUsers
}

export const getOrgMembers = (state, user_id, org_id) => {
  const users = get(state, ['entities', 'users'])
  if (user_id) {
    return users && users[user_id]
  }

  const list = objectToArray(users) || []

  if (org_id) {
    return list.filter(
      j =>
        j.organizations &&
        j.organizations.find &&
        j.organizations.find(
          o => o.id == org_id || (o.organization && o.organization.id == org_id),
        ),
    )
  }
  return list
}

// 公司负责人列表
export const getLeadersOfOrg = (state, org_id) => {
  const org = getOrg(state, org_id)
  return org && org.users && org.users.filter(user => user.role === 'employer')
}
export const getContractOfOrg = (state, org_id) => {
  const org = getOrg(state, org_id)
  return org && org.subscriptions
}

// 机会列表

// 机会列表
export const getContacts = (state, space_id) => {
  const contact_ids = get(state, ['crm', 'contacts'])
  return (
    contact_ids &&
    contact_ids.map(id => get(state, ['entities', 'contacts', id]))
  )
}
// 线索列表
export const getLeads = state => {
  const leads = get(state, ['entities', 'leads'])
  return objectToArray(leads)
}
// 客户意向办公室列表
export const getIntentAreas = state => {
  const areas = get(state, ['entities', 'intentAreas'])
  return objectToArray(areas)
}

// 指定分店下的分区列表(简单信息)
export const getSimpleAreas = state => {
  const areas = get(state, ['entities', 'simpleAreas'])
  return objectToArray(areas)
}

//
export const getObjCustomers = state => {
  return get(state, ['entities', 'customers'])
}
export const getObjOpportunities = state => {
  return get(state, ['entities', 'opportunities'])
}

// 销售机会公司
export const getOpportunityTeam = (state, opportunity_id) => {
  const opportunity = state.entities.opportunities[opportunity_id]
  if (!opportunity) return null
  return opportunity.sales_team
}
// 销售负责人
export const getOpportunityLeader = (state, opportunity_id) => {
  const sales_team = getOpportunityTeam(state, opportunity_id)
  return sales_team.filter(team => team.is_leader)
}

// 销售记录
export const getRecords = state => {
  const records = get(state, ['entities', 'crmRecords'])

  return (records && Object.values(records).reverse()) || []
}

// 分店分区
export const getArea = (state, area_id) => {
  return getEntity(state, 'areas', area_id)
}

/**
 * 返回Kay value 形式area
 * @param state
 */
export const getObjArea = state => {
  return get(state, ['entities', 'areas'])
}

export const getAreasOfLocation = (state, location_id) => {
  let areas = objectToArray(state.entities.areas)
  areas &&
    areas.map(area => {
      area.desks =
        area.desks &&
        area.desks.map(json => {
          json.area_id = area.id
          return json
        })
      return area
    })
  areas = areas && areas.filter(area => area.location_id == location_id)
  return areas
}

// 分店会议室
export const getMeetingRoomsOfLocation = (state, location_id) => {
  const meetings = get(state, ['entities', 'meetingRoots'])

  const meetingList =
    (meetings && Object.keys(meetings).length > 0 && Object.values(meetings)) ||
    []

  return (
    meetingList &&
    meetingList.filter(meeting => {
      if (!location_id) {
        return true
      }

      return meeting.location_id === location_id
    })
  )
}

// 查询平板列表
export const getMeetingDevicesOfLocation = (state, location_id) => {
  const meetingDevices = get(state, ['entities', 'meetingDevices'])

  const devicesList =
    (meetingDevices &&
      Object.keys(meetingDevices).length > 0 &&
      Object.values(meetingDevices)) ||
    []
  return (
    devicesList &&
    devicesList.filter(device => {
      if (!location_id) {
        return true
      }

      return device.location_id == location_id
    })
  )
}

/**
 * 获取预定列表
 * @type {[type]}
 */
export const getReservations = state => {
  const reservations = get(state, ['entities', 'reservations'])
  return objectToArray(reservations)
}

// /**
//  * 获取取消会议室列表
//  * @type {[type]}
//  */
// export const getApprovalRecords = (state) => {
//   let reservations = get(state, ['entities','meetingRoots'])
//   return objectToArray(reservations)
// }

// 分店开放工位区
export const getOpenOfficesOfLocation = (state, location_id) => {
  const areas = getAreasOfLocation(state, location_id)
  if (!areas) return null
  return areas.filter(area => area.area_type === 'public_office')
}
// 分店独立办公室
export const getCloseOfficesOfLocation = (state, location_id) => {
  const areas = getAreasOfLocation(state, location_id)
  if (!areas) return null
  return areas.filter(area => area.area_type === 'private_office')
}
// 所有办公区域
export const getOfficeAreasOfLocation = (state, location_id) => {
  const areas = getAreasOfLocation(state, location_id)
  if (!areas) return null
  return areas.filter(area =>
    _.includes(['private_office', 'public_office'], area.area_type),
  )
}
// 会员Desk
export const getDeskOfMember = (state, member_id) => {
  const user = state.entities.users[member_id]
  if (!user || !user.desk) return null
  return user.desk
}

// 获取空间员工信息
export const getSpaceMemberInfo = (state, member_id) => {
  if (get(state, ['entities', 'users']) && member_id) {
    const user = state.entities.users[member_id]
    return user
  }
  return false
}

/**
 * 获取CRM来源列表
 * @param  {[obj]} state [state]
 * @return {[array]}       [CRM来源列表]
 */
export const getCrmSources = state => {
  let sources = get(state, ['entities', 'crmSources']) || []
  sources = Object.assign([], Object.values(sources))
  return sources
}

// 根据Location_id 获取分店楼层信息
export const getFloors = (state, id) => {
  let floorInfos = get(state, ['entities', 'floorInfos']) || []
  floorInfos = Object.assign([], Object.values(floorInfos))

  floorInfos = floorInfos.filter(json => json.location_id == id)
  floorInfos = floorInfos.map(json => {
    json.name = json.layer_description
    return json
  })

  return floorInfos
}

/**
 * FIXME: taoh - remove this 获取序列化的Locations
 * @return {[array]} [返回locations]
 */
export const getSerializeLocation = state => {
  return Object.values(get(state, ['entities', 'locations'], {}))
}

/**
 * object to array
 * @return {[array]} [返回转换后的数组 否则为[]空数组]
 */
export const objectToArray = object => {
  return (
    (object && Object.keys(object).length > 0 && Object.values(object)) || []
  )
}

/**
 * 获取设备信息
 * @type {[type]}
 */
export const getDevices = state => {
  const devices = get(state, ['entities', 'devices']) || []
  return Object.assign([], Object.values(devices))
}

// 返回Entities数上的节点
export const getEntities = (state, entitiesName) => {
  return get(state, ['entities', entitiesName])
}

// 返回指定的Entity
export const getEntity = (state, entitiesName, entityId) => {
  return get(state, ['entities', entitiesName, entityId])
}

/**
 * FAQ 分类列表
 */
export const getSections = (state, location_id) => {
  const sections = get(state, ['entities', 'sections'])
  const sectionList = Object.assign([], objectToArray(sections))

  sectionList.map(section => {
    section.name = section.title
    return section
  })

  if (!location_id) {
    return sectionList
  }

  return []
}

/**
 * FAQ 文件列表
 */
export const getFaqFiles = state => {
  const faqFiles = get(state, ['entities', 'faqFiles'])
  const faqFileList = Object.assign([], objectToArray(faqFiles))

  return faqFileList
}

// 获取某个分类分支下的所有的文章列表
export const getTopicsBySection = (state, section_id) => {
  const topics = get(state, ['entities', 'topics'])
  let topicsList = Object.assign([], objectToArray(topics))
  if (!section_id) {
    return topicsList
  }

  topicsList = topicsList.filter(topics => {
    return topics.section.id == section_id
  })

  return topicsList
}

export const getSpacesSalesSetting = state => {
  const space = getSpace(state)
  const settings = get(state, ['entities', 'spacesSalesSetting'])
  const settingList = Object.assign([], objectToArray(settings))

  return settingList.find(json => json.space_id == space.id)
}

// 根据文章ID 和 分类ID 获取文章具体信息
export const getTopicsObjBySectionAndId = (state, section_id, topics_id) => {
  const topics = get(state, ['entities', 'topics'])
  const topicsList = Object.assign([], objectToArray(topics))

  if (!section_id || !topics_id) {
    return null
  }

  return topicsList.find(topics => {
    return topics.section.id == section_id && topics_id == topics.id
  })
}

// 获取门禁列表
export const getLocks = state => {
  const locks = get(state, ['entities', 'locks'])
  return Object.assign([], objectToArray(locks))
}

// 获取门禁详情
export const getLockInfo = (state, lock_id) => {
  const locks = get(state, ['entities', 'locks'])
  const locksArray = Object.assign([], objectToArray(locks))
  if (!lock_id) {
    return null
  }
  return locksArray.find(lock => {
    return lock.id == lock_id
  })
}

// 获取门禁权限(门禁详情)
export const getLocksPermissions = state => {
  const locksPermissions = get(state, ['entities', 'lockPermissions'])
  const locksPermissionsList = Object.assign([], objectToArray(locksPermissions))
  return locksPermissionsList
}

// 获取门禁权限
export const getLocksPermissionsUser = (state, member_id) => {
  const locksPermissions = get(state, ['entities', 'lockPermissions'])
  const locksPermissionsList = Object.assign([], objectToArray(locksPermissions))
  if (!member_id) {
    return null
  }
  return locksPermissionsList.filter(permissions => {
    return permissions.user_id == member_id
  })
}

/**
 * 获取服务商列表
 * @return {array} [返回服务商列表]
 */
export const getServiceProviders = state => {
  const serviceProviders = get(state, ['entities', 'serviceProviders'])
  return Object.assign([], objectToArray(serviceProviders))
}

export const getServiceProvidersOfId = (state, id) => {
  const serviceProviders = get(state, ['entities', 'serviceProviders'])
  const serviceProvider = (serviceProviders && serviceProviders[id]) || null
  return serviceProvider
}

/**
 * 获取根据id获取服务请求 如果没有id获取全部的服务请求
 */
export const getServiceRequestOfProvidersId = (state, id) => {
  const serviceRequests = get(state, ['entities', 'serviceRequests'])
  const serviceRequestsList = Object.assign([], objectToArray(serviceRequests))
  if (!id) {
    return serviceRequestsList.sort((a, b) => {
      return b.updated_at - a.updated_at
    })
  }

  return serviceRequestsList
    .filter(request => {
      return request.service_provider_id == id
    })
    .sort((a, b) => {
      return b.updated_at - a.updated_at
    })
}

export const getServiceOfId = (state, id) => {
  const services = get(state, ['entities', 'services'])
  const serviceList = Object.assign([], objectToArray(services))

  if (!id) {
    return serviceList
  }

  return services && services[id]
}

export const getServiceOfProviderId = (state, id) => {
  const services = getServiceOfId(state)
  if (!id) {
    return services
  }

  return services.filter(service => {
    return service.service_provider_id == id
  })
}

/**
 * 通过服务类型ID 获取服务
 * @method getServiceCategoriesOfId
 * @param  {[obj]}                 state [state]
 * @param  {[int]}                 id    [服务类型id]
 * @return {[array || obj]}        [如果id 为null 则返回全部的服务类型 如果id存在则返回该服务类型对象]
 */
export const getServiceCategories = state => {
  const serviceCategories = get(state, ['entities', 'serviceCategories'])
  const serviceCategoriesList = Object.assign(
    [],
    objectToArray(serviceCategories),
  )

  return serviceCategoriesList
}

/**
 * 获取所有的待办事项
 * @method getSpaceLeaveOrEnterOptions
 */
export const getSpaceLeaveOrEnterOptions = state => {
  const leaveOrEnterOptions = get(state, ['entities', 'leaveOrEnterOptions'])
  const leaveOrEnterOptionList = Object.assign(
    [],
    objectToArray(leaveOrEnterOptions),
  )

  return leaveOrEnterOptionList
}

export const getSubCategoriesOfId = (state, id) => {
  const subCategories = get(state, ['entities', 'subCategories'])
  const subCategoriesList = Object.assign([], objectToArray(subCategories))

  if (!id) {
    return subCategoriesList
  }

  return subCategories[id]
}

// Get all cities
export const getCities = state => {
  return _.get(state, 'entities.cities', [])
}

// Get all provinces
export const getProvinces = state => {
  return _.get(state, 'entities.provinces', [])
}

/**
 * 根据公告ID 获取公告  如果没有ID 默认返回公告数组
 * @method getAnnouncementsOfId
 * @param  {[obj]}             state [state]
 * @param  {[int]}             id    [公告id]
 */
export const getAnnouncementsOfId = (state, id) => {
  const announcements = get(state, ['entities', 'announcements'])
  const announcementsList = Object.assign([], objectToArray(announcements))

  if (!id) {
    return announcementsList
  }

  return announcements[id]
}

/**
 * 获取空间配置
 * @method getSpaceSetting
 * @param  {[object]}        state [state]
 * @return {[object]}              [空间配置对象]
 */
export const getSpaceSetting = state => {
  const space = get(state, ['spaceSetting'], {})
  return space
}

export const getLocationSetting = state => {
  // const id = locationId || getCurrentLocationId(state)
  // const location = getLocation(id)
  return get(state, 'locationSetting', {})
}

// 获取空间权限配置
export const getRoleSetting = state => {
  const space = get(state, ['spaceRole'])
  return space
}

export const getLocationRoleSetting = state => {
  return get(state, ['locationRole'])
}
export const getSpaceVirtualCurrencyName = state => {
  const settings = getSpaceSetting(state)
  const virtual_currency_name = _.get(
    settings,
    'virtual_currency_settings.virtual_currency_name',
    '积分',
  )
  return virtual_currency_name
}

/**
 * 获取会议室预定设置
 * @method getSpaceMeetingRoomSetting
 */
export const getSpaceMeetingRoomSetting = state => {
  const meetingSetting = get(state, ['entities', 'meetingSetting'])
  return meetingSetting && Object.values(meetingSetting)[0]
}

/**
 * 获取BeeCloud配置
 */
export const getBeeCloudSetting = state => {
  const settings = getSpaceSetting(state)
  const beecloud = settings && settings.beecloud_setting
  return beecloud
}

// 获取税率列表
export const getTaxes = state => {
  const taxes = get(state, ['entities', 'taxes'])
  return Object.assign([], objectToArray(taxes))
}

/**
 * 返回数组形式的  城市列表
 * @return array [返回数组]
 */
export const getCitiesToArray = state => {
  const cities = get(state, ['entities', 'cities'])
  return Object.assign([], objectToArray(cities))
}

// 获取合同模板列表-或者根据id获取合同模板
export const getSubscriptionTempletes = (state, id) => {
  const subscriptions = get(state, ['entities', 'subscriptionTempletes'])
  if (id) {
    return subscriptions && subscriptions[id]
  }

  return Object.assign([], objectToArray(subscriptions))
}

// 根据合同模板的状态获取合同列表
export const getSubscriptionTempletesOfState = (state, status) => {
  const subscriptions = getSubscriptionTempletes(state)
  return subscriptions.filter(json => json.status == status)
}

// 获取合同模板有效字段
export const getSubscriptionPlaceholder = state => {
  const validPlaceholders = get(state, ['entities', 'subscriptionPlaceholder'])
  return Object.assign([], objectToArray(validPlaceholders))
}

/**
 * 返回APP应用
 * @return array [返回数组]
 */
export const getAppModules = state => {
  const appModules = get(state, ['entities', 'appModules'])
  return Object.assign([], objectToArray(appModules))
}

/**
 * 返回平面图列表
 * @method getFloorPlans
 */
export const getFloorPlans = (state, loc_id) => {
  const floorplans = get(state, ['entities', 'floorplans'])
  let floorPlansList = Object.assign([], objectToArray(floorplans))
  if (!loc_id) {
    return floorPlansList
  }
  floorPlansList = floorPlansList.filter(json => json.location_id == loc_id)
  return floorPlansList
}

// 获取大楼信息
export const getBuildingInfo = (state, loc_id) => {
  const buildingInfo = get(state, ['entities', 'buildingInfo'])
  let buildingInfoList = Object.assign([], objectToArray(buildingInfo))
  if (!loc_id) {
    return buildingInfoList
  }
  buildingInfoList = buildingInfoList.filter(json => json.location_id == loc_id)
  return buildingInfoList
}

/**
 * 获取公司清单
 * @method getOrgOptions
 * @param  {[type]}      state [description]
 * @return {[type]}            [description]
 */
export const getOrgOptionsOfOrgId = (state, org_id) => {
  const leaveOrEnterOptions = get(state, ['entities', 'leaveOrEnterOptions'])
  const options = Object.assign([], objectToArray(leaveOrEnterOptions))

  if (!org_id) {
    return options
  }

  return (
    options[0] &&
    options.filter(json => {
      return json.organization_id == org_id
    })
  )
}

export const getTradingAreas = state => {
  return state.trading_areas
}
export const getTradingAreasOfCity = state => {}
export const getIndustries = state => {
  return state.entities.industries
}
export const getRaisings = state => {
  const raisings = get(state, ['entities', 'raisings'])

  return (
    (raisings && Object.keys(raisings).length > 0 && Object.values(raisings)) ||
    []
  )
}

export const getUserGropAndSettingValue = (setting, key) => {
  const obj = {
    user_group: '',
    setting_value: '',
  }
  if (!setting || !setting[key]) {
    return obj
  }

  obj.user_group = setting[key].user_group || ''
  obj.setting_value = setting[key] || ''

  return obj
}

// get Redux Form
export const getReduxFormValues = (state, name) => {
  const formObj = get(state, ['form', name])

  if (!formObj) {
    return null
  }

  return formObj.values ? formObj.values : null
}

// 会员等级列表
export const getMemberships = (state, options) => {
  const memberships = get(state, ['entities', 'memberships'])

  if (options) {
    if (options.parent_id) {
      return (
        memberships &&
        memberships[options.parent_id].histories.find(
          json => options.id == json.id,
        )
      )
    }
    return memberships && memberships[options.id]
  }

  return objectToArray(memberships)
}

// 会员制资源列表
export const getMembershipResources = (state, id) => {
  const membershipResources = get(state, ['entities', 'membershipResources'])

  if (id) {
    return membershipResources && membershipResources[id]
  }

  return objectToArray(membershipResources)
}

// 会员制资源列表
export const getPointPlans = (state, id) => {
  const pointPlans = get(state, ['entities', 'pointPlans'])

  if (id) {
    return pointPlans[id]
  }

  return objectToArray(pointPlans)
}

// 商城
export const getSalesProducts = state => {
  const salesProducts = get(state, ['entities', 'salesProducts'])

  return objectToArray(salesProducts)
}

export const getShops = state => {
  const shops = get(state, ['entities', 'shops'])

  return objectToArray(shops)
}

export const getProductCategories = (state, shop_id) => {
  const productCategories = get(state, ['entities', 'productCategories'])
  const productCategoryList = objectToArray(productCategories)
  if (shop_id) {
    return productCategoryList.filter(p => p.shop_id == shop_id)
  }

  return productCategoryList
}

export const getProductInventories = state => {
  const productInventories = get(state, ['entities', 'productInventories'])

  return objectToArray(productInventories)
}

// 增值服务列表
export const getAdditionalServices = state => {
  const additionalServices = get(state, ['entities', 'additionalServices']) || {}

  return objectToArray(additionalServices)
}

// 资讯列表
export const getNews = state => {
  const news = get(state, ['entities', 'news']) || {}

  return objectToArray(news)
}

// 资讯详情
export const getNewsInfo = (state, news_id) => {
  const news = get(state, ['entities', 'news']) || {}
  const newsInfo = news[news_id] || {}
  return newsInfo
}

// 获取打印机列表
export const getPrinters = (state, loc_id) => {
  const printers = get(state, ['entities', 'printers'])
  let printersList = Object.assign([], objectToArray(printers))
  if (!loc_id) {
    return printersList
  }
  printersList = printersList.filter(
    json => json.location && json.location.id == loc_id,
  )
  return printersList
}

// 获取储物柜预定列表
export const getLockerReservations = state => {
  const lockerReservations = get(state, ['entities', 'lockerReservations'])
  const lockerReservationsArray = Object.assign(
    [],
    objectToArray(lockerReservations),
  )
  return lockerReservationsArray.reverse()
}

// 获取发票库列表
export const getInvoicingPools = state => {
  const invoicingPools = get(state, ['entities', 'invoicingPools'])
  const invoicingPoolsArray = Object.assign([], objectToArray(invoicingPools))
  return invoicingPoolsArray
}

// 获取发票库列表
export const getShopVendors = state => {
  const shopVendors = get(state, ['entities', 'shopVendors'])
  const shopVendorsArray = Object.assign([], objectToArray(shopVendors))
  return shopVendorsArray
}

// 获取门禁组列表
export const getLockGroups = state => {
  const lockGroups = get(state, ['entities', 'lockGroups'])
  const lockGroupsArray = Object.assign([], objectToArray(lockGroups))
  return lockGroupsArray
}

// 获取门禁详情
export const getLockGroupInfo = (state, lock_group_id) => {
  const lockGroups = get(state, ['entities', 'lockGroups'])
  const lockGroupsArray = Object.assign([], objectToArray(lockGroups))
  if (!lock_group_id) {
    return null
  }
  return lockGroupsArray.find(lock_group => {
    return lock_group.id == lock_group_id
  })
}

// 获取平板列表
export const getPads = state => {
  const pads = get(state, ['entities', 'pad'])
  const padArray = Object.assign([], objectToArray(pads))
  return padArray
}

// 获取平板详情
export const getPadInfo = (state, pad_id) => {
  const pads = get(state, ['entities', 'pad'])
  const padArray = Object.assign([], objectToArray(pads))
  if (!pad_id) {
    return null
  }
  return padArray.find(pad => {
    return pad.id == pad_id
  })
}

// 获取数字屏幕模板列表
export const getScreenTemplates = state => {
  const screenTemplates = get(state, ['entities', 'screenTemplate'])
  const screenTemplatesArray = Object.assign([], objectToArray(screenTemplates))
  return screenTemplatesArray
}

// 获取数字屏幕模板详情
export const getScreenTemplateInfo = (state, screen_temp_id) => {
  const screenTemplates = get(state, ['entities', 'screenTemplate'])
  const screenTemplatesArray = Object.assign([], objectToArray(screenTemplates))
  if (!screen_temp_id) {
    return null
  }
  return screenTemplatesArray.find(screen_temp => {
    return screen_temp.id == screen_temp_id
  })
}

// 获取优惠券列表
export const getCoupons = state => {
  const coupons = get(state, ['entities', 'coupons'])
  const couponsArray = Object.assign([], objectToArray(coupons))
  return couponsArray
}

// 获取优惠券详情
export const getCouponInfo = (state, coupon_id) => {
  const coupons = get(state, ['entities', 'coupons'])
  const couponsArray = Object.assign([], objectToArray(coupons))
  if (!coupon_id) {
    return null
  }
  return couponsArray.find(screen_temp => {
    return screen_temp.id === coupon_id
  })
}

// 获取分店currency设置
export const getLocationCurrency = (state, loc_id) => {
  return accounting.settings.currency.symbol
}
// 获取写字楼楼层列表
export const getLocationFloorsFromState = state => {
  const locationFloors = get(state, ['entities', 'locationFloors'])
  const locationFloorsArray = Object.assign([], objectToArray(locationFloors))
  return locationFloorsArray
}

// 获取会议室 根据会议室ID
export const getCouponDetaile = (state, coupon_id) => {
  const coupons = get(state, ['entities', 'coupons'])
  const coupon = coupons && coupons[coupon_id]

  if (!coupon) {
    return {}
  }

  return coupon
}

// 交租日设置
export const getRentingPhaseRecurringDay = state => {
  const computeWay = _.get(
    state,
    'locationSetting.subscription_settings.renting_phase_recurring_date_compute_way',
  )
  const type = computeWay && computeWay.type
  if (type === 'fixed') {
    return computeWay.default_fixed || 20
  }
  // 交租日开始前的第几天
  return 20 // TODO: not working!!

}

// location——group

// 获取项目列表
export const getLocationGroupsAsDictionary = (state, space_id = null) => {
  return get(state, ['entities', 'locationGroups'], {})
}

// 获取项目列表
export const getLocationGroups = state => {
  const locationGroups = get(state, ['entities', 'locationGroups'])
  const locationGroupsArray = Object.assign([], objectToArray(locationGroups))
  return locationGroupsArray
}

// 项目设置
export const getLocationGroupSetting = state => {
  return get(state, 'locationGroupSetting', {})
}

// 项目权限
export const getLocationGroupRoleSetting = state => {
  return get(state, ['locationGroupRole'])
}

// 项目信息
export const getLocationGroup = (state, location_group_id) => {
  return deepQuery(getLocationGroups(state), location_group_id)
}

// // 用户可以access的项目
// export const getLocationGroups = state => {
//   const locationGroupIds = _.get(state, 'user.locationGroups', [])
//   const filteredLocations = _.compact(
//     locationGroupIds.map(id => getLocationGroup(state, id))
//   )
//   return filteredLocations
// }

// // 用户可以access的项目id
export const getLocationGroupIds = state => {
  const locationGroupIds = _.get(state, 'user.locationGroups', [])
  return locationGroupIds.map(item => (item.id ? item.id : item))
}

export const getCurrentLocationGroup = state => {
  const locationGroupId =
    JSON.parse(localStorage.getItem('location_group_id')) ||
    _.get(state, 'currentLocationGroup.id')
  return deepQuery(getLocationGroups(state), locationGroupId)
  // return _.get(state, ['entities', 'locationGroups', locationGroupId])
}

export const getCurrentLocationGroupInfo = state => {
  const locationGroupId =
    JSON.parse(localStorage.getItem('location_group_id')) ||
    _.get(state, 'currentLocationGroup.id')
  return deepQuery(getLocationGroups(state), locationGroupId)
}

export const getCurrentLocationGroupId = state => {
  // const location_group = JSON.parse(localStorage.getItem('location_group'))

  // return _.get(state, 'currentLocationGroup.id') || _.get(location_group, 'id')
  return _.get(state, 'currentLocationGroup.id')
}
