import { combineReducers } from 'redux'
import user from './user'
import crm from './crm'
import areas from './areas'
import locations from './locations'
import error from './error'
import inform from './inform'
import spaceSetting from './spaceSetting'
import locationSetting from './locationSetting'
import locationRole from './locationRole'
import locationGroupRole from './locationGroupRole'
import locationGroupSetting from './locationGroupSetting'
import spaceRole from './spaceRole'
import loading from './loading'
import locale from './locale'
import currentLocation from './currentLocation'
import currentLocationGroup from './currentLocationGroup'
import * as actions from '../actions'
import workbench from './workbench'
import task from './task'

import intl from 'react-intl-universal'
// This normally imports the entire lodash package, but it can be more
// efficient by using bable-plugin-lodash
// See https://github.com/lodash/babel-plugin-lodash
import * as _ from 'lodash'
import { arrayToObject } from 'utils/kbUtil'
import {
    get,
    unsetObject,
    updateArray,
    replaceArray,
    unsetBatchObject
} from './reducer_utils'

// Updates an entity cache in response to any action with response.entities.
function entities(state = {}, action = {}) {
    if (action.id && action.key && action.type.indexOf('DELETE') !== -1) {
        return unsetObject(state, `${action.key}.${action.id}`)
    }

    if (action.ids && action.key && action.type.indexOf('DELETE') !== -1) {
        return unsetBatchObject(state, action.key, action.ids)
    }

    const isEntities = Boolean(
        action.response &&
      ((action.response.json && action.response.json.entities) ||
        action.response.entities)
    )
    const updateState = entitiesUpdate(state, action, isEntities)

    const replaceState = entitiesReplace(state, action, isEntities)
    if (updateState) {
        return updateState
    }

    if (replaceState) {
        return replaceState
    }

    if (action.type === actions.USER_LOGGED_OUT) {
        return {}
    }

    if (isEntities) {
        const newState = _.merge(
            {},
            state,
            action.response.entities || action.response.json.entities
        )
        return newState
    }

    return state
}

function entitiesReplace(state, action, isEntities) {
    if (
        isEntities &&
    action.type.indexOf('REPLACE') !== -1 &&
    action.entityName
    ) {
        const data = action.response.entities || action.response.json.entities
        if (!Object.keys(data).length) {
            const s = _.cloneDeep(state)
            s[action.entityName] = {}
            return s
        }
        const newState = replaceObjOfKey(action.entityName, data, state, action)

        return newState
    }

    return null
}

function replaceObjOfKey(entityName, data, state, action) {
    const newState = _.cloneDeep(state)
    const keys = Object.keys(data)
    if (!keys) {
        return newState
    }

    if (!action.status) {
        return { ...newState, ...data }
    }

    if (action.status === 'single' && keys.length === 1) {
        const { status, key, removeId } = action

        const modules = key.split('.')
        let list = newState[entityName]
        modules.forEach((json, index) => {
            if (index === modules.length - 1 && !list) {
                list[json] = []
            }
            list = list[json]
        })
        if (removeId) {
            list.splice(
                list.findIndex(json => json.id === removeId),
                1
            )
        }
        const values = Object.values(data[entityName])
        list.unshift(values[0])
        return newState
    }

    return newState
}

function entitiesUpdate(state, action, isEntities) {
    try {
        if (isEntities && action.type.indexOf('UPDATE') !== -1) {
            const entities = action.response.entities || action.response.json.entities

            const newState = _.mergeWith(
                {},
                state,
                entities,
                updateArray
            )

            const { key, id, entityName } = action

            // 对于单条子属性的更新
            if (action.status === 's_property') {
                const fieldKeys = key.split(',')

                fieldKeys.forEach(fieldName => {
                    newState[entityName][id][fieldName] = entities[entityName][id][fieldName]
                })
            }

            // 单个替换整个对象
            if (action.status === 's_object') {
                newState[entityName][id] = entities[entityName][id]
            }

            return newState
        }

        return null
    } catch (error) {
        console.warn('报错了：', error)
        return null
    }

    
}

function success(state = {}, action = {}) {
    const { success } = action

    if (action.type === 'CLEAR_SUCCESS_STATE') {
        return {}
    }

    if (action.type !== 'SUCCESS_STATE') {
        return state
    }

    if (success && success.message) {
        return _.merge({}, state, {
            message: success.message,
            title: success.title
        })
    }

    return _.merge({}, state, { message: intl.get('operated') })
}

// Create root reducers
export const rootReducer = asyncReducers => {
    return combineReducers({
    // form: formReducer,
        entities,
        spaceSetting,
        locationGroupSetting,
        locationSetting,
        locationRole,
        locationGroupRole,
        spaceRole,
        user,
        currentLocation,
        currentLocationGroup,
        locale,
        workbench,
        task,
        ...asyncReducers
    })
}
export default rootReducer
