import createStore from './createStore'

let currentUser
try {
  const userStore = localStorage.getItem('current_user')
  if (userStore) {
    currentUser = JSON.parse(userStore)
  }
} catch (e) {
  localStorage.removeItem('current_user')
  currentUser = null
}

const initialState = {
  user: currentUser,
  entities: {
    // cities: currentUser.cities,
    // locations: currentUser.locations,
    spaces: currentUser && currentUser.spaces
  }
}

const store = createStore(initialState, history)
window.__store__ = store

export default store
