import React from 'react'
import { Route } from 'react-router-dom'
import lazyLoad from 'utils/lazyLoad'

import loginRoutes from './loginRoutes'
import spaceRoutes from './spaceRoutes'

import loginRoutesSaas from './loginRoutesSaas'

const HomePage = lazyLoad(() => import('../containers/HomePage'))
const WechatLogin = lazyLoad(() => import('../containers/login/WechatLogin'))
const AssistBox = lazyLoad(() => import('../containers/shuck/AssistBox'))
const CreateSpacePage = lazyLoad(() => import('../containers/spaces/Create'))
const CreateSpaceSuccess = lazyLoad(() => import('../containers/spaces/CreateSpaceSuccess'))
const PageWarning = lazyLoad(() => import('../components/Exception/PageWarning'))
const NotAuthorized = lazyLoad(() => import('../components/Exception/NotAuthorized'))
const PageNotFound = lazyLoad(() => import('../components/Exception/PageNotFound'))

export default (
    <Route path="/">
         <Route
            path='web_login_with_wechat_code'
            element={WechatLogin} />
        <Route
            path='tenant_login_with_wechat_code'
            element={WechatLogin} />
        <Route
            index
            element={HomePage} />
           
        {/* /login */}
        {loginRoutesSaas()}
        {/* /tenant_login */}
        {loginRoutes()}


        <Route
            path="spaces"
            element={AssistBox}>
            <Route
                path="create"
                element={CreateSpacePage} />
            <Route
                path="create_success"
                element={CreateSpaceSuccess} />
        </Route>

        {/* /admin */}
        {spaceRoutes()}
        {/* 通用组件工具 */}
        <Route
            path="warning"
            element={PageWarning} />
        <Route
            path="not_authorized"
            element={NotAuthorized} />
        <Route
            path="*"
            element={PageNotFound} />
    </Route>

)
