import { applyMiddleware, compose, createStore } from 'redux'
import reducers from '../app/reducers'

// adds action.globalState() function to retrieve global redux state from tree
function globalState({ getState }) {
    return next => action => {
        next({ ...action, getGlobalState: getState })
    }
}

export default (initialState = {}) => {
    const middleware = applyMiddleware(globalState)
    const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

    const store = createStore(
        reducers(),
        initialState,
        composeEnhancers(middleware)
    )

    store.asyncReducers = {}

    return store
}
