import intl from 'react-intl-universal'

// TODO: taoh - 用constants方法重写

//添加新公司
export let ADD_ORGANIZATION

//添加新会员
export let ADD_ORGANIZATION_MEMBER

//添加新合同
export let ADD_SUBSCRIPTION

//添加新分区
export let ADD_AREA

//新建报修
export let ADD_TASK

//添加新员工
export let ADD_STAFF

//添加新客户
export let ADD_CLIENT

//安全设置
export let SAFE_SETTING

//合同审批人配置
export let APPROVE_CONTRACT

//添加服务
export let ADD_SERVICE

//添加付款信息
export let AA_PAYMENT_INFORMATION

export let APPLICATION_FORM

export let ANNOUNCEMENT_FORM

export let SPACE_FORM

export let LOCK_SETTING_FORM

// 税率设置
export let TAXES_FORM

// 活动
export let ACTIVITIES_CREATE_FORM

//优惠券
// export let COUPONS_FORM

export function initHintContent() {
  ACTIVITIES_CREATE_FORM = {
    tag: intl.get('hint.activities.create.form.tag'),
    start_at: intl.get('hint.activities.create.form.start_at'),
    end_at: intl.get('hint.activities.create.form.end_at'),
    ticket: intl.get('hint.activities.create.form.ticket')
  }

  TAXES_FORM = {
    rate: intl.get('hint.taxes.form.rate')
  }

  SPACE_FORM = {
    short_name: intl.get('hint.space.form.short_name'),
    full_name: intl.get('hint.space.form.full_name'),
    contact_phone: intl.get('hint.space.form.contact_phone'),
    one_liner: intl.get('hint.space.form.one_liner'),
    devices_list: intl.get('hint.space.form.devices_list')
  }

  LOCK_SETTING_FORM = {
    bind_card: intl.get('hint.lock.setting.form.bind_card'),
    set_lock_password: intl.get('hint.lock.setting.form.set_lock_password'),
    display_bluetooth: intl.get('hint.lock.setting.form.display_bluetooth')
  }

  ANNOUNCEMENT_FORM = {
    name: intl.get('hint.announcement.form.name'),
    url: intl.get('hint.announcement.form.url')
  }

  APPLICATION_FORM = {
    name: intl.get('hint.application.form.name'),
    url: intl.get('hint.application.form.url')
    // 酷办App原生地址（以kuban://开头）'
  }

  AA_PAYMENT_INFORMATION = {
    collect_amount: intl.get('hint.payment.infomation.collect_amount')
  }

  ADD_SERVICE = {
    list_price: intl.get('hint.add.service.list_price'),
    member_price: intl.get('hint.add.service.member_price'),
    conditions: intl.get('hint.add.service.conditions')
  }

  SAFE_SETTING = {
    manager: intl.get('hint.safe.setting.manager')
  }

  APPROVE_CONTRACT = {
    approve: intl.get('hint.approve.subscription.approve'),
    approver: intl.get('hint.approve.subscription.approver')
  }

  ADD_CLIENT = {
    name: intl.get('hint.add.client.name'),
    market_stage: intl.get('hint.add.client.market_stage'),
    market_record: intl.get('hint.add.client.market_record'),
    market_team: intl.get('hint.add.client.market_team'),
    principal: intl.get('hint.add.client.principal'),
    team_member: intl.get('hint.add.client.team_member'),
    remark: intl.get('hint.add.client.remark')
  }

  ADD_STAFF = {
    superior: intl.get('hint.add.staff.superior'),
    permission_settings: intl.get('hint.add.staff.permission_settings')
  }

  ADD_ORGANIZATION = {
    full_name: intl.get('hint.add.organization.full_name'),
    short_name: intl.get('hint.add.organization.short_name'),
    intro: intl.get('hint.add.organization.intro'),
    remark: intl.get('hint.add.organization.remark')
  }

  ADD_ORGANIZATION_MEMBER = {
    principal: intl.get('hint.add.organization.member.principal'),
    skills: intl.get('hint.add.organization.member.skills')
  }

  ADD_SUBSCRIPTION = {
    valid_date: intl.get('hint.add.subscription.valid_date'),
    sign_date: intl.get('hint.add.subscription.sign_date'),
    start_date: intl.get('hint.add.subscription.start_date'),
    end_date: intl.get('hint.add.subscription.end_date'),
    expiration_reminder_date: intl.get(
      'hint.add.subscription.expiration_reminder_date'
    ),
    station_price: intl.get('hint.add.subscription.station_price'),
    month_price: intl.get('hint.add.subscription.month_price'),
    rent_day: intl.get('hint.add.subscription.rent_day'),
    sign: intl.get('hint.add.subscription.sign'),
    remark: intl.get('hint.add.subscription.remark')
  }

  ADD_AREA = {
    area: intl.get('hint.add.area'),
    name: intl.get('hint.add.area.name'),
    station_num: intl.get('hint.add.area.station_num')
  }

  ADD_TASK = {
    title_repair: intl.get('hint.add.task.title'),
    title_clean: intl.get('hint.add.task.title_clean'),
    // title_custom_service: intl.get('hint.add.task.title_custom_service'),
    title_feedback: intl.get('hint.add.task.title_feedback'),
    team: intl.get('hint.add.task.team'),
    principal: intl.get('hint.add.task.principal'),
    participator: intl.get('hint.add.task.participator')
  }
  // COUPONS_FORM = {
  //   name: intl.get("hint.coupons.form.name"),
  //   type: intl.get("hint.coupons.form.type"),
  // }
}
