import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoad from 'utils/lazyLoad'

const ClientInfoBox = lazyLoad(() => import('containers/application/ClientInfoBox'))
const ClientInfoHome = lazyLoad(() => import('containers/application/ClientInfoHome'))
const ClientsList = lazyLoad(() => import('containers/application/ClientsList'))
const ClientInfoFunctions = lazyLoad(() => import('../containers/application/ClientInfoFunctions'))
const ClientInfoStart = lazyLoad(() => import('../containers/application/ClientInfoStart'))

export default function() {
    return (
        <>
            <Route path="clients" element={ClientsList} />
            <Route path="clients/:app_id" element={ClientInfoBox}>
                <Route path="home" element={ClientInfoHome} />
                <Route path="functions" element={ClientInfoFunctions} />
                <Route path="start" element={ClientInfoStart} />
            </Route>
        </>
    )
}
